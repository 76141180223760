import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TablePagination, useTheme } from '@mui/material';
import { useStyles } from '../../components/table/TableStyle'

const columnsGroups = [
    { id: 'claveGrupal', label: 'Clave Grupal', minWidth: 100 },
    { id: 'date', label: 'Fecha  de creación', minWidth: 100 },
    { id: 'user', label: 'Usuario de registro', minWidth: 100 },
    { id: 'status', label: 'Estatus', minWidth: 100 },
    { id: 'cantidad', label: 'Usuarios por grupo', minWidth: 100 },
]

export default function TableGroups(props) {
    const theme = useTheme()
    const localStyle = useStyles(theme)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    return (
        <div>
            <TableContainer className={localStyle.container}>
                <Table size="medium" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {columnsGroups.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align='left'
                                    className={localStyle.head}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}

                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow className={localStyle.tableRow} onClick={() => props.setDataGroup(row)}>
                                    <TableCell align='left'>
                                        {row.ClaveGrupo}
                                    </TableCell>
                                    <TableCell align='left'>
                                        {row.CreationDate}
                                    </TableCell>
                                    <TableCell align='left'>
                                        {row.CreationUser}
                                    </TableCell>
                                    <TableCell align='left'>
                                        {row.Status}
                                    </TableCell>
                                    <TableCell align='left'>
                                        {row.TotalSolicitudes}
                                    </TableCell>

                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[0]}
                component="div"
                count={props?.data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}
