import React, { useEffect, useState } from 'react'
import { constPathRoot, useWindowDimensions } from '../../utils';
import { Box } from '@mui/material';

export default function Reports() {
    const { height, width } = useWindowDimensions();
    const [urlCharts, setUrlCharts] = useState(null);

    // useEffect(() => {
    //     // console.log("matches: ",width,matches)
    //     let urlGenerate = `${constUrlGraficas.ambient}?reference=${"aa"}&token=${sessionStorage.token}`;
    //     console.log("url: ", urlGenerate)
    //     setUrlCharts(urlGenerate)
    // }, []);

    let path = window.location.pathname

    const setSectionName = () => {
        console.log("path: ", path)
        // eslint-disable-next-line default-case
        switch (path) {
            case `${constPathRoot.pathRoot}/inbox/report/contrataciones`:
                return "https://reportes.docsolutions.com:444/Reports/report/METLIFE/1.Contrataciones"
            case `${constPathRoot.pathRoot}/inbox/report/prestamos`:
                return "https://reportes.docsolutions.com:444/Reports/report/METLIFE/5.Prestamos%20solicitados"
        }
    }

    console.log(setSectionName());
   
    

    return (
        <>
            <Box style={{ width: "100%", height: "80vh" }}>
                {/* {urlCharts ? */}
                <object data={"https://reportes.docsolutions.com:444/Reports/report/METLIFE/1.Contrataciones"} width="100%" height={height - 150}></object>
                {/* : null} */}
            </Box>
        </>)
}
