import { ticketsConstants } from '../constants';
import { ticketRequest } from '../../services'

export const ticketActions = {}

ticketActions.GetTicketsByFile = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.GetTicketsByFile(data)
      .then(
        ticketInfo => dispatch(success(ticketInfo)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_INFO_REQUEST }
  }

  function success(ticketInfo) {
    return { type: ticketsConstants.TICKETS_INFO_SUCCESS, ticketInfo }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_INFO_FAILURE, error }
  }

}

ticketActions.GetInfoByFile = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.GetInfoByFile(data)
      .then(
        ticketInfoByFile => dispatch(success(ticketInfoByFile)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_BY_INFO_REQUEST }
  }

  function success(ticketInfoByFile) {
    return { type: ticketsConstants.TICKETS_BY_INFO_SUCCESS, ticketInfoByFile }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_BY_INFO_FAILURE, error }
  }

}

ticketActions.GetTicketSingle = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.GetTicketSingle(data)
      .then(
        ticketSingle => dispatch(success(ticketSingle)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_SINGLE_REQUEST }
  }

  function success(ticketSingle) {
    return { type: ticketsConstants.TICKETS_SINGLE_SUCCESS, ticketSingle }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_SINGLE_FAILURE, error }
  }

}

ticketActions.GetTicketDocs = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.GetTicketDocs(data)
      .then(
        ticketDocs => dispatch(success(ticketDocs)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_DOCS_REQUEST }
  }

  function success(ticketDocs) {
    return { type: ticketsConstants.TICKETS_DOCS_SUCCESS, ticketDocs }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_DOCS_FAILURE, error }
  }

}

ticketActions.GetInfoTicketsBySearsh = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.GetInfoTicketsBySearsh(data)
      .then(
        ticketInfoBySearch => dispatch(success(ticketInfoBySearch)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_BY_SEARCH_REQUEST }
  }

  function success(ticketInfoBySearch) {
    return { type: ticketsConstants.TICKETS_BY_SEARCH_SUCCESS, ticketInfoBySearch }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_BY_SEARCH_FAILURE, error }
  }

}

ticketActions.CreateTicketSeguimiento = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.CreateTicketSeguimiento(data)
      .then(
        createTicket => dispatch(success(createTicket)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_CREATE_REQUEST }
  }

  function success(createTicket) {
    return { type: ticketsConstants.TICKETS_CREATE_SUCCESS, createTicket }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_CREATE_FAILURE, error }
  }

}

ticketActions.ExportTicketExcel = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.ExportTicketExcel(data)
      .then(
        excelExport => dispatch(success(excelExport)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_EXCEL_EXPORT_REQUEST }
  }

  function success(excelExport) {
    console.log('Success excel', excelExport)
    return { type: ticketsConstants.TICKETS_EXCEL_EXPORT_SUCCESS, excelExport }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_EXCEL_EXPORT_FAILURE, error }
  }

}

ticketActions.GetTicketsBySearch = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.GetTicketsBySearch(data)
      .then(
        ticketSearch => dispatch(success(ticketSearch)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_SEARCH_REQUEST }
  }

  function success(ticketsSearch) {
    return { type: ticketsConstants.TICKETS_SEARCH_SUCCESS, ticketsSearch }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_SEARCH_FAILURE, error }
  }

}

ticketActions.GetTicketsByFolio = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.GetTicketsByFolio(data)
      .then(
        ticketsFolio => dispatch(success(ticketsFolio)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_FOLIO_REQUEST }
  }

  function success(ticketsFolio) {
    return { type: ticketsConstants.TICKETS_FOLIO_SUCCESS, ticketsFolio }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_FOLIO_FAILURE, error }
  }

}

ticketActions.TicketUpdate = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.AddUpdateTicketSeguimiento(data)
      .then(
        ticketUpdateData => dispatch(success(ticketUpdateData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_UPDATEMETADATA_REQUEST }
  }

  function success(ticketUpdateData) {
    return { type: ticketsConstants.TICKETS_UPDATEMETADATA_SUCCESS, ticketUpdateData }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_UPDATEMETADATA_FAILURE, error }
  }
}

ticketActions.GetTicketEstatusProceso = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.GetTicketEstatusProceso(data)
      .then(
        ticketEstatusProceso => dispatch(success(ticketEstatusProceso)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_ESTATUSPROCESO_REQUEST }
  }

  function success(ticketEstatusProceso) {
    return { type: ticketsConstants.TICKETS_ESTATUSPROCESO_SUCCESS, ticketEstatusProceso }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_ESTATUSPROCESO_FAILURE, error }
  }

}
ticketActions.GetTicketContactoList = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.GetTicketContactoList(data)
      .then(
        ticketContactoList => dispatch(success(ticketContactoList)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_CONTACTOLIST_REQUEST }
  }

  function success(ticketContactoList) {
    return { type: ticketsConstants.TICKETS_CONTACTOLIST_SUCCESS, ticketContactoList }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_CONTACTOLIST_FAILURE, error }
  }

}

ticketActions.GetTicketAreaRespList = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.GetTicketAreaRespList(data)
      .then(
        ticketAreaRespList => dispatch(success(ticketAreaRespList)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_AREARESPLIST_REQUEST }
  }

  function success(ticketAreaRespList) {
    return { type: ticketsConstants.TICKETS_AREARESPLIST_SUCCESS, ticketAreaRespList }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_AREARESPLIST_FAILURE, error }
  }

}

ticketActions.GetTicketEjecutivoList = (data) => {
  return dispatch => {
    dispatch(request())
    ticketRequest.GetTicketEjecutivoList(data)
      .then(
        ticketEjecutivoList => dispatch(success(ticketEjecutivoList)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: ticketsConstants.TICKETS_EJECUTIVOLIST_REQUEST }
  }

  function success(ticketEjecutivoList) {
    return { type: ticketsConstants.TICKETS_EJECUTIVOLIST_SUCCESS, ticketEjecutivoList }
  }

  function failure(error) {
    return { type: ticketsConstants.TICKETS_EJECUTIVOLIST_FAILURE, error }
  }

}
