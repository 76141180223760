export const fileConstants = {
  FILE_INFO_REQUEST: "FILE_INFO_REQUEST",
  FILE_INFO_SUCCESS: "FILE_INFO_SUCCESS",
  FILE_INFO_FAILURE: "FILE_INFO_FAILURE",

  FILE_SEARCH_USER_REQUEST: "FILE_SEARCH_USER_REQUEST",
  FILE_SEARCH_USER_SUCCESS: "FILE_SEARCH_USER_SUCCESS",
  FILE_SEARCH_USER_FAILURE: "FILE_SEARCH_USER_FAILURE",

  FILE_SEARCH_USER_PAGINATION_REQUEST: "FILE_SEARCH_USER_PAGINATION_REQUEST",
  FILE_SEARCH_USER_PAGINATION_SUCCESS: "FILE_SEARCH_USER_PAGINATION_SUCCESS",
  FILE_SEARCH_USER_PAGINATION_FAILURE: "FILE_SEARCH_USER_PAGINATION_FAILURE",

  FILE_SEARCH_REQUEST: "FILE_SEARCH_REQUEST",
  FILE_SEARCH_SUCCESS: "FILE_SEARCH_SUCCESS",
  FILE_SEARCH_FAILURE: "FILE_SEARCH_FAILURE",

  FILE_RECORD_REQUEST: "FILE_RECORD_REQUEST",
  FILE_RECORD_SUCCESS: "FILE_RECORD_SUCCESS",
  FILE_RECORD_FAILURE: "FILE_RECORD_FAILURE",

  FILE_ACTORS_REQUEST: "FILE_ACTORS_REQUEST",
  FILE_ACTORS_SUCCESS: "FILE_ACTORS_SUCCESS",
  FILE_ACTORS_FAILURE: "FILE_ACTORS_FAILURE",

  FILE_ACTOR_REQUEST: "FILE_ACTOR_REQUEST",
  FILE_ACTOR_SUCCESS: "FILE_ACTOR_SUCCESS",
  FILE_ACTOR_FAILURE: "FILE_ACTOR_FAILURE",

  FILE_FOLIO_REQUEST: "FILE_FOLIO_REQUEST",
  FILE_FOLIO_SUCCESS: "FILE_FOLIO_SUCCESS",
  FILE_FOLIO_FAILURE: "FILE_FOLIO_FAILURE",

  FILE_UPDATEMETADATA_REQUEST: "FILE_UPDATEMETADATA_REQUEST",
  FILE_UPDATEMETADATA_SUCCESS: "FILE_UPDATEMETADATA_SUCCESS",
  FILE_UPDATEMETADATA_FAILURE: "FILE_UPDATEMETADATA_FAILURE",

  ASIGNATE_CURRENT_USER_NEW_REQUEST: "ASIGNATE_CURRENT_USER_NEW_REQUEST",
  ASIGNATE_CURRENT_USER_NEW_SUCCESS: "ASIGNATE_CURRENT_USER_NEW_SUCCESS",
  ASIGNATE_CURRENT_USER_NEW_FAILURE: "ASIGNATE_CURRENT_USER_NEW_FAILURE",

  FILE_GENERATEFOLIOHIRING_REQUEST: "FILE_GENERATEFOLIOHIRING_REQUEST",
  FILE_GENERATEFOLIOHIRING_SUCCESS: "FILE_GENERATEFOLIOHIRING_SUCCESS",
  FILE_GENERATEFOLIOHIRING_FAILURE: "FILE_GENERATEFOLIOHIRING_FAILURE",

  GET_ASIGNATE_CURRENTUSER_REQUEST: "GET_ASIGNATE_CURRENTUSER_REQUEST",
  GET_ASIGNATE_CURRENTUSER_SUCCESS: "GET_ASIGNATE_CURRENTUSER_SUCCESS",
  GET_ASIGNATE_CURRENTUSER_FAILURE: "GET_ASIGNATE_CURRENTUSER_FAILURE",
};
