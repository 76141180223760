import { catalogConstants } from '../constants/index.js'
import { getGenericControls } from '../../utils/index.js'

export const GetCatalog = (state = {}, action) => {
    switch (action.type) {
        case catalogConstants.CATALOG_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case catalogConstants.CATALOG_SUCCESS:
            return {
                loading: false,
                items: action.catalog.Body,
                error: undefined
            }
        //break;
        case catalogConstants.CATALOG_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}
