import { Box, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import React from 'react'
import { UseImage } from './UseImage'
import img from '../assets/Busqueda_folio.png'

export const useStyles = makeStyles((theme) => ({
    imageNotFound: {
        maxWidth: 300
    }
}))

export default function NoShares(props) {

    const classes = useStyles()

    return (
        <div>
            <Grid item lg={12} xs={12}>
                <Box display={'flex'} justifyContent='center' m={5}>
                    <UseImage src={img} type='img' className={classes.imageNotFound} />
                </Box>
                <Box textAlign={'center'} m={5}>
                    <Typography variant='h5'>
                        {!!props.title ? props.title : 'No tienes acciones asignadas en este momento'}
                    </Typography>
                </Box>
            </Grid>
        </div>
    )
}
