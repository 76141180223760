import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const fileMasiveRequests = {}

fileMasiveRequests.UploadBatchFile2 = (data) => {
  return axios.post(
    AppUrls.UploadBatchFile2,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

fileMasiveRequests.InitializeBatchFile = (data) => {
  return axios.post(
    AppUrls.InitializeBatchFile,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

fileMasiveRequests.GetByUsuarioRolBusquedaPortalGrupos = (data) => {
  return axios.post(
    AppUrls.GetByUsuarioRolBusquedaPortalGrupos,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

fileMasiveRequests.GetByUsuarioRolDetalleGrupo = (data) => {
  return axios.post(
    AppUrls.GetByUsuarioRolBusquedaPortalDetalleGrupo,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

fileMasiveRequests.GetByUsuarioGruposStatus = (data) => {
  return axios.post(
    AppUrls.GetByUsuarioRolBusquedaPortalGruposStatus,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

fileMasiveRequests.UploadBatchDocumentProcess = (data) => {
  return axios.post(
    AppUrls.UploadBatchDocumentProcess,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

fileMasiveRequests.GetUsersInformationMetadadaMasive = (data) => {
  return axios.post(
    AppUrls.UploadBatchMetadata,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

fileMasiveRequests.UploadUsersInformationMetadadaMasive = (data) => {
  return axios.post(
    AppUrls.UploadBatchMetadataProcess,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

fileMasiveRequests.DownloadDocumentContent = (data) => {
  console.log(data, 'data')
  return axios.get(
    AppUrls.DownloadDocumentContent + "?batchUpload_Id=" + data,
    {
      responseType: "blob",
      headers: {
        //Configuración de headers a enviar al consumir cualquier API ***Incluye token de autorización
        // "Access-Control-Allow-Origin": "*",
        Accept: "application/json", //JSON
        "Content-Type": "application/json;charset=UTF-8", //Esperamos un json con configuración UTF-8
        Authorization: `${sessionStorage.getItem("token")}`, //Se añade Token   Content-Type: multipart/form-data; boundary=something
      },
    }
  ).then((response) => {
    console.log('responseresponse', response)
    return response.data;
  })
}


fileMasiveRequests.UploadBatchFile = async (data) => {
  axios.post(AppUrls.UploadBatchFile, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
      // 'Content-Type': 'application/json;charset=UTF-8',
      'Content-Disposition': 'form-data'
    },
  }).then(response => {
    console.log('response.data', response.data)
    const data = JSON.parse(response.data);
    console.log(data);
  }).catch(error => {
    console.error(error);
  });
}


fileMasiveRequests.UploadBatchDocument = async (data) => {
  return axios.post(AppUrls.UploadBatchDocument, data, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      // 'Access-Control-Allow-Origin': '*',
      'Content-Type': 'multipart/form-data',
      // 'Content-Type': 'application/json;charset=UTF-8',
      'Content-Disposition': 'form-data'
    },
  }).then(handleResponse)
  // .then(response => {
  //   console.log('response.data',response.data)
  //   const data = JSON.parse(response.data);
  //   console.log(data);
  // }).catch(error => {
  //   console.error(error);
  // });
}

// .then(handleResponse)

let handleResponse = (response) => {
  console.log('responseresponse', response)
  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Message || responseObject);
    console.log(responseObject)
    console.log(error)
    return { error };
  }
  return respObject;
}