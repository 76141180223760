import { executeSearchConstants } from '../constants/index.js'

export const SearchData = (state = {}, action) => {
    switch (action.type) {
        case executeSearchConstants.SEARCH_REQUEST:
            return { loading: true, items: undefined, error: undefined }
            //break;
        case executeSearchConstants.SEARCH_SUCCESS:
            return { loading: false, items: action.searchData, error: undefined }
            //break;
        case executeSearchConstants.SEARCH_FAILURE:
            return { loading: false, items: undefined, error: action.error }
            //break;
        default:
            return state
    }
}

export const SelectOptionData = (state = {}, action) => {
    switch (action.type) {
        case executeSearchConstants.SELECTED_OPTION_REQUEST:
            return { loading: true, items: undefined, error: undefined }
            //break;
        case executeSearchConstants.SELECTED_OPTION_SUCCESS:
            return { loading: false, items: action.optionData.Body, error: undefined }
            //break;
        case executeSearchConstants.SELECTED_OPTION_FAILURE:
            return { loading: false, items: undefined, error: action.error }
            //break;
        default:
            return state
    }
}