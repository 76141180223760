import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux"; // Asumiendo que usas Redux
import { notificationActions } from "../store/actions";

export function RoutesListener() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    // Dispatch an action every time the location changes
    if (!location.pathname.includes("/auth") && location.pathname !== "/") {
      dispatch(notificationActions.GetNotificationsUser({}));
    }
  }, [dispatch, location.pathname]);

  return null; // No render component
}
