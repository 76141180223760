import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const notificationRequests = {}

notificationRequests.GetNewPin = (data) => {
  return axios.post(
    AppUrls.GetNewPin,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigNoAuth()
  ).then(handleResponse)
}

notificationRequests.GetNotificationsUser = (data) => {
  return axios.post(
    AppUrls.GetByUsuarioRolNotificacionesPortal,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

notificationRequests.GetMetadataFill = (data) => {
  return axios.post(
    AppUrls.GetMetadataFill,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

notificationRequests.GetCancelables = (data) => {
  return axios.post(
    AppUrls.GetCancelables,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

notificationRequests.UpdateCancelFiles = (data) => {
  return axios.post(
    AppUrls.UpdateCancelFiles,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}


let handleResponse = (response) => {
  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  return respObject;
}