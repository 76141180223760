import { Icon } from "@iconify/react";
import {
  Badge,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationActions } from "../store/actions";
import { UseImage } from "./UseImage";
import finalizado from "../assets/ContratoFinalizado.png";
import vencido from "../assets/ContratoVencido.png";
import asignado from "../assets/ContratoAsignado.png";
import rezagado from "../assets/ContratoRezagado.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../utils";
import NotificationsIcon from '../assets/icons/notificationsIcon.svg'
// import { mainStyles, theme } from '../styles'

export default function NotificationsView() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useNavigate();
  const stateNotifications = useSelector(
    (state) => state?.GetNotificationsUser
  );

  const [notiList, setNotiList] = useState([]);

  const [open, setOpen] = useState(false);

  /* useEffect(() => {
    dispatch(notificationActions.GetNotificationsUser({}));
  }, []); */

  /*   useEffect(() => {
    if (stateNotifications?.items != null) {
      const updatedList = [];

      stateNotifications.items.map((noti) => {
        const newDate = new Date();
        updatedList.push({ ...noti, time: (newDate - new Date()) / 3600000 });
      });
      console.log("updatedList:::", updatedList);
      setNotiList(updatedList);
    }
  }, [stateNotifications]); */

  return (
    <>
      <Box
        style={{
          marginLeft: ".8em",
          color: theme.palette.userStyle.icNotificaciones,
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          style={{ color: theme.palette.userStyle.icNotificaciones }}
          onClick={() => setOpen(!open)}
        >
          <Badge color="error" badgeContent={stateNotifications?.items?.length}>
            {/* <Icon fontSize={38} icon="ic:outline-notifications-active" /> */}
            <UseImage src={NotificationsIcon} style={{width: '30px'}} type="img" />
          </Badge>
        </IconButton>
      </Box>

      <Dialog
        maxWidth={"md"}
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent style={{ maxHeight: "70vh", overflow: "auto" }}>
          <Box display={"flex"}>
            <Box flexGrow={1} style={{ marginLeft: "1em" }}>
              <Typography
                style={{
                  color: theme.palette.userStyle.icNotificaciones,
                  fontSize: "25px",
                  fontWeight: 600,
                }}
              >
                Notificaciones
              </Typography>
            </Box>
            <Box
              sx={{
                "&:hover": { cursor: "pointer" },
                alignContent: "center",
                paddingX: 1,
              }}
            >
              <Icon
                fontSize={20}
                onClick={() => setOpen(!open)}
                icon="material-symbols:close"
              />
            </Box>
          </Box>
          {stateNotifications?.items?.length > 0 ? (
            stateNotifications?.items?.map((item) => (
              <Box
                onClick={() => {
                  setOpen(!open);
                  history(`${routes.alertaNotificaciones}/${item.File_Id}`);
                  window.location.reload();
                }}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                  border: `2px solid ${
                    item.TipoNotificacion === "Destiempo"
                      ? "#FFBB33"
                      : item.TipoNotificacion === "En tiempo"
                      ? "#00847F"
                      : item.TipoNotificacion === "Vencido"
                      ? "#A8000B"
                      : null
                  }`,
                  minWidth: 480,
                  margin: "1em",
                  // borderRadius: "5px",
                  py: 2,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    px: 2,
                    width: "80%",
                    color: "black",
                  }}
                >
                  <Box sx={{ minWidth: "75%" }}>
                    <Box sx={{ textAlign: "left", margin: ".3em" }}>
                      <Typography
                        sx={{ fontWeight: 600, fontSize: "1rem !important" }}
                      >
                        Folio: {item.Code}
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "left", margin: ".3em" }}>
                      {item?.MetadatasFill?.map((mt) => (
                        <Typography>{mt.Name}</Typography>
                      ))}
                      {/* <Typography>{item.DatoFaltante}</Typography> */}
                    </Box>
                    <Box style={{ textAlign: "left", margin: ".3em" }}>
                      <Typography>Implant: {item.FullNameImplant}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    color: "black",
                    position: "relative",
                    // right: 60,
                    textAlign: 'right',
                    pr: 1,
                    minWidth: '10em'
                  }}
                >
                  <Typography sx={{ fontSize: "0.7em !important" }}>
                    {item.LastActivityDate}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "30vw",
                minHeight: "30vh",
                p: 3,
              }}
            >
              <Typography variant="h5">
                No hay notificaciones para mostrar.
              </Typography>
            </Stack>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
