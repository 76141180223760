import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress, Box, Typography, Button,
} from '@mui/material'
import { fileActions, productActions } from '../../store/actions'
import protect from '../../utils/protect'
import {
  FormDynamic,
  Alert, InformationalMessageComponent,CustomLoadingOverlay,
} from '../../components'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'

function RegistrationCandidatesComponent(props) {
  const navigation = useNavigate()
  const [listControls, setListControls] = useState(null)
  const [isRegister, setIsRegister] = useState(null)
  const [dataCurrent, setDataCurrent] = useState(null)

  const productData = useSelector(state => state.ProductHiringData)
  const generateFolioData = useSelector(state => state.GenerateFolioHiringData)
  const dispatch = useDispatch()

  useEffect(() => {
    console.log()
    dispatch(productActions.GetProductHiring(""))
  }, [])

  useEffect(() => {
    if (productData.items !== undefined) {
      setListControls(productData.items)
      // navigation('/inbox/search')
      console.log("productData--- Items-----------: ", productData)
    }
    else if (productData.error !== undefined) {
      console.log(productData.error)
      // AlertCustom({
      //   icon: 0,
      //   title: productData.error,
      // });
    }
  }, [productData])

  useEffect(() => {
    if (generateFolioData.items !== undefined && isRegister) {
      console.log("generateFolioData--- Items-----------: ", generateFolioData)
      setDataCurrent(generateFolioData.items)
      setListControls(null)
      setIsRegister(false)
      Alert({
        icon: "success",
        title: "Registro correcto.",
      });
      // dispatch(productActions.GetProductHiring(""))
    } else if (generateFolioData.error !== undefined && isRegister) {
      setIsRegister(false)
      Alert({
        icon: 0,
        title: generateFolioData.error,
      });
    }
  }, [generateFolioData])


  const handleSaveFile = async (formData, e) => {
    console.log("formData, e**********: ", formData, e)

    let newListMetdata = []
    listControls.QuestionDefinitions.forEach((itemMetadata, i) => {
      if (itemMetadata.Name !== 'Primer Nombre' && itemMetadata.Name !== 'Segundo Nombre' &&
        itemMetadata.Name !== 'Primer Apellido' && itemMetadata.Name !== 'Segundo Apellido' &&
        itemMetadata.Name !== 'Correo electrónico' && itemMetadata.Name !== 'Teléfono') {
        newListMetdata.push({
          GroupName: itemMetadata.QuestionGroup,
          Name: itemMetadata.Name,
          Value: formData[itemMetadata.Name],
        })
      }
    })

    console.log("newListMetdata: ", newListMetdata)
    let requestData = {
      Name: formData["Primer Nombre"],
      SecondName: formData["Segundo Nombre"],
      FatherLastName: formData["Primer Apellido"],
      MotherLastName: formData["Segundo Apellido"],
      Email: formData["Correo electrónico"],
      PhoneNumber: formData["Teléfono"],
      Metadata: newListMetdata?.length > 0 ? newListMetdata : null
    }

    console.log("requestData: ", requestData)
    dispatch(fileActions.GenerateFolioHiring(requestData))
    setIsRegister(true)
  }

  const handleResset = (e) => {
    console.log("Ejecuta action resset:")
    setDataCurrent(null)
    Alert({
      icon: "success",
      title: "Usuario activado.",
    });
    dispatch(productActions.GetProductHiring(""))
  }

  console.log(listControls, 'listControls')

  return (
    <div >
      {/* {dataCurrent !== undefined && dataCurrent !== null ?
        <InformationalMessageComponent actionResset={handleResset} Data={dataCurrent} /> : null} */}

      {productData.loading ?
        <CustomLoadingOverlay
        active={productData.loading} text="Espera un momento por favor..."
    /> :
        listControls !== null ?
          <>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
                <Button
                    variant="contained"
                    startIcon={<Icon fontSize={25} icon="ph:users-three" />}
                    sx={{ height: 45, px: 10,  maxWidth: 300 }}
                    onClick={() => { navigation('/inbox/bulkLoadUsers') }} >
                    Registro masivo
                  </Button>
            </Box>
            <FormDynamic TextButton="Registrar" DataControls={listControls} handleSave={handleSaveFile} />
          </>
          : null
      }
    </div>)
}

export default protect(RegistrationCandidatesComponent)