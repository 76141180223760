import { ticketsConstants } from '../constants/index.js'

export const TicketsInfo = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_INFO_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_INFO_SUCCESS:
            return {
                loading: false,
                items: action.ticketInfo?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_INFO_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const TicketsInfoByFile = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_BY_INFO_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_BY_INFO_SUCCESS:
            return {
                loading: false,
                items: action.ticketInfoByFile?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_BY_INFO_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const TicketDocs = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_DOCS_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_DOCS_SUCCESS:
            return {
                loading: false,
                items: action.ticketDocs?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_DOCS_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}
export const ExportTicketExcel = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_EXCEL_EXPORT_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_EXCEL_EXPORT_SUCCESS:
            return {
                loading: false,
                items: action.excelExport,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_EXCEL_EXPORT_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const TicketsInfoBySearch = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_BY_SEARCH_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_BY_SEARCH_SUCCESS:
            return {
                loading: false,
                items: action.ticketInfoBySearch?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_BY_ISEARCH_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const TicketsBySearch = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_SEARCH_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_SEARCH_SUCCESS:
            return {
                loading: false,
                items: action.ticketsSearch?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_SEARCH_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const TicketsByFolio = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_FOLIO_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_FOLIO_SUCCESS:
            return {
                loading: false,
                items: action.ticketsFolio?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_FOLIO_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const TicketSingle = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_SINGLE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_SINGLE_SUCCESS:
            return {
                loading: false,
                items: action.ticketSingle?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_SINGLE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const CreateTicketSeguimiento = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_CREATE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_CREATE_SUCCESS:
            return {
                loading: false,
                items: action.createTicket?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_CREATE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const TicketUpdate = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_UPDATEMETADATA_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_UPDATEMETADATA_SUCCESS:
            return {
                loading: false,
                items: action.ticketUpdateData?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_UPDATEMETADATA_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const TicketEstatusProceso = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_ESTATUSPROCESO_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_ESTATUSPROCESO_SUCCESS:
            return {
                loading: false,
                items: action.ticketEstatusProceso?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_ESTATUSPROCESO_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const TicketContactoList = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_CONTACTOLIST_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_CONTACTOLIST_SUCCESS:
            return {
                loading: false,
                items: action.ticketContactoList?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_CONTACTOLIST_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const TicketAreaRespList = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_AREARESPLIST_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_AREARESPLIST_SUCCESS:
            return {
                loading: false,
                items: action.ticketAreaRespList?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_AREARESPLIST_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const TicketEjecutivoList = (state = {}, action) => {
    switch (action.type) {
        case ticketsConstants.TICKETS_EJECUTIVOLIST_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_EJECUTIVOLIST_SUCCESS:
            return {
                loading: false,
                items: action.ticketEjecutivoList?.Body,
                error: undefined
            }
        //break;
        case ticketsConstants.TICKETS_EJECUTIVOLIST_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}