import { useTheme } from "@mui/styles";
import { useStyles } from "../tabs/TabsStyle";
import { ApiUrl } from "../../utils";
import { ticketRequest } from "../../services";
import { Box, Grid, Stack, TextField, Button, IconButton } from "@mui/material";
import { ticketActions } from "../../store/actions";
import { CustomLoadingOverlay, GenericHeaderActions, UseImage } from "../index";
import excelIcon from "../../assets/excel.png";
import { TableTicket } from "../table";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const TicketsTableView = (props) => {
  const theme = useTheme();
  const localStyles = useStyles(theme);
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [ticketList, setTicketList] = useState([]);

  const ticketListInfo = useSelector((state) => state.TicketsBySearch);
  const infoFile = useSelector((state) => state.FileInfo);
  // const ticketFolioInfo = useSelector(state => state.TicketsByFolio)

  useEffect(() => {
    const objSearch = {
      File_Id: props.folio,
      Busqueda: "",
    };
    dispatch(ticketActions.GetTicketsBySearch(objSearch));
  }, []);

  useEffect(() => {
    console.log("ticket list table: ", ticketListInfo);
    if (ticketListInfo?.items !== undefined) {
      const filtered = ticketListInfo?.items.filter(
        (ticket) => ticket?.File_Id == props.folio
      );
      console.log("Filtered: ", filtered);
      setTicketList(filtered);
    }
  }, [ticketListInfo]);

  useEffect(() => {
    if (searchText === "") {
      props.onClearSearch();
    }
  }, [searchText]);

  const handleExporExcel = () => {
    ticketRequest
      .ExportTicketExcel({ File_Id: infoFile?.items?.Id })
      .then((response) => {
        console.log("Response excel:", response);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Seguimiento.xlsx");

        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.error("Error al descargar el archivo:", error));
  };
  console.log("window.innerHeight:: ", window.innerHeight);
  return (
    <>
      {ticketListInfo?.loading == true ? (
        <CustomLoadingOverlay
          active={ticketListInfo?.loading}
          text="Espera un momento por favor..."
        />
      ) : (
        <div style={{ height: `${window.innerHeight - 350}px` }}>
          <Stack
            display={"flex"}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"start"}
          >
            <Box
              sx={{ mr: 1, mb: 1, "&:hover": { cursor: "pointer" } }}
              onClick={props.onClickReturn}
            >
              <ArrowBackIcon />
            </Box>
            <GenericHeaderActions
              LabelTitle="Seguimiento a siniestro"
              onClickConfirm={props.onClickNew}
              ConfirmButtonText="Nuevo"
            />
          </Stack>

          <div /* className={localStyles.scroll} */>
            <Stack
              alignItems={"center"}
              justifyContent={"flex-start"}
              direction={"column"}
              width={"100%"}
            >
              <Stack
                alignItems={"center"}
                justifyContent={"space-between"}
                direction={"row"}
                width={"100%"}
                sx={{ mb: 1 }}
              >
                <Stack
                  width={"100%"}
                  direction={"row"}
                  alignItems={"start"}
                  justifyContent={"center"}
                >
                  <TextField
                    value={searchText}
                    placeholder={
                      "Busca la solicitud por ID Ticket, Etapa o Responsable"
                    }
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      e.key === "Enter" && props.onSearch(searchText);
                    }}
                  />
                </Stack>
                <Stack
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  direction={"row"}
                  sx={{ px: 1 }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"start"}
                    justifyContent={"center"}
                  >
                    <Grid
                      sx={{
                        paddingTop: "0px !important",
                        paddingLeft: "6px !important",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          height: 50,
                          minWidth: 220,
                          maxWidth: 228,
                          textTransform: "none !important",
                        }}
                        onClick={() => {
                          props.onSearch(searchText);
                        }}
                      >
                        Buscar
                      </Button>
                    </Grid>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"start"}
                    justifyContent={"center"}
                  >
                    <Grid
                      sx={{
                        paddingTop: "0px !important",
                        paddingLeft: "6px !important",
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          p: 0,
                          height: 50,
                          width: 220,
                          textTransform: "none !important",
                          color: "#333333",
                          borderColor: "#333333",
                          "&:hover": {
                            backgroundColor: "#F3F3F3",
                            borderColor: "#333333",
                          },
                        }}
                        onClick={(e) => {
                          setSearchText(""); /*  props.onClearSearch() */
                        }}
                      >
                        Limpiar búsqueda
                      </Button>
                    </Grid>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"start"}
                    justifyContent={"center"}
                    sx={{ width: "60px", height: "50px" }}
                  >
                    <Grid>
                      <IconButton
                        onClick={() => {
                          handleExporExcel();
                        }}
                      >
                        <UseImage
                          src={excelIcon}
                          className={localStyles.imgExcel}
                          type="img"
                        />
                      </IconButton>
                    </Grid>
                  </Stack>
                </Stack>
              </Stack>
              <Box sx={{ width: "100%" }}>
                <TableTicket
                  data={ticketList}
                  onClickSee={props.onClickSee}
                  onClickFollow={props.onClickFollow}
                />
              </Box>
            </Stack>
          </div>
        </div>
      )}
    </>
  );
};
