import { productConstants } from '../constants'
import { productRequest } from '../../services'

export const productActions = {}

productActions.GetProductRemote = (data) => {
  return dispatch => {
    dispatch(request())
    productRequest.GetProductRemote(data)
      .then(
        product => dispatch(success(product)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: productConstants.PRODUCT_REQUEST }
  }

  function success(product) {
    return { type: productConstants.PRODUCT_SUCCESS, product }
  }

  function failure(error) {
    return { type: productConstants.PRODUCT_FAILURE, error }
  }
}

productActions.GetProductHiring = (data) => {
  return dispatch => {
    dispatch(request())
    productRequest.GetProductHiring(data)
      .then(
        productData => dispatch(success(productData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: productConstants.PRODUCT_HIRING_REQUEST }
  }

  function success(productData) {
    return { type: productConstants.PRODUCT_HIRING_SUCCESS, productData }
  }

  function failure(error) {
    return { type: productConstants.PRODUCT_HIRING_FAILURE, error }
  }

}