import { workFlowConstants } from '../constants/index.js'

export const BinnacleInfo = (state = {}, action) => {
    switch (action.type) {
        case workFlowConstants.WF_BINNACLE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case workFlowConstants.WF_BINNACLE_SUCCESS:
            return {
                loading: false,
                items: action.binnacleInfo.Body,
                error: undefined
            }
        //break;
        case workFlowConstants.WF_BINNACLE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const ContinueWorkFlowData = (state = {}, action) => {
    switch (action.type) {
        case workFlowConstants.CONTINUE_WF_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case workFlowConstants.CONTINUE_WF_SUCCESS:
            return {
                loading: false,
                items: action.contineWFData.Body,
                error: undefined
            }
        //break;
        case workFlowConstants.CONTINUE_WF_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}

export const RejectWorkFlowData = (state = {}, action) => {
    switch (action.type) {
        case workFlowConstants.REJECT_WF_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case workFlowConstants.REJECT_WF_SUCCESS:
            return {
                loading: false,
                items: action.rejectWFData.Body,
                error: undefined
            }
        //break;
        case workFlowConstants.REJECT_WF_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}


export const ReturnStageData = (state = {}, action) => {
    switch (action.type) {
        case workFlowConstants.RETURN_STAGE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        //break;
        case workFlowConstants.RETURN_STAGE_SUCCESS:
            return {
                loading: false,
                items: action.returnStageData.Body,
                error: undefined
            }
        //break;
        case workFlowConstants.RETURN_STAGE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        //break;
        default:
            return state
    }
}