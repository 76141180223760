import { ApiUrl } from '../utils'

const Api = {
  //Complemento de url de api
  authentication: "authentication/authentication",
  authenticationReferer: "authentication/authenticationwithrefer",
  getClientIPsWithRefer: 'authentication/GetClientIPsWithRefer',
  verifyLogInValidity: "authentication/VerifyLogInValidity",
  unAuthenticateByUserName: "authentication/UnAuthenticateByUserName",
  getFileInfo: "file/GetFileInfo",
  getUserSearch: "file/GetByUsuarioRolBusquedaPortal",
  getUserSearchPagination: "file/GetByUsuarioRolBusquedaPortalPaginado",
  getSearch: "file/GetFilesBySearch",
  getRecord: "file/GetCustomMetadataFile",
  getActors: "file/GetActors",
  getActor: "file/GetActor",
  generateFolio: "file/GenerateFolioRemote",
  getBinnacle: "workflow/GetStageRecords",
  postFvalidation: "validation/RegisterFileValidation",
  validateDocument: "validation/RegisterDocumentValidation",
  catpchaValidation: "validation/ValidateCAPTCHA",
  validatePin: "validation/ValidatePINChangePassword",
  docExternalVal: "validation/RegisterExternalDocumentValidation",
  getResultadosValidaciones: "validation/GetResultadosValidaciones",
  getDocument: "document/DownloadDocumentContent",
  getDocumentInfo: "document/GetDocumentInfo",
  downloadDocument: "document/DownloadDocument",
  getTicketsByFile: "Ticket/GetTicketsByFile",
  getTicketsBySearch: "Ticket/GetTicketsBySearchWD",
  // getTicketsBySearch: "Ticket/GetTicketsBySearsh",
  getTicketsByFolio: "Ticket/GetTicketsByFolio",
  addUpdateTicketSeguimiento: "Ticket/AddUpdateTicketSeguimiento",
  getTicketEstatusProceso: "Seguimiento/GetEstatusProceso",
  getTicketContactoList: "Seguimiento/MedioContacto",
  getTicketAreaRespList: "Seguimiento/AreaResponsable",
  getTicketEjecutivoList: "Seguimiento/Ejecutivo",
  // getInfoByFile: "Ticket/GetInfoByFile",
  getTicketSingle: "Ticket/GetTicketsSeguimientoByFile",
  getInfoByFile: "Ticket/GetTicketsSeguimientoBySearsh",

  getInfoTicketsBySearsh: "Ticket/GetInfoTicketsBySearsh",
  getTicketsDocs: "Ticket/GetTicketsBySearsh",
  exportTicketExcel: "Ticket/ExportarExcel2",
  createTicketSeguimiento: 'Ticket/CreateTicketSeguimiento',
  updateMetadata: "document/UpdateMetadata",
  bioDocument: "document/DownloadSectionContent",
  bioDocumentVersion: "document/DownloadSectionVersionContent",
  getProduct: "product/GetProductsRemote",
  getCatalog: "product/GetCatalog",
  changePassword: "user/RegisterChangePassword",
  recoverChangePassword: 'user/UpdateUserMC'/* 'user/ChangePassword' */,
  biometricData: "biometric/GetBiometricData",
  getVerificationData: "biometric/GetVerificationData",
  newPin: "notification/NotificationPINChangePassword",
  getRegisterGeolocationFile: "validation/GetRegisterGeolocationFile",
  getRoles: "role/GetRoles",
  getListBranchOffice: "external/GetListBranchOffice",
  getUsers: "user/GetUsers",
  registerUserRole: "user/RegisterUserRole",
  updateUserRole: "user/UpdateUserRole",
  getCustomValidationFile: "validation/GetCustomValidationFile",
  fileUpdateMetadata: "file/UpdateMetadata",
  asignateCurrentUserNew: "file/AsignateCurrentUser",
  processValidationRules: "validation/ProcessValidationRules",
  continueWorkFlow: "workflow/ContinueWorkFlow",
  rejectWorkFlow: "workflow/RejecWorkFlow",
  getProductHiring: "product/GetProductHiring",
  generateFolioHiring: "file/GenerateFolioHiring",
  uploadSection: 'document/UploadSection',
  cargaDocumentoWithSection: 'extraction/CargaDocumentoWithSection',
  registerMetadata: 'document/RegisterExtraccionData',
  ocrData: 'extraction/OcrExtractionResult',
  executeSearchCp: 'ExecuteSearch/GetByCodigoPostal',//Obtener datos de catálogo CP
  asignateCurrentUser: 'file/AsignateCurrentUser',//Obtener datos DE EXCEL POR B64

  getDocumentsGroupByRol: 'document/GetDocumentsGroupByRol',//Obtener datos DE EXCEL POR B64

  uploadBatchFile: 'flujomasivo/UploadBatchFile',//Obtener datos de EXCEL POR FORM
  uploadBatchFile2: 'flujomasivo/UploadBatchFile2',//Obtener datos de EXCEL POR FORM
  initializeBatchFile: 'flujomasivo/InitializeBatchFile',//Iniciar flujo masivo 
  getByUsuarioRolBusquedaPortalGrupos: 'flujomasivo/GetByUsuarioRolBusquedaPortalGrupos',//Obtener datos DE EXCEL POR B64
  getByUsuarioRolBusquedaPortalDetalleGrupo: 'flujomasivo/GetByUsuarioRolBusquedaPortalDetalleGrupo',//Obtener datos DE EXCEL POR B64
  getByUsuarioRolBusquedaPortalGruposStatus: 'flujomasivo/GetByUsuarioRolBusquedaPortalGruposStatus',//Obtener datos DE EXCEL POR B64
  uploadBatchDocument: 'flujomasivo/UploadBatchDocument',//Obtener datos DE EXCEL POR B64
  uploadBatchDocumentProcess: 'flujomasivo/UploadBatchDocumentProcess',//Obtener datos DE EXCEL POR B64
  downloadDocumentContent: 'flujomasivo/DownloadDocumentContent',
  uploadBatchMetadata: 'flujomasivo/UploadBatchMetadata',
  uploadBatchMetadataProcess: 'flujomasivo/UploadBatchMetadataProcess',

  getByUsuarioRolNotificacionesPortal: 'file/GetFilesNotifications',
  getMetadataFill: 'file/GetFileMetadataFill',
  getBySelectedOption: 'ExecuteSearch/GetBySelectedOption',
  getCancelables: 'file/GetCancelables',
  updateCancelFiles: 'file/CancelFiles',

  getRolesMC: 'role/GetRolesMC',
  registerUserMC: 'user/RegisterUserMC',
  updateUserMC: 'user/UpdateUserMC',
  getUsersMC: 'user/GetUsersMC',
  getProductUserMC: 'product/GetProductUserMC',
  returnStage: 'workflow/ReturnStage'
};

export const AppUrls = {
  //Concatenación y retorno de URL según ambiente seleccionado
  // Authentication
  Authentication: `${ApiUrl.ambient}${Api.authentication}`,
  AuthenticationReferer: `${ApiUrl.ambient}${Api.authenticationReferer}`,
  GetClientIPsWithRefer: `${ApiUrl.ambient}${Api.getClientIPsWithRefer}`,
  VerifyLogInValidity: `${ApiUrl.ambient}${Api.verifyLogInValidity}`,
  UnAuthenticateByUserName: `${ApiUrl.ambient}${Api.unAuthenticateByUserName}`,
  GetFileInfo: `${ApiUrl.ambient}${Api.getFileInfo}`,
  GetSearchByUser: `${ApiUrl.ambient}${Api.getUserSearch}`,
  GetUserSearchPagination: `${ApiUrl.ambient}${Api.getUserSearchPagination}`,
  GetInfoBySearch: `${ApiUrl.ambient}${Api.getSearch}`,
  GetRecordInfo: `${ApiUrl.ambient}${Api.getRecord}`,
  GetActorsInfo: `${ApiUrl.ambient}${Api.getActors}`,
  GetActorInfo: `${ApiUrl.ambient}${Api.getActor}`,
  GenerateFolio: `${ApiUrl.ambient}${Api.generateFolio}`,
  GetBinnacleInfo: `${ApiUrl.ambient}${Api.getBinnacle}`,
  PostFileValidation: `${ApiUrl.ambient}${Api.postFvalidation}`,
  PostDocumentValidation: `${ApiUrl.ambient}${Api.validateDocument}`,
  SendPinValidation: `${ApiUrl.ambient}${Api.validatePin}`,
  GetCatpcha: `${ApiUrl.ambient}${Api.catpchaValidation}`,
  GetDocument: `${ApiUrl.ambient}${Api.getDocument}`,
  GetDocumentInfo: `${ApiUrl.ambient}${Api.getDocumentInfo}`,
  DownloadDocument: `${ApiUrl.ambient}${Api.downloadDocument}`,
  GetTicketsByFile: `${ApiUrl.ambient}${Api.getTicketsByFile}`,
  GetTicketsBySearch: `${ApiUrl.ambient}${Api.getTicketsBySearch}`,
  GetTicketsByFolio: `${ApiUrl.ambient}${Api.getTicketsByFolio}`,
  AddUpdateTicketSeguimiento: `${ApiUrl.ambient}${Api.addUpdateTicketSeguimiento}`,
  GetTicketEstatusProceso: `${ApiUrl.ambient}${Api.getTicketEstatusProceso}`,
  GetTicketContactoList: `${ApiUrl.ambient}${Api.getTicketContactoList}`,
  GetTicketAreaRespList: `${ApiUrl.ambient}${Api.getTicketAreaRespList}`,
  GetTicketEjecutivoList: `${ApiUrl.ambient}${Api.getTicketEjecutivoList}`,
  GetInfoByFile: `${ApiUrl.ambient}${Api.getInfoByFile}`,
  GetTicketSingle: `${ApiUrl.ambient}${Api.getTicketSingle}`,
  GetInfoTicketsBySearsh: `${ApiUrl.ambient}${Api.getInfoTicketsBySearsh}`,
  GetTicketDocs: `${ApiUrl.ambient}${Api.getTicketsDocs}`,
  ExportTicketExcel: `${ApiUrl.ambient}${Api.exportTicketExcel}`,
  CreateTicketSeguimiento: `${ApiUrl.ambient}${Api.createTicketSeguimiento}`,
  UpdateMetadata: `${ApiUrl.ambient}${Api.updateMetadata}`,
  ValidateDocument: `${ApiUrl.ambient}${Api.validateDocument}`,
  GetBioDocument: `${ApiUrl.ambient}${Api.bioDocument}`,
  GetBioDocumentVersion: `${ApiUrl.ambient}${Api.bioDocumentVersion}`,
  GetProductRemote: `${ApiUrl.ambient}${Api.getProduct}`,
  GetCatalog: `${ApiUrl.ambient}${Api.getCatalog}`,
  ChangePassword: `${ApiUrl.ambient}${Api.changePassword}`,
  RecoverChangePassword: `${ApiUrl.ambient}${Api.recoverChangePassword}`,
  GetBiometricData: `${ApiUrl.ambient}${Api.biometricData}`,
  GetVerificationData: `${ApiUrl.ambient}${Api.getVerificationData}`,
  GetNewPin: `${ApiUrl.ambient}${Api.newPin}`,
  SendDocExternalVal: `${ApiUrl.ambient}${Api.docExternalVal}`,
  GetResultadosValidaciones: `${ApiUrl.ambient}${Api.getResultadosValidaciones}`,
  GetRegisterGeolocationFile: `${ApiUrl.ambient}${Api.getRegisterGeolocationFile}`,
  GetRoles: `${ApiUrl.ambient}${Api.getRoles}`,
  GetListBranchOffice: `${ApiUrl.ambient}${Api.getListBranchOffice}`,
  GetUsers: `${ApiUrl.ambient}${Api.getUsers}`,
  RegisterUserRole: `${ApiUrl.ambient}${Api.registerUserRole}`,
  UpdateUserRole: `${ApiUrl.ambient}${Api.updateUserRole}`,
  GetCustomValidationFile: `${ApiUrl.ambient}${Api.getCustomValidationFile}`,
  FileUpdateMetadata: `${ApiUrl.ambient}${Api.fileUpdateMetadata}`,
  AsignateCurrentUserNew: `${ApiUrl.ambient}${Api.asignateCurrentUserNew}`,
  ProcessValidationRules: `${ApiUrl.ambient}${Api.processValidationRules}`,
  ContinueWorkFlow: `${ApiUrl.ambient}${Api.continueWorkFlow}`,
  RejectWorkFlow: `${ApiUrl.ambient}${Api.rejectWorkFlow}`,
  GetProductHiring: `${ApiUrl.ambient}${Api.getProductHiring}`,
  GenerateFolioHiring: `${ApiUrl.ambient}${Api.generateFolioHiring}`,
  UploadSectionApi: `${ApiUrl.ambient}${Api.uploadSection}`,
  CargaDocumentoWithSection: `${ApiUrl.ambient}${Api.cargaDocumentoWithSection}`,
  RegisterMetadata: `${ApiUrl.ambient}${Api.registerMetadata}`,
  GetOcrData: `${ApiUrl.ambient}${Api.ocrData}`,
  ExecuteSearchCp: `${ApiUrl.ambient}${Api.executeSearchCp}`,
  UploadBatchFile: `${ApiUrl.ambient}${Api.uploadBatchFile}`,
  UploadBatchFile2: `${ApiUrl.ambient}${Api.uploadBatchFile2}`,
  InitializeBatchFile: `${ApiUrl.ambient}${Api.initializeBatchFile}`,
  AsignateCurrentUser: `${ApiUrl.ambient}${Api.asignateCurrentUser}`,
  GetByUsuarioRolBusquedaPortalGrupos: `${ApiUrl.ambient}${Api.getByUsuarioRolBusquedaPortalGrupos}`,
  GetByUsuarioRolBusquedaPortalDetalleGrupo: `${ApiUrl.ambient}${Api.getByUsuarioRolBusquedaPortalDetalleGrupo}`,
  GetByUsuarioRolBusquedaPortalGruposStatus: `${ApiUrl.ambient}${Api.getByUsuarioRolBusquedaPortalGruposStatus}`,
  UploadBatchDocument: `${ApiUrl.ambient}${Api.uploadBatchDocument}`,
  UploadBatchDocumentProcess: `${ApiUrl.ambient}${Api.uploadBatchDocumentProcess}`,
  DownloadDocumentContent: `${ApiUrl.ambient}${Api.downloadDocumentContent}`,
  UploadBatchMetadata: `${ApiUrl.ambient}${Api.uploadBatchMetadata}`,
  UploadBatchMetadataProcess: `${ApiUrl.ambient}${Api.uploadBatchMetadataProcess}`,
  GetDocumentsGroupByRol: `${ApiUrl.ambient}${Api.getDocumentsGroupByRol}`,
  GetByUsuarioRolNotificacionesPortal: `${ApiUrl.ambient}${Api.getByUsuarioRolNotificacionesPortal}`,
  GetMetadataFill: `${ApiUrl.ambient}${Api.getMetadataFill}`,
  GetBySelectedOption: `${ApiUrl.ambient}${Api.getBySelectedOption}`,
  GetCancelables: `${ApiUrl.ambient}${Api.getCancelables}`,
  UpdateCancelFiles: `${ApiUrl.ambient}${Api.updateCancelFiles}`,

  GetRolesMC: `${ApiUrl.ambient}${Api.getRolesMC}`,
  RegisterUserMC: `${ApiUrl.ambient}${Api.registerUserMC}`,
  UpdateUserMC: `${ApiUrl.ambient}${Api.updateUserMC}`,
  GetUsersMC: `${ApiUrl.ambient}${Api.getUsersMC}`,
  GetProductUserMC: `${ApiUrl.ambient}${Api.getProductUserMC}`,
  ReturnStage: `${ApiUrl.ambient}${Api.returnStage}`,

};