import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyles } from '../../../components/table/TableStyle'

import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Tooltip as tooltipClasses,useTheme
} from '@mui/material';
import { Icon } from '@iconify/react'

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 15,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  }
}));


export function TableDataUsers({ data }) {
  const theme = useTheme()
  console.log(data)
  const localStyle = useStyles(theme)
  let path = window.location.pathname

  console.log("data: INFO", data)

  const newData = data?.map((col) => {
    return {
      Nombre: col.Nombre,
      aMaterno: col.ApellidoMaterno,
      segundoNombre: col.SegundoNombre,
      aPaterno: col.ApellidoPaterno,
      Celular: col.Celular,
      Correo: col.Email,
      Area: col.Area,
      Puesto: col.Puesto,
      Pais: col.Pais,
      Clave: col.ClaveGrupo,
      Estatus: col.State,
      Error: col.ErrorToolTip
    }
  })

  const columns = [
    { id: 'Clave', label: 'Clave Grupal', minWidth: 100 },
    { id: 'Nombre', label: 'Primer Nombre', minWidth: 100 },
    { id: 'segundoNombre', label: 'Segundo Nombre', minWidth: 100 },
    { id: 'aPaterno', label: 'Primer Apellido', minWidth: 100 },
    { id: 'aMaterno', label: 'Segundo Apellido', minWidth: 100 },
    { id: 'Celular', label: 'Teléfono', minWidth: 100 },
    { id: 'Correo', label: 'Correo electrónico', minWidth: 100 },
    { id: 'Area', label: 'Área', minWidth: 100 },
    { id: 'Puesto', label: 'Puesto', minWidth: 100 },
    { id: 'Pais', label: 'País', minWidth: 100 },
    { id: 'Estatus', label: 'Estatus', minWidth: 100 },
  ]

  const createData = (Nombre, segundoNombre, aMaterno, aPaterno, Celular, Correo, Area, Puesto, Pais, Clave, Estatus, MessageError) => {
    return { Nombre, segundoNombre, aMaterno, aPaterno, Celular, Correo, Area, Puesto, Pais, Clave, Estatus, MessageError }
  }

  const rows = newData?.map((r) => {
    return createData(r.Nombre, r.segundoNombre, r.aMaterno, r.aPaterno, r.Celular, r.Correo, r.Area, r.Puesto, r.Pais, r.Clave, r.Estatus, r.Error)
  })

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [orderDirection, setOrderDirection] = useState('')
  const [valueToOrderBy, setValueToOrderBy] = useState('')

  const handlerSort = (event, property) => {
    const isAscending = (valueToOrderBy === property && orderDirection === 'asc')
    setValueToOrderBy(property)
    setOrderDirection(isAscending ? 'desc' : 'asc')
  }

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property)
  }

  return (
    <div style={{ margin: '0 1em 0 1em' }}>
      <TableContainer className={localStyle.container}>
        <Table size="medium" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  className={localStyle.head}
                  onClick={createSortHandler(column.label)}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => { //sortRowInformation(rows, getComparator(orderDirection,valueToOrderBy))
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}
                    className={localStyle.tableRow}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'Estatus' ? value === true ?
                            <Icon color="#0FA958" fontSize={28} icon="material-symbols:check-circle-outline-rounded" /> :
                            <Tip title={row.Error} placement="top"><Icon color="#F24E1E" fontSize={28} icon="mdi:close-circle-outline" /></Tip> : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[0]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}