export const userManagerConstants = {
  GET_ROLESMC_REQUEST: 'GET_ROLESMC_REQUEST',
  GET_ROLESMC_SUCCESS: 'GET_ROLESMC_SUCCESS',
  GET_ROLESMC_FAILURE: 'GET_ROLESMC_FAILURE',

  GET_PRODUCTSUSERMC_REQUEST: 'GET_PRODUCTSUSERMC_REQUEST',
  GET_PRODUCTSUSERMC_SUCCESS: 'GET_PRODUCTSUSERMC_SUCCESS',
  GET_PRODUCTSUSERMC_FAILURE: 'GET_PRODUCTSUSERMC_FAILURE',

  REGISTER_USERMC_REQUEST: 'REGISTER_USERMC_REQUEST',
  REGISTER_USERMC_SUCCESS: 'REGISTER_USERMC_SUCCESS',
  REGISTER_USERMC_FAILURE: 'REGISTER_USERMC_FAILURE',

  UPDATE_USERMC_REQUEST: 'UPDATE_USERMC_REQUEST',
  UPDATE_USERMC_SUCCESS: 'UPDATE_USERMC_SUCCESS',
  UPDATE_USERMC_FAILURE: 'UPDATE_USERMC_FAILURE',

  GET_USERSMC_REQUEST: 'GET_USERSMC_REQUEST',
  GET_USERSMC_SUCCESS: 'GET_USERSMC_SUCCESS',
  GET_USERSMC_FAILURE: 'GET_USERSMC_FAILURE',


}

