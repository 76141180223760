import { combineReducers } from "redux";

import {
  Authentication,
  AuthenticationReferer,
  GetClientIPsWithRefer,
  VerifyLogInValidity,
  UnAuthenticateByUserName,
} from "./AuthReducer"; //Importar reducer
import {
  FileInfo,
  SearchByUser,
  SearchByUserPagination,
  InfoBySearch,
  RecordInfo,
  ActorsInfo,
  ActorInfo,
  Folio,
  FileUpdateMetData,
  GenerateFolioHiringData,
  UserCurentData,
} from "./FileReducer";
import {
  TicketsInfo,
  TicketUpdate,
  TicketsBySearch,
  TicketAreaRespList,
  TicketContactoList,
  TicketEjecutivoList,
  TicketEstatusProceso,
  TicketsInfoByFile,
  TicketsInfoBySearch,
  ExportTicketExcel,
  CreateTicketSeguimiento,
  TicketSingle,
  TicketDocs,
  TicketsByFolio,
} from "./TicketsReducer";
import {
  InitializeBatchFile,
  GetBatchFile,
  GetByUsuarioRolDetalleGrupo,
  GetByUsuarioGruposStatus,
  GetByUsuarioRolBusquedaPortalGrupos,
  UploadBatchDocumentProcess,
  GetUsersInformationMetadadaMasive,
  UploadUsersInformationMetadadaMasive,
} from "./FileMasiveReducer";
import {
  BinnacleInfo,
  ContinueWorkFlowData,
  RejectWorkFlowData,
  ReturnStageData,
} from "./WorkFlowReducer";
import {
  FileValidation,
  DocumentValidation,
  catpchaValidation,
  PinValidation,
  DocExternalVal,
  ResultadosVal,
  RegisGeolocationData,
  CustomValidationFile,
  ProcessValRulesData,
} from "./ValidationReducer";
import {
  Document,
  BioDocument,
  BioDocumentVersion,
  DocumentUpdateMetData,
  UploadSections,
  CargaDocWithSecData,
  RegisterMetadaData,
  OcrData,
  GetDocumentsGroupByRol,
  DownloadDocument,
} from "./DocumentReducer";
import { ProductRemote, ProductHiringData } from "./ProductReducer";
import {
  ChangePass,
  UsersAll,
  RegisterUser,
  UpdateUser,
  RecoverChangePassword,
} from "./UserReducer";
import { BiometricData, VerificationData } from "./BiometricReducer";
import {
  NewPin,
  GetNotificationsUser,
  GetMetadataFill,
  GetNotificationsCancelables,
  UpdateCancelFilesNotifications,
} from "./NotificationReducer";
import { RoleAll } from "./RoleReduce";
import { ListBranchOffice } from "./ExternalReducer";
import { SearchData, SelectOptionData } from "./ExecuteSearchReducer";
import {
  RolesMC,
  RegisterUserMC,
  UpdateUserMC,
  GetUsersMC,
  ProductUserMC,
} from "./UserManagerReducer";
import { GetCatalog } from "./CatalogReducer";

const rootReducer = combineReducers({
  Authentication,
  AuthenticationReferer,
  GetClientIPsWithRefer,
  VerifyLogInValidity,
  UnAuthenticateByUserName,
  FileInfo,
  SearchByUser,
  SearchByUserPagination,
  InfoBySearch,
  RecordInfo,
  ActorsInfo,
  ActorInfo,
  Folio,
  TicketsInfo,
  TicketsInfoByFile,
  TicketsInfoBySearch,
  CreateTicketSeguimiento,
  TicketSingle,
  TicketDocs,
  TicketAreaRespList,
  ExportTicketExcel,
  TicketContactoList,
  TicketEjecutivoList,
  TicketUpdate,
  TicketsBySearch,
  TicketsByFolio,
  TicketEstatusProceso,
  BinnacleInfo,
  FileValidation,
  DocumentValidation,
  catpchaValidation,
  PinValidation,
  DocExternalVal,
  ResultadosVal,
  Document,
  BioDocument,
  BioDocumentVersion,
  ProductRemote,
  ChangePass,
  BiometricData,
  VerificationData,
  NewPin,
  RegisGeolocationData,
  RoleAll,
  ListBranchOffice,
  UsersAll,
  RegisterUser,
  UpdateUser,
  RecoverChangePassword,
  CustomValidationFile,
  FileUpdateMetData,
  ContinueWorkFlowData,
  RejectWorkFlowData,
  ReturnStageData,
  ProcessValRulesData,
  DocumentUpdateMetData,
  ProductHiringData,
  GenerateFolioHiringData,
  CargaDocWithSecData,
  UploadSections,
  RegisterMetadaData,
  OcrData,
  SearchData,
  GetBatchFile,
  UserCurentData,
  InitializeBatchFile,
  GetByUsuarioRolBusquedaPortalGrupos,
  GetByUsuarioRolDetalleGrupo,
  GetByUsuarioGruposStatus,
  UploadBatchDocumentProcess,
  GetUsersInformationMetadadaMasive,
  UploadUsersInformationMetadadaMasive,
  GetDocumentsGroupByRol,
  DownloadDocument,
  GetNotificationsUser,
  GetMetadataFill,
  SelectOptionData,
  GetNotificationsCancelables,
  UpdateCancelFilesNotifications,
  RolesMC,
  RegisterUserMC,
  UpdateUserMC,
  GetUsersMC,
  ProductUserMC,
  GetCatalog,
});

export default rootReducer;
