import { validationConstants } from '../constants'
import { validationRequests } from '../../services'

export const validationActions = {}

validationActions.PostFileValidation = (data) => {
  if (data == null || data == {}) {
    return dispatch => {
      dispatch(failure("Datos no reconocidos"))
    }
  }

  return dispatch => {
    dispatch(request())
    validationRequests.PostFileValidation(data)
      .then(
        fileValidation => dispatch(success(fileValidation)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: validationConstants.VALIDATION_FILE_REQUEST }
  }

  function success(fileValidation) {
    return { type: validationConstants.VALIDATION_FILE_SUCCESS, fileValidation }
  }

  function failure(error) {
    return { type: validationConstants.VALIDATION_FILE_FAILURE, error }
  }

}

validationActions.PostDocumentValidation = (data) => {
  return dispatch => {
    dispatch(request())
    validationRequests.PostDocumentValidation(data)
      .then(
        documentValidation => dispatch(success(documentValidation)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: validationConstants.VALIDATION_DOCUMENT_REQUEST }
  }

  function success(documentValidation) {
    return { type: validationConstants.VALIDATION_DOCUMENT_SUCCESS, documentValidation }
  }

  function failure(error) {
    return { type: validationConstants.VALIDATION_DOCUMENT_FAILURE, error }
  }

}

validationActions.GetCatpcha = (data) => {
  return dispatch => {
    dispatch(request())
    validationRequests.GetCatpcha(data)
      .then(
        catpcha => dispatch(success(catpcha)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: validationConstants.VALIDATION_CATPCHA_REQUEST }
  }

  function success(catpcha) {
    return { type: validationConstants.VALIDATION_CATPCHA_SUCCESS, catpcha }
  }

  function failure(error) {
    return { type: validationConstants.VALIDATION_CATPCHA_FAILURE, error }
  }

}

validationActions.SendPinValidation = (data) => {
  return dispatch => {
    dispatch(request())
    validationRequests.SendPinValidation(data)
      .then(
        pinRes => dispatch(success(pinRes)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: validationConstants.VALIDATION_PIN_REQUEST }
  }

  function success(pinRes) {
    return { type: validationConstants.VALIDATION_PIN_SUCCESS, pinRes }
  }

  function failure(error) {
    return { type: validationConstants.VALIDATION_PIN_FAILURE, error }
  }

}

validationActions.SendDocExternalVal = (data) => {
  return dispatch => {
    dispatch(request())
    validationRequests.SendDocExternalVal(data)
      .then(
        docExtVal => dispatch(success(docExtVal)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: validationConstants.DOCEXTERNAL_VAL_REQUEST }
  }

  function success(docExtVal) {
    return { type: validationConstants.DOCEXTERNAL_VAL_SUCCESS, docExtVal }
  }

  function failure(error) {
    return { type: validationConstants.DOCEXTERNAL_VAL_FAILURE, error }
  }
}

validationActions.GetResultadosValidaciones = (data) => {
  return dispatch => {
    dispatch(request())
    validationRequests.GetResultadosValidaciones(data)
      .then(
        resultVal => dispatch(success(resultVal)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: validationConstants.RESULT_VAL_REQUEST }
  }

  function success(resultVal) {
    return { type: validationConstants.RESULT_VAL_SUCCESS, resultVal }
  }

  function failure(error) {
    return { type: validationConstants.RESULT_VAL_FAILURE, error }
  }
}

validationActions.GetRegisterGeolocationFile = (data) => {
  return dispatch => {
    dispatch(request())
    validationRequests.GetRegisterGeolocationFile(data)
      .then(
        regGeolocationData => dispatch(success(regGeolocationData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: validationConstants.REGISTER_GEOLOCATION_REQUEST }
  }

  function success(regGeolocationData) {
    return { type: validationConstants.REGISTER_GEOLOCATION_SUCCESS, regGeolocationData }
  }

  function failure(error) {
    return { type: validationConstants.REGISTER_GEOLOCATION_FAILURE, error }
  }
}

validationActions.GetCustomValidationFile = (data) => {
  return dispatch => {
    dispatch(request())
    validationRequests.GetCustomValidationFile(data)
      .then(
        customValFile => dispatch(success(customValFile)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: validationConstants.CUSTOMVALIDATION_FILE_REQUEST }
  }

  function success(customValFile) {
    return { type: validationConstants.CUSTOMVALIDATION_FILE_SUCCESS, customValFile }
  }

  function failure(error) {
    return { type: validationConstants.CUSTOMVALIDATION_FILE_FAILURE, error }
  }
}

validationActions.ProcessValidationRules = (data) => {
  return dispatch => {
    dispatch(request())
    validationRequests.ProcessValidationRules(data)
      .then(
        processValData => dispatch(success(processValData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: validationConstants.PROCESS_VALIDATIONRULES_REQUEST }
  }

  function success(processValData) {
    return { type: validationConstants.PROCESS_VALIDATIONRULES_SUCCESS, processValData }
  }

  function failure(error) {
    return { type: validationConstants.PROCESS_VALIDATIONRULES_FAILURE, error }
  }
}