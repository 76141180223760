import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const catalogRequest = {}

catalogRequest.GetCatalog = (data) => {
  console.log('DATA CATALOG: ', data)
  return axios.post(
    AppUrls.GetCatalog,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

let handleResponse = (response) => {
  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  return respObject;
}