import React from 'react'
import {Route} from 'react-router-dom'

function protect(Component){ 
    
const WrappedComponent = (props)=>{
    
    const token = sessionStorage.getItem('token')
    return token ? <Component {...props}/> : <Route to = {"/"}/>
} 

return WrappedComponent;
}

export default protect; 