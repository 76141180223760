import { useTheme } from "@mui/styles";
import { useStyles } from "../tabs/TabsStyle";
import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { UseImage } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import VisorPDF from "../Visor/VisorPDF.js";
import { CustomLoadingOverlay, GenericHeaderActions } from "../index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ticketDocument from "../../assets/ticketDocument.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ticketActions } from "../../store/actions/TicketsActions.js";

export const TicketSelectedView = (props) => {
  const theme = useTheme();
  const localStyles = useStyles(theme);
  const dispatch = useDispatch();

  const ticketInfo = useSelector((state) => state.TicketsBySearch);
  const ticketDocsInfo = useSelector((state) => state.TicketDocs);

  const [openDoc, setOpenDoc] = useState(false);
  const [ticket, setTicket] = useState([]);
  const [doc, setDoc] = useState(null);
  const [docName, setDocName] = useState("");

  useEffect(() => {
    const objSearch = {
      File_Id: props?.folio,
      Busqueda: "",
    };
    getTicketSearchDispatch(objSearch);
    getTickeDocsDispatch(props.TicketId);
  }, []);

  useEffect(() => {
    if (ticketInfo?.items != undefined) {
      const ticketSelected = ticketInfo?.items.find(
        (tk) => tk.Id == props.TicketId
      );
      setTicket(ticketSelected);
    }
  }, [ticketInfo]);

  useEffect(() => {
    if (ticketDocsInfo?.items != undefined) {
      console.log("ticketDocsInfo::: ", ticketDocsInfo, props.TicketId);
      const ticketSelected = ticketDocsInfo?.items.find(
        (tk) => tk.Id == props.TicketId
      );
      setDoc(ticketSelected?.Documento);
      setDocName(ticketSelected?.NombreDocumento);
    }
  }, [ticketDocsInfo]);

  const getTickeDocsDispatch = (folio) => {
    dispatch(ticketActions.GetTicketDocs(folio));
  };

  const getTicketSearchDispatch = (searchText) => {
    dispatch(ticketActions.GetTicketsBySearch(searchText));
  };

  function obtenerFechaActual(date) {
    const fecha = new Date(date);
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const año = fecha.getFullYear();
    const horas = fecha.getHours().toString().padStart(2, "0");
    const minutos = fecha.getMinutes().toString().padStart(2, "0");
    const segundos = fecha.getSeconds().toString().padStart(2, "0");

    return `${dia}/${mes}/${año} ${horas}:${minutos}:${segundos}`;
  }

  const handleOpenDoc = () => {
    setOpenDoc(true);
  };
  const handleCloseDoc = () => {
    setOpenDoc(false);
  };

  const docViewer = () => {
    const docType =
      docName.includes(".png") ||
      docName.includes(".jpg") ||
      docName.includes(".jpeg")
        ? "image"
        : docName.includes(".pdf")
        ? "pdf"
        : "pdf";

    switch (docType) {
      case "image":
        return (
          <img
            src={`data:image/jpeg;base64,${doc}`}
            alt="error al cargar imagen"
            width={"100%"}
          />
        );
      case "pdf":
        return (
          <VisorPDF
            pdfUrl={`data:application/pdf;base64,${doc}`}
            pdfName={"Document"}
            height={"100vh"}
          />
        );
      default:
        return (
          <VisorPDF
            pdfUrl={`data:application/pdf;base64,${doc}`}
            pdfName={"Document"}
            height={"100vh"}
          />
        );
    }
  };

  return (
    <div>
      {openDoc && (
        <Dialog
          fullScreen={false}
          maxWidth={"xl"}
          onClose={handleCloseDoc}
          open={openDoc}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {docName}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDoc}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Box
              sx={{
                display: "flex !important",
                flexDirection: "column !important",
                backgroundColor: "#ffffff",
                width: "100vh !important",
                borderRadius: "7px !important",
                height: "80vh",
              }}
            >
              {docViewer()}
            </Box>
          </DialogContent>
        </Dialog>
      )}

      <div className={localStyles.scroll}>
        <Stack
          display={"flex"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"start"}
          width={"100%"}
        >
          <Box
            sx={{ mb: 1, mr: 1, "&:hover": { cursor: "pointer" } }}
            onClick={props.onClickReturn}
          >
            <ArrowBackIcon />
          </Box>
          <GenericHeaderActions LabelTitle={`ID Ticket ${props?.TicketId}`} />
        </Stack>

        {ticketInfo?.loading || ticketDocsInfo?.loading ? (
          <div className={localStyles.spinSection}>
            <CustomLoadingOverlay
              active={ticketInfo?.loading || ticketDocsInfo?.loading}
              text="Espera un momento por favor..."
            />
          </div>
        ) : ticketInfo?.items ? (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}
            width={"100%"}
            sx={{ mt: 4 }}
          >
            <Box sx={{ width: "100%", px: 10 }}>
              <TableContainer
                component={Paper}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  width: "100%",
                  alignItems: "center !important",
                  border: "1px solid lightgray",
                }}
              >
                <Table
                  sx={{
                    borderCollapse: "collapse",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{ border: "1px solid lightgray", width: "50%" }}
                      >
                        Fecha y hora de alta
                      </TableCell>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        <Typography sx={{ wordBreak: "break-word" }}>
                          {ticket?.Fecha_Hora_Alta}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        Nombre de Responsable
                      </TableCell>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        <Typography sx={{ wordBreak: "break-word" }}>
                          {ticket?.Responsable}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        Observaciones
                      </TableCell>
                      <TableCell
                        sx={{ border: "1px solid lightgray", maxWidth: "50px" }}
                      >
                        <Typography sx={{ wordBreak: "break-word" }}>
                          {ticket?.Observaciones}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        Tipo de seguimiento
                      </TableCell>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        <Typography sx={{ wordBreak: "break-word" }}>
                          {ticket?.TipoSeguimiento}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        Comentarios HSBC
                      </TableCell>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        <Typography sx={{ wordBreak: "break-word" }}>
                          {ticket?.Comentarios}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        Clasificación de movimiento
                      </TableCell>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        <Typography sx={{ wordBreak: "break-word" }}>
                          {ticket?.ClasificacionMovimiento}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        Fecha estimada de solución
                      </TableCell>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        <Typography sx={{ wordBreak: "break-word" }}>
                          {obtenerFechaActual(ticket?.FechaEstimadaSolucion)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        Tipo de solicitud
                      </TableCell>
                      <TableCell sx={{ border: "1px solid lightgray" }}>
                        <Typography sx={{ wordBreak: "break-word" }}>
                          {ticket?.TipoSolicitud}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            {!!doc ? (
              <>
                <Stack
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                  direction={"column"}
                  width={"90%"}
                  sx={{ mt: 6 }}
                >
                  <Box sx={{ borderBottom: "1px solid red", width: "100%" }}>
                    <h4>Documentos</h4>
                  </Box>
                  <Stack
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    direction={"row"}
                    width={"100%"}
                    sx={{ mt: 1 }}
                  >
                    <Box sx={{ width: "50%", my: 2, height: "100px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "flex-start",
                          alignItems: "flex-start",
                          pb: 3,
                        }}
                      >
                        <Box
                          onClick={handleOpenDoc}
                          sx={{
                            "&:hover": { cursor: "pointer" },
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #D2D2D2",
                            borderRadius: "7px",
                            px: 5,
                            py: 3,
                            maxWidth: "180px",
                            boxShadow: 5,
                            position: "relative",
                            userSelect: "none",
                          }}
                        >
                          <UseImage
                            src={ticketDocument}
                            type="img"
                            style={{ width: "80px" }}
                          />
                          <Box height={"50px"} width={"100px"}>
                            <Typography
                              height={"50px"}
                              width={"100px"}
                              sx={{
                                fontSize: "12px !important",
                                pt: 2,
                                // overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {docName}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Stack>
                </Stack>
              </>
            ) : null}
          </Stack>
        ) : null}
      </div>
    </div>
  );
};
