import React, { useState } from 'react';

export const UseImage = props => {
  return (<img src={props.src} onClick={props.onClick} className={props.className} {...props} />)

  // const { useEffect } = React//Hook para el state  
  // const [isMovil, setIsMovil] = useState(false)//State para mostrar u ocultar contraseña

  // useEffect(() => {
  //   //Ancho de los botones
  //   if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) {
  //     //Sí es móvil
  //     setIsMovil(true)
  //   } else {
  //     //Sí es desktop
  //     setIsMovil(false)
  //   }
  // })

  // // console.log("case IMAGE name: ", props.src)
  // let images = isMovil ? require.context('../assets/movil', true, /\.(gif|png|jpe?g|svg)$/) : require.context('../assets', true, /\.(gif|png|jpe?g|svg)$/)
  // // console.log("Images--- ", images)
  // let loadImage = (imageName) => (images(`./${imageName}`).default)
  // // console.log("case IMAGE name loadImage(props.src): ", loadImage)
  // return (<img {...props} src={loadImage(props.src)} onClick={props.onClick} />)
}