import { validationConstants } from '../constants/index.js'

export const FileValidation = (state = {}, action) => {
    switch (action.type) {
        case validationConstants.VALIDATION_FILE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case validationConstants.VALIDATION_FILE_SUCCESS:
            return {
                loading: false,
                items: action.fileValidation.Body,
                error: undefined
            }
            //break;
        case validationConstants.VALIDATION_FILE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}

export const DocumentValidation = (state = {}, action) => {
    switch (action.type) {
        case validationConstants.VALIDATION_DOCUMENT_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case validationConstants.VALIDATION_DOCUMENT_SUCCESS:
            return {
                loading: false,
                items: action.documentValidation.Body,
                error: undefined
            }
            //break;
        case validationConstants.VALIDATION_DOCUMENT_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}


export const catpchaValidation = (state = {}, action) => {
    switch (action.type) {
        case validationConstants.VALIDATION_CATPCHA_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case validationConstants.VALIDATION_CATPCHA_SUCCESS:
            return {
                loading: false,
                items: action.catpcha.Body,
                error: undefined
            }
            //break;
        case validationConstants.VALIDATION_CATPCHA_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}

export const PinValidation = (state = {}, action) => {
    switch (action.type) {
        case validationConstants.VALIDATION_PIN_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case validationConstants.VALIDATION_PIN_SUCCESS:
            return {
                loading: false,
                items: action.pinRes.Body,
                error: undefined
            }
            //break;
        case validationConstants.VALIDATION_PIN_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}

export const DocExternalVal = (state = {}, action) => {
    switch (action.type) {
        case validationConstants.DOCEXTERNAL_VAL_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case validationConstants.DOCEXTERNAL_VAL_SUCCESS:
            return {
                loading: false,
                items: action.docExtVal.Body,
                error: undefined
            }
            //break;
        case validationConstants.DOCEXTERNAL_VAL_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}

export const ResultadosVal = (state = {}, action) => {
    switch (action.type) {
        case validationConstants.RESULT_VAL_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case validationConstants.RESULT_VAL_SUCCESS:
            return {
                loading: false,
                items: action.resultVal.Body,
                error: undefined
            }
            //break;
        case validationConstants.RESULT_VAL_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}

export const RegisGeolocationData = (state = {}, action) => {
    switch (action.type) {
        case validationConstants.REGISTER_GEOLOCATION_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case validationConstants.REGISTER_GEOLOCATION_SUCCESS:
            return {
                loading: false,
                items: action.regGeolocationData.Body,
                error: undefined
            }
            //break;
        case validationConstants.REGISTER_GEOLOCATION_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}

export const CustomValidationFile = (state = {}, action) => {
    switch (action.type) {
        case validationConstants.CUSTOMVALIDATION_FILE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case validationConstants.CUSTOMVALIDATION_FILE_SUCCESS:
            return {
                loading: false,
                items: action.customValFile.Body,
                error: undefined
            }
            //break;
        case validationConstants.CUSTOMVALIDATION_FILE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}

export const ProcessValRulesData = (state = {}, action) => {
    switch (action.type) {
        case validationConstants.PROCESS_VALIDATIONRULES_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case validationConstants.PROCESS_VALIDATIONRULES_SUCCESS:
            return {
                loading: false,
                items: action.processValData.Body,
                error: undefined
            }
            //break;
        case validationConstants.PROCESS_VALIDATIONRULES_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}