import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
    bodyHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '98%',
        backgroundColor: '#ffffff',
        height: '60px'
    },

    sectioUser: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },

    generalSection: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'

    },

    userIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: theme.palette.iconNamePrincipal.main,
        color: theme.palette.iconNamePrincipal.text,
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(2),
        width: 40,
        height: 40,
        borderRadius: '50%',
    },

    capital: {
        marginTop: '5px',
        padding: 0,
        fontSize: 20,
        fontWeight: 'bold',
        color: theme.palette.iconNamePrincipal.text,
    },

    h6: {
        fontWeight: 'bold !important',
        marginLeft: theme.spacing(2),
    },

    p: {
        fontWeight: 'bold !important',
        fontSize: 16,
        // marginRight: theme.spacing(2),
        marginBottom: 'none',
        paddingBottom: 'none'
    },

    text: {
        fontWeight: 'bolder !important',
        fontSize: 12,
        textAlign: 'right'
    },

    link: {
        color: `${theme.palette.linkButton.main} !important`,
        '&:hover': {
            color: `${theme.palette.primary.main} !important`
        }
    },

    headerName: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: theme.spacing(2),
    }
}))