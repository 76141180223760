import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'//React route//GifLoader
import { Typography, Grid, Hidden, Box, useTheme, Button } from '@mui/material'//Se importan elementos de la librería material-ui
import LoadingOverlay from 'react-loading-overlay'
import { userActions, validationActions, userManagerActions } from '../../store/actions'//Acciones para redux
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from '@mui/styles'
import ImageLogin from '../../assets/imageLogin.jpg'
import ReCAPTCHA from "react-google-recaptcha";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Logout } from '../../utils/tools'
import {
    CustomLoadingOverlay,
    FormDynamic,
    Alert,
    UseImage,
    InputField,
} from '../../components'

const useStyles = makeStyles((theme) => ({
    //Estílos solamente del componente "LoginComponent"
    containerImagen: {
        height: "100vh",

    },
    imagenIlustrativa: {
        height: "100%",
        width: "100%",
        backgroundRepeat: 'none',
        backgroundSize: '100%',
        objectFit: 'cover',
    },
    imagenLogin: {
        height: "15vh",
        // width: "100%",
        backgroundRepeat: 'none',
        backgroundSize: '100%',
        objectFit: 'cover',
    },
    formInput: {
        width: "100%",
        padding: 1,
    },
    boxReponsive: {
        width: "50%",
        [theme.breakpoints.down("sm")]: {
            width: "95%",
        },
    },
    containerForm: {
        width: "65%",
        [theme.breakpoints.down("sm")]: {
            width: "95%",
        },
    },
    loader: {
        position: 'absolute !important',
        top: '0 !important',
        bottom: '0 !important',
        left: '0 !important',
        right: '0 !important',
        height: '100vh !important',
        width: '100% !important',
    },
}));




export default function RecoverPasswordComponentLego(props) {
    const location = useLocation()
    const formMethods = useForm({ mode: "onChange" });
    const { control, handleSubmit, register, formState, setValue } = useForm({ mode: "onBlur" });
    // const localStyles = useStyles()
    const [loadView, setLoadView] = useState(false)//Se muestra/oculta el gif loader
    const navigate = useNavigate()//Módulo "History" de react router
    const theme = useTheme()
    const classes = useStyles(theme)

    //Estados de componente
    const [requestCaptcha, setRequestCaptcha] = useState(false)
    const [idRef, setIdRef] = useState('')
    const [captchaSucess, setCaptchaSucess] = useState(true)
    const [currentUser, setCurrentUser] = useState()

    //Redux hooks
    const auth = useSelector(state => state.AuthenticationReferer)
    const changePassword = useSelector(state => state.RecoverChangePassword)
    const catpcha = useSelector(state => state.catpchaValidation)
    const usersMC = useSelector((state) => state.GetUsersMC);
    const dispatch = useDispatch()


    useEffect(() => {
        console.log('auth.items::::: ', auth.items)
        if (auth.items != undefined) {
            setValue('txtEmail', auth?.items.UserLoginData.Email)
            setValue('txtName', `${auth?.items.UserLoginData.Name ?? ''} ${auth?.items.UserLoginData.FatherLastName ?? ''} ${auth?.items.UserLoginData.MotherLastName ?? ''}`)
        }
    }, [auth]);

    useEffect(() => {
        dispatch(userManagerActions.GetUsersMC({ SearchText: '' }));
    }, [])

    useEffect(() => {
        if (usersMC?.items !== undefined) {
            const userLogged = usersMC.items.find(user => user.Id == auth?.items?.UserLoginData?.Id)
            setCurrentUser(userLogged)
        }
    }, [usersMC])
    console.log('changePassword:::', changePassword)
    useEffect(() => {
        //Validar que la información haya sido recibida correctamente por el API
        console.log('Va a validar el pass', changePassword)
        setLoadView(changePassword?.loading);
        if (changePassword?.error != undefined) {
            setLoadView(false)
            Alert({
                icon: 0,
                title: changePassword.error,
            },
                () => {
                    window.grecaptcha.reset(); setIdRef('')
                });
        } else {
            if (changePassword?.items != undefined) {
                navigate('/inbox/dashboards')
                // dispatch(workFlowActions.ContinueWorkFlow(dataRequest))
            }
        }
    }, [changePassword]);

    useEffect(() => {
        setLoadView(catpcha.loading)
        if (catpcha.items !== undefined && requestCaptcha) {
            setCaptchaSucess(catpcha.items.Success)
            setIdRef(catpcha.items?.ReferId)
        } else {
            setIdRef('')
        }
    }, [catpcha])


    function catpchaChange(event) {
        console.log("captcha: ", event)
        dispatch(validationActions.GetCatpcha(event))
        setRequestCaptcha(true)
    }

    let submitChangePassword = (formData) => {//Login
        console.log('formData password:', formData)
        if (formData['txtPassword'] != formData['txtPasswordRepeat']) {
            Alert({
                icon: "error",
                text: "Las contraseñas no coinciden",
            });
            return null;
        }
        if (formData['authorizationCheck'] != true) {
            Alert({
                icon: "error",
                text: "Debes aceptar el Aviso de Privacidad para continuar",
            });
            return null;
        }

        console.log('currentUser:::', currentUser)

        /* const ListMetadata = [
            {
                "GroupName": "Usuario",
                "Name": "Producto",
                "Value": "Keyman"
            },
            {
                "GroupName": "Usuario",
                "Name": "Rol",
                "Value": "Mesa de control"
            },
            {
                "GroupName": "Usuario",
                "Name": "Usuario",
                "Value": "ALBERTOMC1"
            },
            {
                "GroupName": "Usuario",
                "Name": "Nombre",
                "Value": "ALBERTO"
            },
            {
                "GroupName": "Usuario",
                "Name": "Segundo Nombre",
                "Value": ""
            },
            {
                "GroupName": "Usuario",
                "Name": "Apellido Paterno",
                "Value": "NIETO"
            },
            {
                "GroupName": "Usuario",
                "Name": "Apellido Materno",
                "Value": ""
            },
            {
                "GroupName": "Usuario",
                "Name": "Correo",
                "Value": "alberto.nieto@docsolutions.com"
            },
            {
                "GroupName": "Usuario",
                "Name": "Teléfono",
                "Value": "521232131231"
            },
            {
                "GroupName": "Usuario",
                "Name": "IP Pública",
                "Value": "187.168.0.10"
            },
            {
                "GroupName": "Usuario",
                "Name": "IP Pública",
                "Value": "187.168.0.10"
            }
        ] */


        let Request = {
            Id: currentUser?.Id,
            UserName: currentUser.UserName,
            Password: formData['txtPassword'],
            Name: currentUser.Name,
            SecondName: currentUser.SecondName,
            FatherLastName: currentUser.FatherLastName,
            MotherLastName: currentUser.MotherLastName,
            Email: currentUser.Email,
            PhoneNumber: currentUser.PhoneNumber,
            IpPublica: currentUser.usersIps[0]?.Ip ?? '',
            Active: true,
            Metadata: [],
            idProducto: currentUser.idProducto[0]?.ID ?? '',
            idRole: currentUser.idRole[0]?.Id ?? ''
        }

        console.log('REQUEST TO SEND::: ', Request)

        dispatch(userActions.RecoverChangePassword(Request))
    }

    const handleLogout = () => {
        Logout();
        navigate('/auth/login')
    }

    //Comienza interfaz
    return (
        <FormProvider {...formMethods} theme={theme}>
            {loadView && <LoadingOverlay
                active={loadView}
                spinner
                text='Espera un momento por favor...'
                className={classes.loader}>
            </LoadingOverlay>}
            <Grid container>
                <Grid item lg={6} md={6} style={{ height: '100vh', overflow: "auto" }} container justifyContent={"center"} alignItems={'center'} >
                    <Grid onClick={handleLogout} sx={{ height: '100%', pt: 5 }} display={'flex'} alignItems={'start'} justifyContent={'start'}><ArrowBackIcon fontSize='large' /></Grid>
                    <Box className={classes.containerForm}>
                        <Typography variant="h4"
                            gutterBottom >Actualización de datos del usuario</Typography>
                        <Typography variant="subtitle1">
                            Lee nuestro <Typography color={'red'} display={'inline'}>Aviso de Privacidad</Typography> y <Typography color={'red'} display={'inline'}>Proteccion de datos</Typography>
                        </Typography>
                        {/* <Typography variant="subtitle1"
                            gutterBottom >
                            Especifique entre 8 y 20 caracteres, que no contenga espacios, al menos una letra mayúscula y letras minúsculas, al menos un número y mínimo un caracter especial
                        </Typography> */}

                        <Grid>
                            <InputField
                                inputType="text"
                                control={control}
                                register={register}
                                name='txtEmail'
                                label='Correo electrónico'
                                validations={{ required: true }}
                                required={true}
                                error={!!formState.errors['txtEmail']}
                                helperText={formState.errors['txtEmail']?.message}
                                disabled={true}
                            />
                            <InputField
                                inputType="text"
                                control={control}
                                register={register}
                                name='txtName'
                                label='Nombre (s)'
                                validations={{ required: true }}
                                required={true}
                                error={!!formState.errors['txtName']}
                                helperText={formState.errors['txtName']?.message}
                                disabled={true}
                            />
                            <InputField
                                inputType="password"
                                isPassword={true}
                                control={control}
                                register={register}
                                name='txtPassword'
                                label='Contraseña'
                                validations={{ required: true }}
                                required={true}
                                error={!!formState.errors['txtPassword']}
                                helperText={formState.errors['txtPassword']?.message}
                            />
                            <InputField
                                inputType="password"
                                isPassword={true}
                                control={control}
                                register={register}
                                name='txtPasswordRepeat'
                                label='Confirma tu contraseña'
                                validations={{ required: true }}
                                required={true}
                                error={!!formState.errors['txtPasswordRepeat']}
                                helperText={formState.errors['txtPasswordRepeat']?.message}
                            />
                            <InputField
                                inputType="check"
                                control={control}
                                register={register}
                                name='authorizationCheck'
                                label={'*He leído y acepto el Aviso de privacidad y protección de datos de HSBC'}
                                radioValues={[{ Option: 'si', Value: true }]}
                                validations={{ required: true }}
                                error={!!formState.errors['authorizationCheck']}
                                helperText={formState.errors['authorizationCheck']?.message}
                            />

                            <Box sx={{ marginTop: '1em', width: "100%" }}>
                                <ReCAPTCHA className="iframeCaptcha"
                                    sitekey="6LeSrKEaAAAAAGnBnM94eRtpu1Z7PwnY3WpOyDvf"
                                    tabindex={100}
                                    onChange={catpchaChange} />
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'center', alignContent: "center", textAlign: "center", marginTop: '1em' }}>
                                <Box style={{ width: '70%' }}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{ height: 45, px: 10 }}
                                        type={captchaSucess ? 'submit' : 'button'}
                                        onClick={handleSubmit(submitChangePassword)}
                                        disabled={idRef == ''}
                                    >
                                        Ingresar
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Box>

                    {loadView ? <CustomLoadingOverlay active={loadView} text="Espera un momento por favor..." /> : null}
                    {/* <CustomAlertDialog
                        open={showAlertMaterial}
                        // closeAction={() => handleAlerDialog('left')}
                        title={'Cancelar'}
                        textDescription={'Al rechazar, deberás comenzar el proceso para una nueva solicitud. ¿Estas seguro de rechazar?'}
                        textAcepted="Aceptar"
                        textCancel="Cancelar"
                        aceptedAction={() => handleAlerDialog('left')}
                        cancelAction={() => handleAlerDialog('left')}
                    /> */}
                </Grid>
                <Hidden smDown>
                    <Grid item lg={6} md={6}>
                        <Box className={classes.containerImagen}>
                            <UseImage src={ImageLogin} type='img' className={classes.imagenIlustrativa} />
                        </Box>
                    </Grid>
                </Hidden>
            </Grid >
        </FormProvider >
    )
}
