import { TextField as TextF, InputAdornment, Button, Grid, Typography, styled } from "@mui/material";
import { Icon } from '@iconify/react'
import { useState } from "react";

const TextField = styled(TextF)(({ theme }) => ({
    height: 50,
    paddingTop: 3,
    padding: 0,
    border: '1px solid #DADADA',
    width: '100% !important',
}));

export const GenericHeaderActions = (props) => {

    const [search, setSearch] = useState(props.textSearch ?? '')
    return (
        <Grid container sx={{ margin: '0 .8em .5em 0em', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item sx={{ paddingTop: "0px !important", paddingLeft: "0px !important", width: '50%' }} >
                {props.onChangeTextField != undefined ?
                    <TextField
                        sx={{
                            pt: 1,
                            width: '100%',
                            '&::placeholder': {
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            },
                        }}
                        variant='standard'
                        margin='none'
                        size="normal"
                        value={search}
                        placeholder={props.placeholderTextField}
                        onChange={(e) => { props.onChangeTextField(e); setSearch(e.target.value) }}
                        InputProps={
                            {
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position='start' sx={(theme) => ({ marginLeft: theme.spacing(1) })} >
                                        <Icon fontSize={18} icon="akar-icons:search" color={'#9E9E9E'} />
                                        {/* <FolderIcon className={localStyles.iconMaterial} /> */}
                                    </InputAdornment>
                                )
                            }
                        }
                    />
                    : null}
                {props.LabelTitle != undefined ?
                    <Typography variant="h6" >{props.LabelTitle} {props.toolTip}</Typography>
                    : null}
            </Grid>
            <Grid display={'flex'} flexDirection={'row'} alignItems={'center'}>
                {props.onClickConfirm != undefined ?
                    <Grid item /* lg={2.8} xs={12}  */ sx={{ paddingTop: "0px !important", paddingLeft: "6px !important" }} >
                        {props.CustomButton ? <>{props.CustomButton}</>
                            : <Button
                                variant={props.ConfirmButtonVariant ?? "contained"}
                                sx={{
                                    height: 50, minWidth: 220, maxWidth: 228, m: 1, textTransform: 'none !important',
                                    ...(props.ConfirmButtonVariant == 'outlined' && { color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' } })
                                }}
                                onClick={props.onClickConfirm}
                                startIcon={props.ConfirmButtonStartIcon}
                                disabled={props.ConfirmButtonDisabled}
                                endIcon={props.ConfirmButtonEndIcon}
                                type="submit" >
                                {props.ConfirmButtonText == undefined ? "Buscar" : props.ConfirmButtonText}
                            </Button>}
                    </Grid>
                    : null}
                {props.onClickCancel != undefined ?
                    <Grid item/*  lg={2.8} xs={12}  */ sx={{ paddingTop: "0px !important", paddingLeft: "6px !important" }} >
                        <Button type="submit"
                            disabled={props.CancelButtonDisabled}
                            variant={props.CancelButtonVariant ?? "outlined"}
                            sx={{
                                height: 50, minWidth: 220, maxWidth: 228, m: 1, textTransform: 'none !important',
                                ...(props.ConfirmButtonVariant != 'outlined' && { color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' } })
                            }}
                            onClick={() => { props.onClickCancel(); props.onChangeTextField && setSearch('') }} >
                            {props.CancelButtonText == undefined ? "Limpiar búsqueda" : props.CancelButtonText}
                        </Button>
                    </Grid>
                    : null}
            </Grid>
        </Grid>)
};         
