import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha, ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

// function createData(id, name, calories, fat, carbs, protein) {
//     return {
//         id,
//         name,
//         calories,
//         fat,
//         carbs,
//         protein,
//     };
// }

// const rows = [
//     createData(1, 'Cupcake', 305, 3.7, 67, 4.3),
//     createData(2, 'Donut', 452, 25.0, 51, 4.9),
//     createData(3, 'Eclair', 262, 16.0, 24, 6.0),
//     createData(4, 'Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData(5, 'Gingerbread', 356, 16.0, 49, 3.9),
//     createData(6, 'Honeycomb', 408, 3.2, 87, 6.5),
//     createData(7, 'Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData(8, 'Jelly Bean', 375, 0.0, 94, 0.0),
//     createData(9, 'KitKat', 518, 26.0, 65, 7.0),
//     createData(10, 'Lollipop', 392, 0.2, 98, 0.0),
//     createData(11, 'Marshmallow', 318, 0, 81, 2.0),
//     createData(12, 'Nougat', 360, 19.0, 9, 37.0),
//     createData(13, 'Oreo', 437, 18.0, 63, 4.0),
// ];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'folio',
        numeric: false,
        disablePadding: true,
        label: 'Folio',
    },
    {
        id: 'nombre',
        numeric: true,
        disablePadding: false,
        label: 'Nombre',
    },
    {
        id: 'curp',
        numeric: true,
        disablePadding: false,
        label: 'CURP',
    },
    {
        id: 'poliza',
        numeric: true,
        disablePadding: false,
        label: 'Número de Póliza',
    },
    {
        id: 'producto',
        numeric: true,
        disablePadding: false,
        label: 'Producto',
    },
    {
        id: 'cobertura',
        numeric: true,
        disablePadding: false,
        label: 'Cobertura Reclamada',
    },
    {
        id: 'dias',
        numeric: true,
        disablePadding: false,
        label: 'Dias',
    },
    {
        id: 'estatus',
        numeric: true,
        disablePadding: false,
        label: 'Estatus',
    },
];

const theme = createTheme({
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    '&.Mui-unchecked': {
                        color: 'red',
                    },
                    '&.Mui-checked': {
                        color: 'red',
                    },
                    '&.Mui-indeterminate': {
                        color: 'red !important',
                    },
                },
                indeterminate: {
                    color: '##fff', // Color for the indeterminate state
                },
            },
        },
    },
});

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" sx={{ backgroundColor: '#243746!important', color: '#fff' }}>
                    <ThemeProvider theme={theme}>
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        /* inputProps={{
                            'aria-label': 'select all desserts',
                        }} */
                        />
                    </ThemeProvider>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell sx={{ backgroundColor: '#243746!important', color: '#fff' }}
                        key={headCell.id}
                        align={/* headCell.numeric ? 'right' :  */'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >{headCell.label}
                        {/* <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel> */}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


export const TableNotifications = (props) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    console.log('props data: ', props.data)

    const newData = props.data?.map((col) => {
        return {
            Id: col.Id,
            Folio: col.Folio,
            Nombre: col.FullName,
            Curp: col?.Metadata?.find((dato) => dato.Name == "CURP")?.Value,
            NumeroPoliza: col?.Metadata?.find((dato) => dato.Name == "NumeroPoliza")?.Value,
            Producto: col?.Metadata?.find((dato) => dato.Name == "Producto")?.Value,
            CoberturaReclamada: col?.Metadata?.find((dato) => dato.Name == "CoberturaReclamada")?.Value,
            Dias: col?.Metadata?.find((dato) => dato.Name == "Dias")?.Value,
            Estatus: col?.Metadata?.find((dato) => dato.Name == "Estatus")?.Value
        }
    })

    const createData = (id, folio, nombre, curp, numPoliza, producto, cobeReclamada, dias, status) => {
        return { id, folio, nombre, curp, numPoliza, producto, cobeReclamada, dias, status }
    }

    const rows = newData?.map((r, index) => {
        return createData(r.Id, r.Folio, r.Nombre, r.Curp, r.NumeroPoliza, r.Producto, r.CoberturaReclamada, r.Dias, r.Estatus)
    })

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        /* newSelected.length === 0 &&  */props.onSelect(newSelected)
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // setRowsPerPage(parseInt(event.target.value, 10));
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%', height: '60vh' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer sx={{ overflow: 'auto', height: '60vh' }}>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const isAllowedToSelect = !(props.data?.[index]?.Status?.includes("Cancelado") || row.dias == '0' || row.status == 'Notificación Ultimátum')
                                    console.log('isAllowedToSelect::: ', isAllowedToSelect)
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => { props.handleSelected(row.id) }}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell padding="checkbox">
                                                {isAllowedToSelect ? <Checkbox
                                                    color="primary"
                                                    onClick={(event) => { event.stopPropagation(); handleClick(event, row.id) }}
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                /> : <></>}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.folio}
                                            </TableCell>
                                            <TableCell align="left">{row.nombre}</TableCell>
                                            <TableCell align="left">{row.curp}</TableCell>
                                            <TableCell align="left">{row.numPoliza}</TableCell>
                                            <TableCell align="left">{row.producto}</TableCell>
                                            <TableCell align="left">{row.cobeReclamada}</TableCell>
                                            <TableCell align="left">{row.dias}</TableCell>
                                            <TableCell align="left">{row.status}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {/* {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <TablePagination 
                    // className={localStyle.tablePaginacion}
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={rows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    onPageChange={handleChangePage}
                /> */}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}