import React, { useEffect, useState } from 'react'
import Webcam from 'react-webcam';
import { mobileModel } from "react-device-detect"
import {
  Typography, Box, Select, MenuItem,useTheme
} from "@mui/material";
//import { theme } from "../../styles/Main.js";

import { B64toBlob } from '../../utils'

export const CustomNativeCamera = (props) => {
  const theme = useTheme()
  const webcamRef = React.useRef(undefined);
  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);
  const [controlCameraCustom, setControlCameraCustom] = useState(null)
  const [deviceIdDefault, setDeviceIdDefault] = React.useState(null);
  const selectDeviceCurrent = React.createRef()

  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  const capture = React.useCallback(() => {

    let base64 = (navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/Android/i)) ? webcamRef.current.getScreenshot({ width: 960, height: 1280 })
      : webcamRef.current.getScreenshot();
    if (base64 !== null && base64 !== undefined) {
      base64 = base64.split(',')
      const blob = B64toBlob(base64[1], 'image/jpeg');
      props.onCapture(blob)
    }
  }, [webcamRef]);

  useEffect(() => {
    try {
      setDeviceId({})
      getDevices().then(gotDevices);
    } catch (ex) {
      console.log("Constructor Error: ", ex)
    }
  }, []);


  useEffect(() => {
    if (props.CaptureInit == true) {
      capture()
    }
  }, [props.CaptureInit])

  useEffect(() => {
    if (devices?.length > 0) {
      try {
        let diviceFindSelect = null;
        // console.log("devices?.length > 0 && mobileModel***: ", devices?.length, mobileModel)
        if (props.FacingMode === "environment" && devices.length > 1 && mobileModel.trimEnd() === 'moto g(8) plus') {
          diviceFindSelect = devices?.find((item) => item.label === "camera2 0, facing back" ||
            item.label === "camera2 0, mirando hacia atrás")
          console.log("diviceFindSelect*********: ", diviceFindSelect)
          if (diviceFindSelect !== undefined && diviceFindSelect !== null && diviceFindSelect?.label != "") {
            console.log("idCurrent:, mobileModel: ", diviceFindSelect.deviceId, mobileModel)
            setDeviceId(diviceFindSelect.deviceId)
            setDeviceIdDefault(`I S Id: ${diviceFindSelect.deviceId}`)
            setControlCameraCustom(null)
          } else {
            console.log("*****************mobileModel*****devices***********: ", mobileModel, devices[devices.length - 1].deviceId)
            setDeviceId(devices[devices.length - 1].deviceId)
            setDeviceIdDefault(`E S Id: ${devices[devices.length - 1].deviceId}`)
            setControlCameraCustom(null)
          }
        }
        else if (props.FacingMode === "environment" && devices?.length > 1 && mobileModel === "moto e20") {
          diviceFindSelect = devices?.find((item) => item.label === "camera2 0, facing back" ||
            item.label === "camera2 0, mirando hacia atrás")
          console.log("diviceFindSelect*********: ", diviceFindSelect)
          if (diviceFindSelect !== undefined && diviceFindSelect !== null && diviceFindSelect?.label != "") {
            console.log("idCurrent:, mobileModel: ", diviceFindSelect.deviceId, mobileModel)
            setDeviceId(diviceFindSelect.deviceId)
            setDeviceIdDefault(`IS_Id: ${diviceFindSelect.deviceId}`)
            setControlCameraCustom(null)
          } else {
            console.log("*****************mobileModel*****devices***********: ", mobileModel, devices[devices.length - 1].deviceId)
            setDeviceId(devices[devices.length - 1].deviceId)
            setDeviceIdDefault(`ES_Id: ${devices[devices.length - 1].deviceId}`)
            setControlCameraCustom(null)
          }
        }
        else if (props.FacingMode === "environment" && devices?.length > 1 && mobileModel === "YAL-L21") {
          diviceFindSelect = devices?.find((item) => item.label === "camera2 0, facing back" ||
            item.label === "camera2 0, mirando hacia atrás")
          console.log("diviceFindSelect*********: ", diviceFindSelect)
          if (diviceFindSelect !== undefined && diviceFindSelect !== null && diviceFindSelect?.label != "") {
            console.log("idCurrent:, mobileModel: ", diviceFindSelect.deviceId, mobileModel)
            setDeviceId(diviceFindSelect.deviceId)
            setDeviceIdDefault(`IS_Id: ${diviceFindSelect.deviceId}`)
            setControlCameraCustom(null)
          } else {
            console.log("*****************mobileModel*****devices***********: ", mobileModel, devices[devices.length - 1].deviceId)
            setDeviceId(devices[devices.length - 1].deviceId)
            setDeviceIdDefault(`ES_Id: ${devices[devices.length - 1].deviceId}`)
            setControlCameraCustom(null)
          }
        }
        else if (props.FacingMode === "environment" && devices?.length > 1 && mobileModel === "SM-N975U") {
          diviceFindSelect = devices?.find((item) => item.label === "camera2 0, facing back" ||
            item.label === "camera2 0, mirando hacia atrás")
          console.log("diviceFindSelect*********: ", diviceFindSelect)
          if (diviceFindSelect !== undefined && diviceFindSelect !== null && diviceFindSelect?.label != "") {
            console.log("idCurrent:, mobileModel: ", diviceFindSelect.deviceId, mobileModel)
            setDeviceId(diviceFindSelect.deviceId)
            setDeviceIdDefault(`IS_Id:${diviceFindSelect.deviceId}:****`)
            setControlCameraCustom(null)
          } else {
            console.log("*****************mobileModel*****devices***********: ", mobileModel, devices[devices.length - 1].deviceId)
            setDeviceId(devices[devices.length - 1].deviceId)
            setDeviceIdDefault(`ES_Id:${devices[devices.length - 1].deviceId}:****`)
            setControlCameraCustom(null)
          }
        }
        else if (props.FacingMode === "environment" && devices?.length > 1 && mobileModel === "SM-A336M") {
          diviceFindSelect = devices?.find((item) => item.label === "camera2 0, facing back" ||
            item.label === "camera2 0, mirando hacia atrás")
          console.log("diviceFindSelect*********: ", diviceFindSelect)
          if (diviceFindSelect !== undefined && diviceFindSelect !== null && diviceFindSelect?.label != "") {
            console.log("idCurrent:, mobileModel: ", diviceFindSelect.deviceId, mobileModel)
            setDeviceId(diviceFindSelect.deviceId)
            setDeviceIdDefault(`IS_Id:${diviceFindSelect.deviceId}:****`)
            setControlCameraCustom(null)
          } else {
            console.log("*****************mobileModel*****devices***********: ", mobileModel, devices[devices.length - 1].deviceId)
            setDeviceId(devices[devices.length - 1].deviceId)
            setDeviceIdDefault(`ES_Id:${devices[devices.length - 1].deviceId}:****`)
            setControlCameraCustom(null)
          }
        }
        else if (props.FacingMode === "environment" && devices?.length > 1 && mobileModel === "SM-G991B") {
          diviceFindSelect = devices?.find((item) => item.label === "camera2 0, facing back" ||
            item.label === "camera2 0, mirando hacia atrás")
          console.log("diviceFindSelect*********: ", diviceFindSelect)
          if (diviceFindSelect !== undefined && diviceFindSelect !== null && diviceFindSelect?.label != "") {
            console.log("idCurrent:, mobileModel: ", diviceFindSelect.deviceId, mobileModel)
            setDeviceId(diviceFindSelect.deviceId)
            setDeviceIdDefault(`IS_Id:${diviceFindSelect.deviceId}:****`)
            setControlCameraCustom(null)
          } else {
            console.log("*****************mobileModel*****devices***********: ", mobileModel, devices[devices.length - 1].deviceId)
            setDeviceId(devices[devices.length - 1].deviceId)
            setDeviceIdDefault(`ES_Id:${devices[devices.length - 1].deviceId}:****`)
            setControlCameraCustom(null)
          }
        }
        // else if (props.FacingMode === "environment" && devices?.length > 1 && mobileModel === "SM-G950F") {
        //    diviceFindSelect = devices?.find((item) => item.label === "camera2 0, facing back" ||
        //     item.label === "camera2 0, mirando hacia atrás")
        //   console.log("diviceFindSelect*********: ", diviceFindSelect)
        //   if (diviceFindSelect !== undefined && diviceFindSelect !== null && diviceFindSelect?.label != "") {
        //     console.log("idCurrent:, mobileModel: ", diviceFindSelect.deviceId, mobileModel)
        //     setDeviceId(diviceFindSelect.deviceId)
        //     setDeviceIdDefault(`IS_Id:${diviceFindSelect.deviceId}:****`)
        //     setControlCameraCustom(null)
        //   } else {
        //     console.log("*****************mobileModel*****devices***********: ", mobileModel, devices[devices.length - 1].deviceId)
        //     setDeviceId(devices[devices.length - 1].deviceId)
        //     setDeviceIdDefault(`ES_Id:${devices[devices.length - 1].deviceId}:****`)
        //     setControlCameraCustom(null)
        //   }
        // }
        else if (devices?.length > 2) {
          setDeviceId(null)
        } else {
          setDeviceId(null)
        }
      } catch (ex) {
        setDeviceId(null)
      }
    }
  }, [devices, mobileModel])

  useEffect(() => {
    console.log("******deviceId selected********: ", deviceId, " - controlCameraCustom: ", controlCameraCustom)
    if (deviceId !== undefined && deviceId !== null && deviceId?.length > 0) {
      console.log(" deviceId entro el ID selecccionado: ", deviceId)
      setControlCameraCustom(<Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        screenshotQuality={1}
        // imageSmoothing={true}
        style={props.isDisplay === false ? { display: 'none' } : { display: 'block' }}
        videoConstraints={{ deviceId: deviceId, echoCancellation: true, noiseSuppression: true }}
        mirrored={
          props.Mirrored === undefined || props.Mirrored === ""
            || props.Mirrored === null ? navigator.userAgent.match(/iPhone/i) ||
              navigator.userAgent.match(/Android/i) ? true : false
            : props.Mirrored
        }
      />)
    }
  }, [deviceId])

  let getDevices = () => {
    return navigator.mediaDevices.enumerateDevices();
  }

  let gotDevices = (deviceInfos) => {
    window.deviceInfos = deviceInfos;
    if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)) {
      console.log("camerasBack****ALL FILTER**: ")
      setDevices(deviceInfos.filter(({ kind }) => kind === "videoinput"))
    } else {
      console.log("camerasBack****ALL SIN SIN FILTER**: ", deviceInfos)
      setDevices(deviceInfos)
    }
  }

  return (<>
    {props.FacingMode === "environment" && devices.length > 1 ?
      <Box sx={{
        // display: 'flex',
        display: 'none',
        // flexDirection: 'row',
        width: '100%',
        justifyContent: "center",
        flexDirection: 'column',
        alignItems: "flex-start",
        position: 'fixed',
        top: 0,
        backgroundColor: "#FFF",
        zIndex: 200,
        minHeight: '7vh',
        // opacity: '0.5',
      }}>
        <Typography variant="subtitle2" color="primary" >
          Si la <b>resolución es baja</b>, cambia de <b>cámara</b>.
        </Typography>
        <Select ref={selectDeviceCurrent}
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={deviceId}
          style={{ color: theme.palette.primary.main }}
          onChange={(e) => {
            setDeviceId(null)
            console.log("********handleChangeCamera***********e device Id***: ", e.target.value)
            setDeviceId(e.target.value)
          }}
        >
          {devices?.map((item, index) => {
            // return <MenuItem style={{ color: theme.palette.primary.main }} value={item.deviceId}>{mobileModel}-{item.label}-{item.deviceId}</MenuItem>
            // return <MenuItem style={{ color: theme.palette.primary.main }} value={item.deviceId}>Cámara {index + 1}</MenuItem>
            return <MenuItem key={`Id_${index}`} style={{ color: theme.palette.primary.main }} value={item.deviceId}>{mobileModel}-{item.label}-Cámara {index + 1}</MenuItem>
          })
          }
        </Select>
        <Box sx={{
          display: 'flex',
          width: '100%',
          justifyContent: "center",
          flexDirection: 'column',
        }}>
          {deviceIdDefault != null ? <Box sx={{ overflow: 'auto' }} >
            Id D:  {deviceIdDefault}
          </Box> : null
          }
          {deviceId !== undefined && deviceId != null && deviceId?.length > 0 ?
            <Box sx={{ overflow: 'auto' }} >
              Id C:  {deviceId}
            </Box> : null
          }
        </Box>
      </Box>
      : null}
    {
      props.width ? <>
        {deviceId !== undefined && deviceId !== null && (navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/Android/i)) ? <>
          {console.log("controlCameraCustom render: : ", controlCameraCustom)}
          {controlCameraCustom}
        </> :
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            screenshotQuality={1}
            style={props.isDisplay === false ? { display: 'none' } : { display: 'block' }}
            videoConstraints={{
              facingMode: props.FacingMode === undefined || props.FacingMode === null
                || props.FacingMode === "" ? "user" : props.FacingMode,
              width: parseInt(props.width),
              echoCancellation: true,
              noiseSuppression: true,
            }}
            mirrored={IsMirrored(props.Mirrored)}
          />
        }
      </> :
        (<Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          screenshotQuality={1}
          style={props.isDisplay === false ? { display: 'none' } : { display: 'block' }}
          videoConstraints={{
            facingMode: props.FacingMode === undefined || props.FacingMode === null
              || props.FacingMode === "" ? "user" : props.FacingMode,
            echoCancellation: true,
            noiseSuppression: true,
          }}
          mirrored={IsMirrored(props.Mirrored)}
        />)
    }
  </>);
}

const IsMirrored = (mirrored) => {
  if (mirrored === undefined || mirrored === null || mirrored === "") {
    if (navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/Android/i)) {
      return true;
    } else {
      return false;
    }
  } else {
    return mirrored;
  }
}