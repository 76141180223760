import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { fileActions, validationActions } from '../../store/actions'
import protect from '../../utils/protect'
import { useNavigate } from 'react-router-dom'
import { CustomAlert } from "../../components/CustomAlert";

import {
    TextField,
    Button,
    InputAdornment,
    CircularProgress,
    Typography,
    Grid, useTheme
} from '@mui/material'
import Empty from '../../assets/NoResultMeet.png'
import { routes } from '../../utils'
import {
    TableNotifications,
    UseImage,
    CustomLoadingOverlay, GenericHeaderActions
} from '../../components'

import { useStyles } from './NotificationsStyles'
import { mainStyles } from '../../theme/mainStyles'
import { Icon } from '@iconify/react'
import { notificationActions } from '../../store/actions';


function NotificationsComponent(props) {
    const theme = useTheme()
    const localStyles = useStyles(theme)
    const noInfo = mainStyles.noDataSections()
    const history = useNavigate()

    const [searching, setSearching] = useState("")
    const [search, setSearch] = useState("")
    const [selectedRows, setSelectedRows] = useState([])
    const [cancelableItems, seCancelableItems] = useState([])
    const [openDeclineConfirm, setOpenDeclineConfirm] = useState(false)

    const [disabledDecline, setDisabledDecline] = useState(true)

    const cancelables = useSelector(state => state.GetNotificationsCancelables)
    const rdyCancelled = useSelector(state => state.UpdateCancelFilesNotifications)
    const dispatch = useDispatch()
    const getCancelables = (text) => {
        console.log('Tira el dispatch')
        dispatch(notificationActions.GetCancelables({ "SearchText": text }))
    }

    const cancellAll = (files) => {
        setDisabledDecline(true)
        console.log('ROWS TO DELETE: ', files)
        dispatch(notificationActions.UpdateCancelFiles({ "FileIds": files }))
        // setTimeout(() => {
        //     getCancelables('')
        // }, 1000);
        // localStorage.setItem('cancelled', true)
    }

    useEffect(() => {
        if (rdyCancelled.items !== undefined && !rdyCancelled.loading) {
            getCancelables('')
        }
    }, [rdyCancelled])


    useEffect(() => {
        getCancelables(search)

    }, [search])

    useEffect(() => {
        if (cancelables.items !== undefined) {
            seCancelableItems(cancelables.items)
        }
    }, [cancelables])

    const handleOnSelect = (rows) => {
        if (rows.length !== 0) {
            setSelectedRows(rows)
            setDisabledDecline(false)
        } else {
            setDisabledDecline(true)
        }
    }

    const handleDeclineAll = () => {
        cancellAll(selectedRows)
    }

    const handleAsignation = (id) => { history(`${routes.recordatorios}/${id}`) }

    const handleOpenDeclineConfirm = () => {
        setOpenDeclineConfirm(true)
    }

    return (
        <div style={{ maxHeight: '80vh' }}>
            <Grid sx={{ width: '100%' }} display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'}>
                <Button
                    variant="contained"
                    sx={{ height: 45, minWidth: 220, maxWidth: 228, m: 1 }}
                    disabled={disabledDecline}
                    onClick={handleOpenDeclineConfirm} >
                    Rechazar
                </Button></Grid>
            <form className={localStyles.form} >
                <GenericHeaderActions onChangeTextField={(event) => {
                    console.log('event.target.value::: ', event.target.value)
                    event.target.value === '' && setSearch('')
                    setSearching(event.target.value)
                }}
                    placeholderTextField="Busca por Asegurado, Beneficiario, Póliza o No. de reclamación"
                    onClickConfirm={(event) => {
                        event.preventDefault()
                        setSearch(searching)
                    }}
                    ConfirmButtonText="Buscar"
                    CancelButtonText="Limpiar búsqueda"
                    onClickCancel={() => { setSearch(""); setSearching('') }} />
            </form>
            {cancelables.error && <p>{"Error de red favor de actualizar la pagina"}</p>}
            {cancelables.loading || rdyCancelled.loading ?
                <CustomLoadingOverlay
                    active={cancelables.loading} text="Espera un momento por favor..."
                />
                : (cancelableItems.length > 0) ?
                    <TableNotifications data={cancelableItems} onSelect={handleOnSelect} handleSelected={handleAsignation} />
                    : <div className={noInfo.noResult}>
                        <UseImage src={Empty} type='img' style={{ maxWidth: 300 }} />
                        <Typography variant="h6" className={noInfo.h6}>No se encontraron solicitudes</Typography>
                    </div>}
            {openDeclineConfirm ?
                <CustomAlert
                    containerStyle={{ padding: '20px' }}
                    closeAction={() => {
                        setOpenDeclineConfirm(false);
                    }}
                    open={openDeclineConfirm}
                    title="Rechazar Solicitud"
                    body={`Estas seguro de rechazar la solicitudes seleccionadas?`}
                    leftButtonText="Cancelar"
                    leftButtonAction={() => {
                        setOpenDeclineConfirm(false);
                    }}
                    rightButtonText="Aceptar"
                    rightButtonAction={handleDeclineAll}
                /> : null}
        </div>)
}

export default protect(NotificationsComponent)