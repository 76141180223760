import React from 'react'

export default function GroupIcon (props) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.529" height="13.765"><g data-name="Grupo 2644"><g data-name="Grupo 6"><path data-name="Trazado 40" d="M4.588 8.029a2.294 2.294 0 10-2.294-2.294 2.3 2.3 0 002.294 2.294zm1.3 1.262a8.009 8.009 0 00-1.3-.115 7.973 7.973 0 00-3.189.665A2.307 2.307 0 000 11.964v1.8h5.162v-1.846a5.16 5.16 0 01.722-2.627zm17.057-1.262a2.294 2.294 0 10-2.294-2.294 2.3 2.3 0 002.29 2.294zm4.588 3.934a2.307 2.307 0 00-1.4-2.122 7.8 7.8 0 00-4.485-.551 5.16 5.16 0 01.723 2.627v1.847h5.162zm-8.9-3.189a11.979 11.979 0 00-4.864-1.032A12.171 12.171 0 008.9 8.775a3.427 3.427 0 00-2.019 3.143v1.847h13.766v-1.847a3.427 3.427 0 00-2.019-3.143zM13.765 0a3.441 3.441 0 103.441 3.441A3.437 3.437 0 0013.765 0z" fill={props.fill}/></g></g></svg>
    )
}
 


