import { userConstants } from '../constants'
import { userRequests } from '../../services'

export const userActions = {}

userActions.ChangePassword = (data) => {
  return dispatch => {
    dispatch(request())
    userRequests.ChangePassword(data)
      .then(
        changePass => dispatch(success(changePass)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST }
  }

  function success(changePass) {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS, changePass }
  }

  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, error }
  }
}

userActions.GetUsers = (data) => {
  return dispatch => {
    dispatch(request())
    userRequests.GetUsers(data)
      .then(
        usersData => dispatch(success(usersData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: userConstants.GETALL_REQUEST }
  }

  function success(usersData) {
    return { type: userConstants.GETALL_SUCCESS, usersData }
  }

  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error }
  }
}

userActions.RegisterUserRole = (data) => {
  return dispatch => {
    dispatch(request())
    userRequests.RegisterUserRole(data)
      .then(
        registerData => dispatch(success(registerData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: userConstants.REGISTER_REQUEST }
  }

  function success(registerData) {
    return { type: userConstants.REGISTER_SUCCESS, registerData }
  }

  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error }
  }
}

userActions.UpdateUserRole = (data) => {
  return dispatch => {
    dispatch(request())
    userRequests.UpdateUserRole(data)
      .then(
        updateData => dispatch(success(updateData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: userConstants.UPDATE_REQUEST }
  }

  function success(updateData) {
    return { type: userConstants.UPDATE_SUCCESS, updateData }
  }

  function failure(error) {
    return { type: userConstants.UPDATE_FAILURE, error }
  }
}

userActions.RecoverChangePassword = (data) => {
  return dispatch => {
    dispatch(request())
    userRequests.RecoverChangePassword(data)
      .then(
        registerCPassData => dispatch(success(registerCPassData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: userConstants.RECOVER_PASSWORD_REQUEST }
  }

  function success(registerCPassData) {
    return { type: userConstants.RECOVER_PASSWORD_SUCCESS, registerCPassData }
  }

  function failure(error) {
    return { type: userConstants.RECOVER_PASSWORD_FAILURE, error }
  }
}