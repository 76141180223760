import { alpha } from "@mui/material";

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#00847F",
  dark: "#00847F",
  darker: "#08660D",
  contrastText: "#212B36",
};

/* const ERROR = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
}; */

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#A8000D",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff",
};

const INFO = {
  main: "#FFC222",
};

export const palette = {
  primary: {
    dark: "#890921",
    main: "#DB0011",
    main100: alpha("#DB0011"),
    main80: alpha("#DB0011", 0.8),
    main60: alpha("#DB0011", 0.6),
    main40: alpha("#DB0011", 0.4),
    main20: alpha("#DB0011", 0.2),
    main10: alpha("#DB0011", 0.1),
    lightContrast: "#FCFCFC",
    darkContrast: "#000000",
    contrastText: "#F2F2F2",
    TableHeader: "#DB0011",
    TableHeader2: "#DB0011",
    text: "#424242",
    selectMenu: "#DB0011",
    borderASummary: "#EEEEEE",
    mainLyrics: '#FFFFFF',
    tableMain: "#243746",
    tableSecondary: "#FFB8B8",
    tableText: "#FFFFFF",
    tableHover: '#FFECEB',
  },
  secondary: {
    dark: "#000000",
    main: "#676767",
    main80: "#3F3F3E",
    main60: "#A0A1A2",
    main40: "#B9B9B9",
    main20: "#D1D1D1",
    main10: "#E8E8E8",
    lightContrast: "#F7F7F3",
    darkContrast: "#000000",
    contrastText: "#FFFFFF",
    light: '#F4F4F4',
  },
  nabvar: {
    main: "#DB0011",
  },
  check: {
    main: '#DB0011',
    text: '#1E1E1E',
  },
  tab: {
    text: '#DB0011',
  },
  footerBar: {
    main: '#424242',
    text: '#ffffff',
  },
  menuColor: {
    main: '#DB0011',// Color de backgroung
    text: '#DB0011',
  },
  userStyle: {
    icNotificaciones: '#292B30',
    icUser: '#DB0011',
    textDetails: '#292B30',
    textInstruccions: '#292B30',
  },
  iconPrincButton: {
    main: '#DB0011',
    bgIcon: '#FFFFFF',
    text: '#FFFFFF',
  },
  iconNamePrincipal: {
    main: '#DB0011',
    text: '#ffffff'
  },
  linkButton: {
    main: '#DB0011', // color de fondo button
  },
  actorsButton: {
    main: '#DB0011',
    bgIcon: '#FFFFFF'
  },
  status: {
    info: '#000000',// Color de textos detalle
  },
  selectTab: {
    info: '#243746', // Tabs selected color
    icon: '#484D58',
    textColor: '#FFFFFF',
    indicator: '#243746',
    border: '#243746',
    infoDisabled: '#F6F6F6',
    textColorDisabled: '#000000',
  },
  accordion: {
    main: '#243746 ',
    line: '#FFF0E8'
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  success: { ...SUCCESS },
  error: { ...ERROR },
  info: { ...INFO },
};
