import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  OutlinedInput,
  Button,
  Typography, styled, useTheme
} from '@mui/material'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  FormProvider,
  useFormContext,
  Controller,
  useForm,
} from "react-hook-form";

import { useStyles } from './TableStyle';
import { mainStyles } from '../../theme/mainStyles'

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableMain} !important`,
  color: `${theme.palette.primary.tableText} !important`,
}));

const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
  }
}));

export const MainTableEquivalencias = (props) => {
  const theme = useTheme()
  const localStyle = useStyles(theme)
  const noInfo = mainStyles.noDataSections()

  //Formulario dinamico
  const formMethods = useForm();
  const { control, handleSubmit } = formMethods;

  // console.log("data: INFO", props.data)

  const newData = props.data?.map((col) => {
    return {
      Nombre: col.ValueMetadataName === undefined || col.ValueMetadataName === null ? col.VsValueMetadataName : col.ValueMetadataName,
      // Nombre: col.Name,
      Valor: col.Value,
      Resultado: col.Approved,
      MotivoRechazo: col.Message,
    }
  })

  const columns = [
    { id: 'Nombre', label: 'Nombre', minWidth: 200, height: 74 },
    { id: 'Valor', label: 'Valor', minWidth: 170 },
    { id: 'Resultado', label: 'Resultado', minWidth: 70 },
    { id: 'MotivoRechazo', label: 'Motivo de rechazo', minWidth: 70 },
  ]

  const createData = (Nombre, Valor, Resultado, MotivoRechazo) => {
    return { Nombre, Valor, Resultado, MotivoRechazo }
  }

  const rows = newData?.map((r) => {
    return createData(r.Nombre, r.Valor, r.Resultado, r.MotivoRechazo)
  })

  const [orderDirection, setOrderDirection] = useState('')
  const [valueToOrderBy, setValueToOrderBy] = useState('')

  const handlerSort = (event, property) => {
    const isAscending = (valueToOrderBy === property && orderDirection === 'asc')
    setValueToOrderBy(property)
    setOrderDirection(isAscending ? 'desc' : 'asc')
  }

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property)
  }

  return (
    <div className={localStyle.table2}>
      <FormProvider {...formMethods}>
        {props.data?.length > 0 ?
          <TableContainer sx={{ width: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCellHead
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                      onClick={createSortHandler(column.label)}
                    >
                      {column.label}
                    </TableCellHead>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rows === undefined || rows === null || rows?.length <= 0) ? null : rows.map((row, rowIndex) => { //sortRowInformation(rows, getComparator(orderDirection,valueToOrderBy))
                  // console.log("Items................Table******++row: ", row, " - props.Validation: ", props.Validation)
                  return (
                    <TableRowCustom hover role="checkbox" tabIndex={-1} key={rowIndex} >
                      {columns.map((column, index) => {
                        const value1 = row[column.id];
                        // console.log(`Columna: ${column.id}- row: ${value1}`);
                        if (column.id === "Valor" //&& props.documentName !== "INE / IFE"
                          && (props.status === "Validación de Mesa de control" ||
                            props.status === "Validación documental" ||
                            props.status === "Validación de documentos")
                          // && (props.Validation === null || props.Validation == false)
                        ) {
                          return <TableCell key={row.Nombre + rowIndex}>
                            <Controller
                              control={control}
                              // name={`${row.Nombre}-${rowIndex}`}
                              name={row.Nombre}
                              defaultValue={row.Valor}
                              render={({ field: { ref, ...restFields } }) => (
                                <OutlinedInput
                                  {...restFields}
                                  inputRef={ref}
                                  fullWidth
                                  variant="outlined"
                                  className={localStyle.outlinedText}
                                />
                              )}
                            />
                          </TableCell>
                        } else if (column.id === "Resultado") {
                          return <TableCell
                            key={column.id}
                            style={{ textAlign: 'center' }} >
                            {value1 == true ?
                              <CheckCircleIcon className={localStyle.successClass} />
                              : <CancelIcon className={localStyle.errorClass} />
                            }
                          </TableCell>
                        } else {
                          return <TableCell style={{ maxWidth: 200 }}
                            key={column.id} >
                            <Typography className={localStyle.dataValue} > {value1}</Typography>
                          </TableCell>
                        }
                      })}
                    </TableRowCustom>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          : <Typography variant="h6" className={noInfo.h6}>Sin equivalencias</Typography>
        }
        {//(props.Validation === null || props.Validation === false) && 
          // props.documentName !== "INE / IFE" &&
          props.data?.length > 0 &&
            (props.status === "Validación de Mesa de control" ||
              props.status === "Validación documental" ||
              props.status === "Validación de documentos") ?
            <div className={localStyle.contentButtos} >
              <Button
                fullWidth
                variant="contained"
                sx={{ height: 45, px: 10, maxWidth: 300 }}
                onClick={handleSubmit(props.handleSave)} >
                Aprobar documento
              </Button>
              &nbsp;
              &nbsp;
              <Button
                fullWidth
                variant="outlined"
                sx={{ height: 45, px: 10, maxWidth: 300, color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' } }}
                onClick={handleSubmit(props.handleSave)} >
                Rechazar documento
              </Button>
            </div>
            : null}
      </FormProvider>
    </div>
  )
}