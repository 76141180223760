import { documentConstants } from '../constants'
import { documentRequests } from '../../services'

export const documentActions = {}

documentActions.GetDocument = (data, ext = null) => {
  return dispatch => {
    dispatch(request())
    documentRequests.GetDocument(data, ext)
      .then(
        document => dispatch(success(document)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: documentConstants.DOCUMENT_FILE_REQUEST }
  }

  function success(document) {
    return { type: documentConstants.DOCUMENT_FILE_SUCCESS, document }
  }

  function failure(error) {
    return { type: documentConstants.DOCUMENT_FILE_FAILURE, error }
  }

}

documentActions.GetBioDocument = (data, ext = null) => {
  return dispatch => {
    dispatch(request())
    documentRequests.GetBioDocument(data, ext)
      .then(
        bioDocument => dispatch(success(bioDocument)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: documentConstants.DOCUMENT_BIO_REQUEST }
  }

  function success(bioDocument) {
    return { type: documentConstants.DOCUMENT_BIO_SUCCESS, bioDocument }
  }

  function failure(error) {
    return { type: documentConstants.DOCUMENT_BIO_FAILURE, error }
  }

}
documentActions.GetBioDocumentVersion = (data, ext = null) => {
  return dispatch => {
    dispatch(request())
    documentRequests.GetBioDocumentVersion(data, ext)
      .then(
        bioDocumentVersion => dispatch(success(bioDocumentVersion)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: documentConstants.DOCUMENT_BIO_VERSION_REQUEST }
  }

  function success(bioDocumentVersion) {
    return { type: documentConstants.DOCUMENT_BIO_VERSION_SUCCESS, bioDocumentVersion }
  }

  function failure(error) {
    return { type: documentConstants.DOCUMENT_BIO_VERSION_FAILURE, error }
  }

}

documentActions.UpdateMetaData = (data) => {
  return dispatch => {
    dispatch(request())
    documentRequests.UpdateMetaData(data)
      .then(
        docUpdateData => dispatch(success(docUpdateData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: documentConstants.DOCUMENT_UPDATEMETADATA_REQUEST }
  }

  function success(docUpdateData) {
    return { type: documentConstants.DOCUMENT_UPDATEMETADATA_SUCCESS, docUpdateData }
  }

  function failure(error) {
    return { type: documentConstants.DOCUMENT_UPDATEMETADATA_FAILURE, error }
  }
}

documentActions.DownloadDocument = (data) => {
  return dispatch => {
    dispatch(request())
    documentRequests.DownloadDocument(data)
      .then(
        downloadDoc => dispatch(success(downloadDoc)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: documentConstants.DOWNLOAD_DOC_REQUEST }
  }

  function success(downloadDoc) {
    return { type: documentConstants.DOWNLOAD_DOC_SUCCESS, downloadDoc }
  }

  function failure(error) {
    return { type: documentConstants.DOWNLOAD_DOC_FAILURE, error }
  }
}


documentActions.CargaDocumentoWithSection = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.CargaDocumentoWithSection(data).then(
      (cargaDocData) => dispatch(success(cargaDocData)),
      (error) => dispatch(failure(error.message))
    );
  };

  function request() {
    return { type: documentConstants.CARGADOC_WITH_SECTION_REQUEST };
  }

  function success(cargaDocData) {
    return { type: documentConstants.CARGADOC_WITH_SECTION_SUCCESS, cargaDocData };
  }

  function failure(error) {
    return { type: documentConstants.CARGADOC_WITH_SECTION_FAILURE, error };
  }
}

documentActions.CargaSeccion = (sectionData) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.CargarSeccion(sectionData).then(
      (uploadSecData) => dispatch(success(uploadSecData)),
      (error) => dispatch(failure(error.message))
    );
  };

  function request() {
    return { type: documentConstants.UPLOAD_SECTION_REQUEST };
  }

  function success(uploadSecData) {
    return { type: documentConstants.UPLOAD_SECTION_SUCCESS, uploadSecData };
  }

  function failure(error) {
    return { type: documentConstants.UPLOAD_SECTION_FAILURE, error };
  }
};

documentActions.RegisterMetadata = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.RegisterMetadata(data).then(
      (metaData) => dispatch(success(metaData)),
      (error) => dispatch(failure(error.message))
    );
  };

  function request() {
    return { type: documentConstants.REGISTER_DATA_REQUEST };
  }

  function success(metaData) {
    return {
      type: documentConstants.REGISTER_DATA_SUCCESS,
      metaData,
    };
  }

  function failure(error) {
    return { type: documentConstants.REGISTER_DATA_FAILURE, error };
  }
};

documentActions.GetOcrData = (data) => {
  return (dispatch) => {
    dispatch(request());
    documentRequests.GetOcrData(data).then(
      (ocrData) => dispatch(success(ocrData)),
      (error) => dispatch(failure(error.message))
    );
  };

  function request() {
    return { type: documentConstants.GET_OCRDATA_REQUEST };
  }

  function success(ocrData) {
    return {
      type: documentConstants.GET_OCRDATA_SUCCESS,
      ocrData,
    };
  }

  function failure(error) {
    return { type: documentConstants.GET_OCRDATA_FAILURE, error };
  }
};


documentActions.GetDocumentsGroupByRol = (data) => {
  console.log('GetDocumentsGroupByRol', data)
  return dispatch => {
    dispatch(request())
    documentRequests.GetDocumentsGroupByRol(data)
      .then(
        documents => dispatch(success(documents)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: documentConstants.GET_DOCUMENTS_BY_ROL_REQUEST }
  }

  function success(documents) {
    return { type: documentConstants.GET_DOCUMENTS_BY_ROL_SUCCESS, documents }
  }

  function failure(error) {
    return { type: documentConstants.GET_DOCUMENTS_BY_ROL_FAILURE, error }
  }

}
