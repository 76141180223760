import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  useTheme,
  Stack,
  TextField as InputText,
  styled
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import { useForm, FormProvider } from "react-hook-form";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { userManagerActions } from "../../store/actions";
import { MainTableDinamyc } from "../../components/table/MainTableDynamic";
import { FormDynamic, Alert } from "../../components";
import { getHash } from "../../utils";
import { Logout } from '../../utils'

const useStyles = makeStyles((theme) => ({
  loader: {
    position: "absolute !important",
    top: "0 !important",
    bottom: "0 !important",
    left: "0 !important",
    right: "0 !important",
    height: "100vh !important",
    width: "100% !important",
    zIndex: 100000,
  },
}));

const TextField = styled(InputText)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    fontSize: 14,
    width: "100%",
    "& fieldset legend span": {
      display: "none",
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black', // Color del borde cuando está enfocado
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      borderRadius: 0,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 5,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    fontFamily: '"UniversNextforHSBC-Regular", sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black', // Color del borde cuando está enfocado
    },
  },
  "& label.Mui-error": {
    color: "#000 !important",
  },
  "& .MuiSelect-select": {
    paddingTop: '13px',
    paddingBottom: '0px',
    height: 35,
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#A8000B !important;',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: "#000 !important",
  },
  '& form .MuiInputLabel-root.Mui-error': {
    color: "#000 !important",
  },
}));

export default function UsersAdministrator(props) {
  const theme = useTheme();
  const localStyles = useStyles(theme)
  const formMethods = useForm({ mode: "onChange" });
  const classes = useStyles(theme);
  const navigate = useNavigate()


  const usersMC = useSelector((state) => state.GetUsersMC);
  const rolesMC = useSelector((state) => state.RolesMC);
  const productsMC = useSelector((state) => state.ProductUserMC);
  const updateUserMC = useSelector((state) => state.UpdateUserMC);
  const registerUserMC = useSelector((state) => state.RegisterUserMC);

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [sendUserRegister, setSendUserRegister] = useState(false);
  const [loadView, setLoadView] = useState(null);
  const [data, setData] = useState([]);
  const [metadataForm, setMetadataForm] = useState([]);
  const [activeCaptureNew, setaAtiveCaptureNew] = useState(false);
  const [editionActive, setEditionActive] = useState(false);
  const [cleanForm, setCleanForm] = useState(false)
  const [userSelected, setUserSelected] = useState(null)
  const [searchText, setSearchText] = useState('')



  const newData = data?.map((col) => {
    return {
      Id: col.Id,
      Name: col.Name,
      Email: col.Email,
      UserName: col.UserName,
      CreationDate: col.CreationDate,
      Active: col.Active
    };
  });

  const columns = [
    { id: "Id", label: "Id", minWidth: 170 },
    { id: "Name", label: "Nombre", minWidth: 170 },
    { id: "Email", label: "Correo", minWidth: 170 },
    { id: "UserName", label: "Usuario", minWidth: 170 },
    { id: "CreationDate", label: "Fecha", minWidth: 170 },
    { id: "Active", label: "Estatus", minWidth: 170 },
  ];

  const createData = (Id, Name, Email, UserName, CreationDate, Active) => {
    return {
      Id,
      Name,
      Email,
      UserName,
      CreationDate,
      Active: !!Active ?
        <Typography sx={{ color: '#68A913', marginRight: '8px' }}><FiberManualRecordIcon fontSize='inherit' sx={{ pr: 1 }} /><span style={{ color: 'black' }}>Activo</span></Typography>
        : <Typography sx={{ color: '#C20E30', marginRight: '8px' }}><FiberManualRecordIcon fontSize='inherit' sx={{ pr: 1 }} /><span style={{ color: 'black' }}>Inactivo</span></Typography>
    };
  };

  const rows = newData?.map((r) => {
    return createData(r.Id, r.Name, r.Email, r.UserName, r.CreationDate, r.Active);
  });

  const handleGetUsers = (text) => {
    dispatch(userManagerActions.GetUsersMC({ SearchText: text }));
  }

  const handleUpdateUser = (data) => {
    dispatch(userManagerActions.UpdateUserMC(data));
  }

  const handleRegisterUser = (data) => {
    dispatch(userManagerActions.RegisterUserMC(data));
  }

  useEffect(() => {
    setLoadView(true)
    handleGetUsers("")
    dispatch(userManagerActions.GetRolesMC(''));
    dispatch(userManagerActions.GetProductUserMC(''));
    // dispatch(userManagerActions.GetUsersMC({ SearchText: "" }));
  }, []);

  useEffect(() => {
    if (searchText === '') { handleGetUsers(''); }
  }, [searchText])

  useEffect(() => {
    console.log('usersMC::', usersMC)
    if (!!usersMC.items) {
      setData(usersMC.items)
      setLoadView(false)
    } else if (usersMC.error != undefined) {
      setLoadView(false);
      if (usersMC.error == 'unauthorized') {
      }
    }
  }, [usersMC])

  useEffect(() => {
    console.log('Roles and product: ', rolesMC, productsMC)
    if (!!rolesMC.items && rolesMC.items.length > 0
      && !!productsMC.items && productsMC.items.length > 0
      /* && !activeCaptureNew */
    ) {
      setMetadataForm(null);
      let dynamcQuestion = [
        {
          DataType: "string",
          Description: "Producto",
          Id: 9,
          Length: 500,
          Name: "Producto",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Combo",
          Required: true,
          Sequence: 1,
          Visible: true,
          Options: productsMC?.items/* tenatDataResponse?.items */,
        },
        {
          DataType: "string",
          Description: "Rol",
          ExecuteSearchDefinition: [
            {
              Option: 'Médico valorador',
              Requiered: [{
                Question: "IP Pública",
                QuestionGroup: 'Usuario',
                Requiered: false
              }]
            }, {
              Option: 'Agencia de investigación',
              Requiered: [{
                Question: "IP Pública",
                QuestionGroup: 'Usuario',
                Requiered: false
              }]
            }, {
              Option: 'Mesa de control',
              Requiered: [{
                Question: "IP Pública",
                QuestionGroup: 'Usuario',
                Requiered: true
              }]
            }, {
              Option: 'Proveedor',
              Requiered: [{
                Question: "IP Pública",
                QuestionGroup: 'Usuario',
                Requiered: true
              }]
            }, {
              Option: 'Dictaminador HSBC Seguros',
              Requiered: [{
                Question: "IP Pública",
                QuestionGroup: 'Usuario',
                Requiered: true
              }]
            }, {
              Option: 'Tickets',
              Requiered: [{
                Question: "IP Pública",
                QuestionGroup: 'Usuario',
                Requiered: true
              }]
            }, {
              Option: 'Administrador',
              Requiered: [{
                Question: "IP Pública",
                QuestionGroup: 'Usuario',
                Requiered: true
              }]
            }],
          ExecuteSearchFunction: "GetPreguntasRequeridas",
          Id: 8,
          Length: 500,
          Name: "Rol",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Combo",
          Required: true,
          Sequence: 1,
          Visible: true,
          Options: rolesMC?.items,
        },
        {
          DataType: "string",
          Description: "Usuario",
          Id: 1,
          Length: 500,
          Name: "Usuario",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Texto",
          Required: true,
          Sequence: 1,
          Visible: true,
        },
        {
          DataType: "string",
          Description: "Nombre",
          Id: 3,
          Length: 500,
          Name: "Nombre",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Texto",
          Required: true,
          Sequence: 1,
          Visible: true,
        },
        {
          DataType: "string",
          Description: "Segundo Nombre",
          Id: 11,
          Length: 500,
          Name: "Segundo Nombre",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Texto",
          Required: "",
          Sequence: 1,
          Visible: true,
        },
        {
          DataType: "string",
          Description: "Apellido Paterno",
          Id: 4,
          Length: 500,
          Name: "Apellido Paterno",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Texto",
          Required: true,
          Sequence: 1,
          Visible: true,
        },
        {
          DataType: "string",
          Description: "Apellido Materno",
          Id: 5,
          Length: 500,
          Name: "Apellido Materno",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Texto",
          Required: "",
          Sequence: 1,
          Visible: true,
        },
        {
          DataType: "string",
          Description: "Correo",
          Id: 6,
          Length: 500,
          Name: "Correo",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Email",
          Required: true,
          Sequence: 1,
          Visible: true,
        },
        {
          DataType: "string",
          Description: "Teléfono",
          Id: 7,
          Length: 500,
          Name: "Teléfono",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Phone",
          Required: true,
          Sequence: 1,
          Visible: true,
          RegularExpression: "ar",
        },
        {
          DataType: "string",
          Description: "IP Pública",
          Id: 10,
          Length: 500,
          Name: "IP Pública",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Texto",
          Required: true,
          Sequence: 1,
          Visible: true,
        },
        {
          DataType: "string",
          Description: "Estatus del usuario",
          Id: 120,
          Length: 500,
          Name: "Activo",
          QuestionGroup: "Usuario",
          QuestionGroupId: 1,
          QuestionType: "Checkbox",
          CheckTitle: "Estatus del usuario",
          Required: false,
          Sequence: 1,
          Visible: true,
        },
      ];

      let objectFormat = {
        llave: 1,
        groupTitle: "Usuario",
        questions: dynamcQuestion,
      };
      console.log("///////////objectFormat//////////", objectFormat);
      setMetadataForm(objectFormat);
    }
  }, [rolesMC, productsMC, /* tenatDataResponse,  activeCaptureNew*/cleanForm]);

  useEffect(() => {
    if ((editionActive ? (updateUserMC?.items == true) : (registerUserMC?.items == true)) && sendUserRegister) {
      setSendUserRegister(false);
      setLoadView(false);
      setaAtiveCaptureNew(false);
      Alert({
        icon: "success",
        title:
          editionActive == true
            ? "Usuario editado correctamente"
            : "Usuario registrado correctamente",
      },
        handleGetUsers(''));
    } else if (
      (editionActive ? (updateUserMC.error != null) : (registerUserMC.error != null)) &&
      sendUserRegister
    ) {
      setSendUserRegister(false);
      setLoadView(false);
      if (updateUserMC.error == 'unauthorized' || registerUserMC.error == 'unauthorized') {
      } else {
        Alert({
          icon: "error",
          title: editionActive ? updateUserMC.error : registerUserMC.error,
        });
      }


    }
  }, [registerUserMC, updateUserMC]);

  const onClickData = (e) => {
    setEditionActive(true);
    let userSelect = data.find((user) => user.Id == e.Id)
    console.log('User:::', userSelect, metadataForm)
    setUserSelected(userSelect)
    let newArray = metadataForm.questions?.map((question) => {
      switch (question.Name) {
        case "Producto":
          console.log('Producto value:', userSelect?.idProducto[0]?.Name?.toUpperCase())
          return {
            ...question, Value: userSelect?.idProducto[0]?.Name?.toUpperCase()
          };
        case "Rol":
          return { ...question, Value: userSelect?.idRole[0]?.Code?.toUpperCase() };
        case "Usuario":
          return { ...question, Value: userSelect?.UserName };
        case "Nombre":
          return { ...question, Value: userSelect?.Name };
        case "Segundo Nombre":
          return {
            ...question, Value: userSelect?.SecondName
          };
        case "Apellido Paterno":
          return { ...question, Value: userSelect?.FatherLastName };
        case "Apellido Materno":
          return { ...question, Value: userSelect?.MotherLastName };
        case "Correo":
          return { ...question, Value: userSelect?.Email };
        case "Teléfono":
          const asd = { ...question, Value: userSelect?.PhoneNumber }
          return asd;
        case "IP Pública":
          return { ...question, Value: userSelect?.usersIps[0]?.Ip };
        case "Activo":
          return { ...question, Value: userSelect?.Active };
        default:
          return question;
      }
    });
    let objectFormat = {
      llave: 1,
      groupTitle: "Usuario",
      questions: newArray,
    };
    console.log("objectFormat", objectFormat);
    setMetadataForm(objectFormat);
    setaAtiveCaptureNew(true);
  };

  return (
    <>
      {loadView && (
        <LoadingOverlay
          active={loadView}
          spinner
          text="Espera un momento por favor..."
          className={classes.loader}
        ></LoadingOverlay>
      )}

      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container display={'flex'} flexDirection={'column'} justifyContent={'end'} alignItems={'end'} sx={{ mr: 1 }}>
          <Grid>
            {!activeCaptureNew ? (
              <Button
                sx={{
                  mb: '1em',
                  height: 50, minWidth: 225, maxWidth: 228, textTransform: 'none !important'
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setCleanForm(!cleanForm)
                  setEditionActive(false);
                  setaAtiveCaptureNew(true);
                }}
              >
                Nuevo
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12}>
          {activeCaptureNew ? (
            <Box>
              <FormDynamic
                EditMetadataCustom={metadataForm}
                handleSave={(formData, e) => {
                  let ListMetdata = [];
                  console.log("FormDynamic formData: ", formData);
                  console.log("FormDynamic metadataForm: ", metadataForm);

                  metadataForm?.questions?.forEach((itemQ) => {
                    // console.log("itemQ********************: ", itemQ)
                    ListMetdata.push({
                      GroupName: itemQ.QuestionGroup,
                      Name: itemQ.Id,
                      Value:
                        itemQ.QuestionType === "Fecha"
                          ? formData[itemQ.Id] === ""
                            ? null
                            : moment(formData[itemQ.Id]).format("YYYY/MM/DD")
                          : itemQ.QuestionType === "Currency"
                            ? formData[itemQ.Id]
                              ?.replace("$", "")
                              ?.replace(",", "")
                              ?.replace(",", "")
                            : itemQ.QuestionType === "Password"
                              ? getHash(formData[itemQ.Id])
                              : formData[itemQ.Id],
                    });
                  });

                  console.log("ListMetdata: ", ListMetdata);
                  const prodVal = ListMetdata.find(item => item.Name == 9)?.Value?.toUpperCase()//Producto
                  const rolVal = ListMetdata.find(item => item.Name == 8)?.Value?.toUpperCase()//Rol
                  console.log('rolesMC::: ', rolesMC, rolVal)

                  let Request = {
                    Id: editionActive ? userSelected?.Id : 0,
                    UserName: ListMetdata.find(item => item.Name == 1)?.Value,//Usuario
                    Password: editionActive ? userSelected?.Password : null,
                    Name: ListMetdata.find(item => item.Name == 3)?.Value,//Nombre
                    SecondName: ListMetdata.find(item => item.Name == 11)?.Value,//Segundo Nombrer
                    FatherLastName: ListMetdata.find(item => item.Name == 4)?.Value,//Ape. Pat.
                    MotherLastName: ListMetdata.find(item => item.Name == 5)?.Value,//Ape. Mat.
                    Email: ListMetdata.find(item => item.Name == 6)?.Value,//Correo
                    PhoneNumber: ListMetdata.find(item => item.Name == 7)?.Value,//Telfono
                    IpPublica: ListMetdata.find(item => item.Name == 10)?.Value,//Ip
                    Active: ListMetdata.find(item => item.Name == 120)?.Value || false,
                    Metadata: ListMetdata,
                    idProducto: productsMC.items?.find(prod => prod.Value?.toUpperCase() == prodVal)?.Id,
                    idRole: rolesMC.items?.find(rol => rol.Value?.toUpperCase() == rolVal)?.Id
                  }

                  console.log(
                    "Request Update metadata**********************: ",
                    Request
                  );
                  if (editionActive == true) {
                    handleUpdateUser(Request)
                  } else {
                    handleRegisterUser(Request)
                  }
                  setSendUserRegister(true);
                  setLoadView(true);
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    height: 38,
                    minWidth: 350,
                    borderRadius: "8px",
                    // fontWeight: 500,
                    color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' }
                    
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setCleanForm(!cleanForm)
                    setaAtiveCaptureNew(false);
                    setEditionActive(false);
                    // setMetadataForm(null)
                  }}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          ) : (
            <div /* className={localStyles.scroll1} */>
              <Stack alignItems={'center'} justifyContent={'flex-start'} direction={'column'} width={'100%'}>
                <Stack alignItems={'center'} justifyContent={'space-between'} direction={'row'} width={'100%'} sx={{ marginBottom: '10px' }}>
                  <Stack width={'50%'} direction={'row'} alignItems={'start'} justifyContent={'center'} >
                    <TextField value={searchText} placeholder={'Busca al usuario por nombre o por correo electrónico'}
                      sx={{
                        height: 50, width: '100% !important',
                        /*  paddingTop: 3,
                         padding: 0,
                         border: '1px solid #DADADA',
                          */
                      }} onChange={(e) => { setSearchText(e.target.value) }}
                      inputProps={{ disableUnderline: true, }}
                      onKeyDown={(e) => { e.key === 'Enter' && handleGetUsers(searchText) }} />
                  </Stack>
                  <Stack alignItems={'center'} justifyContent={'center'} direction={'row'}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} >
                      <Grid /* item lg={2.8} xs={12} */ sx={{ paddingTop: "0px !important", paddingLeft: "6px !important" }} >
                        <Button
                          variant="contained"
                          sx={{ height: 50, minWidth: 225, maxWidth: 228, m: 1, textTransform: 'none !important' }}
                          onClick={() => { handleGetUsers(searchText) }}>
                          Buscar
                        </Button>
                      </Grid>
                    </Stack>
                    <Stack direction={'row'} alignItems={'start'} justifyContent={'center'}>
                      <Grid /*  item lg={2.8} xs={12} */ sx={{ paddingTop: "0px !important", paddingLeft: "6px !important" }} >
                        <Button
                          variant="outlined"
                          sx={{
                            height: 50, minWidth: 225, maxWidth: 228, m: 1, textTransform: 'none !important',
                            color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' }
                          }}
                          onClick={() => { setSearchText(''); handleGetUsers('') }} >
                          Limpiar búsqueda
                        </Button>
                      </Grid>
                    </Stack>
                  </Stack>
                </Stack>
                <Box sx={{ width: '100%' }}>
                  <MainTableDinamyc
                    Rows={rows}
                    Columns={columns}
                    OnClickData={(e) => onClickData(e)}
                  />
                </Box>
              </Stack>
            </div>

          )}
        </Grid>
      </Grid>
    </>
  );
}
