import { Box, Button, CircularProgress, Grid, Paper, Typography,useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Alert, UseImage, UploadDcumentsControl, CustomLoadingOverlay } from '../../../components'
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import iconMeet from '../../../assets/iconoMeet.png'
import iconDocument from '../../../assets/DocumentsIcon.png'
import { UploadDcumentsControlMultiple } from '../../../components/DocumentUpload/UploadDcumentsControlMultiple';
import { fileMasiveRequests } from '../../../services';
import { useDispatch } from 'react-redux';
import { fileMasiveActions } from '../../../store/actions';
import { useStyles } from '../../home/HomeStyles'
import { Icon } from '@iconify/react'

export default function UploadDocumentsMasive(props) {
    const theme = useTheme()
    const dispatch = useDispatch();
    const localStyles = useStyles(theme);
    const navigation = useNavigate();
    const [uploadIdBatch, setUploadIdBatch] = useState();
    const [error, setError] = useState(false);
    const formMethods = useForm({ mode: "onChange" });
    const [documentsUpload, setDocumentsUpload] = useState();
    const [captionError, setCaptionError] = useState();
    const [loadView, setLoadView] = useState(false);

    console.log('log', props.dataGroup)

    // const validarDocumentos = (documentos1, documentos2) => {
    //     const result = [];

    //     documentos1?.forEach((documento1) => {
    //         const codigoDocumento = documento1.name.split("_")[0];
    //         const documento2 = documentos2?.find((d) => d.Code === codigoDocumento && d.DocumentType === (documento1.name.endsWith("_em.pdf") ? "Examen Médico" : "Estudio de Seguridad"));

    //         if (documento2 && !documento2.DocumentoCargado) {
    //             result.push({ name: documento1.name, status: true });
    //         } else {
    //             result.push({ name: documento1.name, status: false });
    //         }
    //     });

    //     return result;
    // };

    // function verificarDocumentos(documentos1, documentos2) {
    //     const result = [];

    //     documentos1?.forEach((documento1) => {
    //       const primerGuionBajo = documento1.name.indexOf("_");
    //       const codigoDocumento = primerGuionBajo !== -1 ? documento1.name.substring(0, primerGuionBajo) : documento1.name;
    //       const ultimoGuionBajo = documento1.name.lastIndexOf("_");
    //       const sufijoDocumento = ultimoGuionBajo !== -1 ? documento1.name.substring(ultimoGuionBajo) : "";

    //       const documento2 = documentos2?.find((d) => d.Code === codigoDocumento && d.DocumentType === (sufijoDocumento === "_em.pdf" ? "Examen Médico" : "Estudio de Seguridad"));

    //       if (documento2 && !documento2.DocumentoCargado) {
    //         result.push({ name: documento1.name, status: true });
    //       } else {
    //         result.push({ name: documento1.name, status: false });
    //       }
    //     });

    //     return result;
    //   }

    function verificarDocumentos(documentos1, documentos2) {
        const result = [];

        documentos1?.forEach((documento1) => {
            const nombreDocumento1 = documento1.name;
            const subfijosDocumento1 = nombreDocumento1.split("_");
            console.log('subfijosDocumento1', subfijosDocumento1)

            if (subfijosDocumento1.length === 2) {
                const codigoDocumento = subfijosDocumento1[0];
                const documento2 = documentos2?.find((d) => d.Code === codigoDocumento && d.DocumentType === (nombreDocumento1.endsWith("_em.pdf") || nombreDocumento1.endsWith("_em.jpg") || nombreDocumento1.endsWith("_em.png") ? "Examen Médico" : "Estudio de Seguridad"));

                if (documento2 && !documento2.DocumentoCargado) {
                    result.push({ name: nombreDocumento1, status: true });
                } else {
                    result.push({ name: nombreDocumento1, status: false });
                }
            } else {
                result.push({ name: nombreDocumento1, status: false });
            }
        });

        return result;
    }


    let handleFileChange = async (FileContent, fileB64Response, extension) => {
        // console.log('FileContent', FileContent)
        // console.log('FileContent', props.users)

        if (props.users?.length > 0) {
            const documentos1Validados = verificarDocumentos(FileContent, props.users);
            const validacionCorrecta = documentos1Validados.filter(item => item.status === false);
            console.log('validacionCorrecta', validacionCorrecta?.length > 0);

            if (validacionCorrecta?.length > 0) {
                console.log('ENTRA 0');
                console.log('ALGO SALIO MAL');
                setDocumentsUpload(documentos1Validados);
                setCaptionError([{ error: 'error' }]);
                setError(true);
                Alert({
                    icon: 0,
                    title: 'Revisa el nombre de tus documentos sea correcto o no esta ya cargado',
                });
            } else {
                console.log('documentos1Validados', documentos1Validados);
                console.log('ENTRA 1');
                if (FileContent?.length > 0) {
                    setLoadView(true)
                    try {
                        const formData = new FormData();
                        formData.append('FileContent', FileContent[0]);
                        formData.append('BatchDocumentId', '');
                        formData.append('StatusProceso', 'Carga documental por Background Check Vendor');
                        formData.append('BatchUpload_Id', props?.dataGroup?.Id);

                        // Ejecutar la primera petición para obtener el BatchDocumentId
                        const response = await fileMasiveRequests.UploadBatchDocument(formData);
                        const batchDocumentId = response?.Response?.BatchDocumentId;
                        console.log('responseresponse', response)
                        if (FileContent.length > 1) {
                            if (batchDocumentId) {
                                setUploadIdBatch(batchDocumentId)
                                // Crear un array de promesas para las demás peticiones
                                const promises = FileContent.slice(1).map((document) => {
                                    console.log('document', document)
                                    const formData = new FormData();
                                    formData.append('FileContent', document);
                                    formData.append('BatchDocumentId', batchDocumentId);
                                    formData.append('BatchUpload_Id', props.dataGroup.Id);
                                    formData.append('StatusProceso', 'Carga documental por Background Check Vendor');
                                    return fileMasiveRequests.UploadBatchDocument(formData);
                                });
                                const results = await Promise.all(promises);
                                console.log('resultsPromiss', results);
                                const errores = results.filter(item => item?.error);
                                setCaptionError(errores);
                                if (errores?.length > 0) {
                                    Alert({
                                        icon: 0,
                                        title: errores[0]?.error,
                                    });
                                    setError(true)
                                    setDocumentsUpload(FileContent);
                                    setLoadView(false)
                                } else {
                                    console.log('resultsPromiss', results);
                                    setDocumentsUpload(FileContent);
                                    setLoadView(false)
                                    return results;
                                }
                            }
                        } else {
                            if (response.Message !== null) {
                                console.log(response)
                                Alert({
                                    icon: 0,
                                    title: response?.error,
                                });
                                setCaptionError([response.Message])
                                setDocumentsUpload([FileContent]);
                                setLoadView(false)
                            } else {
                                setCaptionError()
                                setDocumentsUpload([FileContent]);
                                setLoadView(false)
                            }

                        }
                    } catch (error) {
                        Alert({
                            icon: 0,
                            title: error,
                        });
                        console.log(error)
                        setLoadView(false)
                    }
                }
            }
        }
    };

    console.log('documentsUpload', documentsUpload, captionError?.length)

    const sendDocuments = () => {
        dispatch(fileMasiveActions.UploadBatchDocumentProcess({
            "BatchDocumentId": uploadIdBatch
        }));
        navigation('/inbox/dashboards')
    }

    if (loadView === true) {
        return (
            <CustomLoadingOverlay
                active={loadView} text="Espera un momento por favor..."
            />
        )
    }
    return (
        <>

            {documentsUpload?.length > 0 ?
                <>
                    <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                        <Grid lg={6} xs={12}>
                            <Box>
                                <Box style={{ alignContent: "center" }}>
                                    <Typography variant='h6'>
                                        Revisa que tus documentos estén correctos
                                    </Typography>
                                </Box>
                                {documentsUpload?.map((item) => (
                                    <Paper elevation={3} style={{ margin: '1em' }}>
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <Box display={'flex'} alignItems={'center'} flexGrow={1}>
                                                <Box style={{ background: '#008EDD', margin: '.4em', borderRadius: 10 }}>
                                                    <Box style={{ margin: '.5em' }}>
                                                        <UseImage src={iconDocument} type='img' />
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    {console.log(item)}
                                                    <Typography variant='h5'>
                                                        {item.name || item[0]?.name}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box style={{ marginRight: '1em' }}>
                                                <Icon color={item.status === false ? "#F24E1E" : "#0FA958"} fontSize={35}
                                                    icon={item.status === false ? "mdi:close-circle-outline" : "material-symbols:check-circle-outline-rounded"} />
                                            </Box>
                                        </Box>
                                    </Paper>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' alignContent='center'>
                        <Grid lg={5} xs={12}>
                            <Box display={'flex'} justifyContent='center'>
                                <Button style={{
                                    height: 38,
                                    width: '100%',
                                    margin: '1em',
                                    borderRadius: '25px',
                                    fontSize: 16,
                                    fontWeight: 500
                                }} variant='outlined' color="primary" onClick={() => {
                                    setDocumentsUpload()
                                    setError(false)
                                }}>
                                    {error ? 'Reintentar' : 'Cancelar'}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' alignContent='center'>
                        <Grid lg={5} xs={12}>
                            <Box display={'flex'} justifyContent='center'>
                                <Button style={{
                                    height: 38,
                                    width: '100%',
                                    margin: '1em',
                                    borderRadius: '25px',
                                    fontSize: 16,
                                    fontWeight: 500
                                }} variant='contained' disabled={captionError?.length > 0 ? true : false} color="primary" onClick={() => sendDocuments()}>
                                    Enviar
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </>

                : (
                    <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                        <Grid item lg={10} xs={12}>
                            <Box textAlign={'justify'} display="flex" alignItems={'center'}>
                                <Typography variant='h6'>
                                    Arrastra tus archivos con extensión .pdf de los candidatos pertenecientes al grupo que seleccionaste, deberás guardar los archivos de la siguiente manera, revisa que el identificador este completo con ceros y números:
                                </Typography>
                            </Box>
                            <Box textAlign={'justify'} display="flex" alignItems={'center'}>
                                <Typography variant='h6'>
                                    *El identificador corresponde al número de folio asignado al empleado, este aparece en la lista de la pantalla anterior de empleados pertenecientes al grupo.
                                </Typography>
                            </Box>
                            <Box textAlign={'justify'} display="flex" alignItems={'center'}>
                                <UseImage src={iconMeet} type='img' style={{ margin: '1em' }} />
                                <Typography variant='h6'>
                                    Estudio de seguridad: <b>Identificador_es</b>
                                </Typography>
                            </Box>
                            <Box textAlign={'justify'} display="flex" alignItems={'center'}>
                                <UseImage src={iconMeet} type='img' style={{ margin: '1em' }} />
                                <Typography variant='h6'>
                                    Examen Médico: <b>Identificador_em</b>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={10} xs={12}>
                            <FormProvider {...formMethods}>
                                <UploadDcumentsControlMultiple
                                    Title={'Arrastre su documento con extensión .pdf o'}
                                    onChange={handleFileChange}
                                />
                            </FormProvider>
                        </Grid>
                        <Grid lg={5} xs={12}>
                            <Box display={'flex'} justifyContent='center'>
                                <Button style={{
                                    height: 38,
                                    width: '100%',
                                    margin: '1em',
                                    borderRadius: '25px',
                                    fontSize: 16,
                                    fontWeight: 500
                                }} variant='outlined' color="primary" onClick={() => props.setCargaMasiva(false)}>
                                    Cancelar
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                )}
        </>
    )
}
