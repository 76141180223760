export const authConstants = {
  AUTH_REQUEST: 'AUTH_REQUEST',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_FAILURE: 'AUTH_FAILURE',

  AUTHREFERER_REQUEST: 'AUTHREFERER_REQUEST',
  AUTHREFERER_SUCCESS: 'AUTHREFERER_SUCCESS',
  AUTHREFERER_FAILURE: 'AUTHREFERER_FAILURE',

  CLIENTIPS_REQUEST: 'CLIENTIPS_REQUEST',
  CLIENTIPS_SUCCESS: 'CLIENTIPS_SUCCESS',
  CLIENTIPS_FAILURE: 'CLIENTIPS_FAILURE',

  LOGINVALIDITY_REQUEST: 'LOGINVALIDITY_REQUEST',
  LOGINVALIDITY_SUCCESS: 'LOGINVALIDITY_SUCCESS',
  LOGINVALIDITY_FAILURE: 'LOGINVALIDITY_FAILURE',
  
  UNAUTHENTICATE_REQUEST: 'UNAUTHENTICATE_REQUEST',
  UNAUTHENTICATE_SUCCESS: 'UNAUTHENTICATE_SUCCESS',
  UNAUTHENTICATE_FAILURE: 'UNAUTHENTICATE_FAILURE',
}
