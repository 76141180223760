import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Link,
  Button,
  styled,
  AppBar,
  Container,
  Box,
  Typography,
  useTheme,
  Divider,
  IconButton,
  Hidden,
} from "@mui/material";

import { UseImage } from "./UseImage";
import { useWindowDimensions } from "../utils";
import logoMet from "../assets/login.svg";

const MenuButton = styled(Link)(({ theme }) => ({
  margin: "2px 0px",
  borderRight: `1px solid ${theme.palette.secondary.main10}`,
  textAlign: "left",
  fontSize: 14,
  "&:hover": {
    backgroundColor: theme.palette.menuColor.main,
    color: theme.palette.menuColor.text,
  },
  height: "42px",
  // borderRadius: '7px',
  "& .MuiButton-startIcon": {
    marginBottom: 2,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 16,
  },
}));

const menuItems = [
  {
    id: "Busqueda",
    name: "Buscar",
    idOrder: 1,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="akar-icons:search" />
      </Box>
    ),
    link: "/inbox/search",
  },
  // {
  //   id: "Generar Solicitudes",
  //   name: "Alta Candidatos",
  //   idOrder: 2,
  //   icon: <Box style={{ marginLeft: ".8em", marginRight: '.8em', display: "flex", alignItems: "center" }}><Icon fontSize={25} icon="heroicons:users" /></Box>,
  //   link: "/inbox/new-file",
  // },
  {
    id: "Asignaciones",
    name: "Asignaciones",
    idOrder: 3,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="majesticons:document-award-line" />
      </Box>
    ),
    link: "/inbox/homeComponent",
    // optionsSecundary: [{
    //   name: "Contratación normal",
    //   link: "/inbox/homeComponent/single",
    // },
    // {
    //   name: "Contratación masiva",
    //   link: "/inbox/homeComponent/masive",
    // }
    // ]
  },
  {
    id: "Asignaciones2",
    name: "Asignaciones2",
    idOrder: 4,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="majesticons:document-award-line" />
      </Box>
    ),
    link: "/inbox/homeComponent",
  },
  {
    id: "Recordatorios",
    name: "Recordatorios",
    idOrder: 3,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="majesticons:document-award-line" />
      </Box>
    ),
    link: "/inbox/notifications",
  },
  {
    id: "Administración de usuarios",
    name: "Administrador de usuarios",
    idOrder: 3,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="majesticons:document-award-line" />
      </Box>
    ),
    link: "/inbox/UserManagement",
  },
  // {
  //   id: "Dashboard",
  //   name: "Dashboard",
  //   idOrder: 4,
  //   icon: <Box style={{ marginLeft: ".8em", marginRight: '.8em', display: "flex", alignItems: "center" }}><Icon fontSize={25} icon="iconoir:reports" /></Box>,
  //   link: "/inbox/dashboards",
  // },
  {
    id: "Reportes",
    name: "Reportes",
    idOrder: 5,
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="tabler:report-analytics" />
      </Box>
    ),
    // link: "/inbox/UserManagement",
    secundary: [
      {
        name: "Reportes de operación I",
        rol: "Reportes",
      },
      {
        name: "Reportes de operación II",
        rol: "Reportes",
      },
      {
        name: "Reporte diario HSBC",
        rol: "Reporte diario HSBC",
      },
      {
        name: "Reporte Asegurabilidad",
        rol: "Reporte Asegurabilidad",
      },
      {
        name: "Reporte Data Siniestros",
        rol: "Reporte Data Siniestros",
      },
      {
        name: "Reporte de encuestas",
        rol: "Reporte de encuestas",
      },
    ],
  },
];

export const HeaderAppBar = (props) => {
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const theme = useTheme();
  const stateAuth = useSelector((state) => state.AuthenticationReferer);

  console.log("stateAuth::: ", stateAuth);
  let optionsHeader =
    stateAuth.items?.UserLoginData?.SecurityLoginData?.Applications[0]
      ?.Modules[0]?.Functions;
  console.log("optionsHeader: ", optionsHeader);
  let rolUser =
    stateAuth.items?.UserLoginData?.SecurityLoginData?.Roles[0]?.Name;

  const getNavItemActive = (menuLink) => {
    // console.log('locationName', menuLink)
    // console.log('locationName', location.pathname.includes(menuLink))
    return location.pathname.includes(menuLink);
  };

  console.log(rolUser);

  const openWindowModalReport = (name) => {
    console.log("entra aqui");
    let wihe = "width=" + width + ",height=" + height;
    if (name === "Reportes de operación I") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/HSBC/HSBC`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe
      );
    } else if (name === "Reportes de operación II") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/HSBC/HSBC%20PROCESOS`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe
      );
    } else if (name === "Reporte diario HSBC") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/HSBC/HSBC%20DIARIO`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe
      );
    } else if (name === "Reporte Asegurabilidad") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/HSBC/HSBC%20LAYOUT`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe
      );
    } else if (name === "Reporte Data Siniestros") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/HSBC/EXTRACCION%20METADATOS`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe
      );
    } else if (name === "Reporte de encuestas") {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/HSBC/ENCUESTAS`,
        "_blank",
        "resizable=1,screenX=1,screenY=1,left=1,top=1," + wihe
      );
    }
  };

  const itemsOrdenados = () => {
    /*  if (!stateAuth.items?.UserLoginData?.SecurityLoginData?.IsAdmin) {
       const itemIndex = menuItems.findIndex(item => item.id === 'Recordatorios')
       if (itemIndex !== -1) {
         menuItems.splice(itemIndex, 1)
       }
     } */
    return menuItems?.sort((a, b) => a.idOrder - b.idOrder);
  };

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "#FFFFFF",
        color: "black",
        height: "auto",
        maxWidth: 1400,
        border: "1px solid white",
        mx: "auto",
      }}
    >
      <Container
        maxWidth="xl"
        style={{ margin: 0, padding: 0, maxWidth: "100%" }}
      >
        <Box
          disableGutters
          style={{
            p: 0,
            m: 0,
            mr: { xs: 0, sm: 0 },
            ml: { xs: 0, sm: 0, md: 0 },
            [theme.breakpoints.up("lg")]: {
              flexDirection: "column !important",
              height: "100vh",
            },
            [theme.breakpoints.down("lg")]: {
              flexDirection: "column !important",
              height: "100vh",
            },
            [theme.breakpoints.down("md")]: {
              flexDirection: "column !important",
              height: "5vh",
            },
            [theme.breakpoints.down("sm")]: {
              height: "5vh",
            },
          }}
        >
          {/* mobile init */}
          <Hidden only={["lg", "md", "xl"]}>
            <Box
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Box>
                <IconButton
                  onClick={props.openDrawer}
                  size="large"
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 1,
                  [theme.breakpoints.down("md")]: {
                    justifyContent: "end",
                  },
                  [theme.breakpoints.down("sm")]: {
                    justifyContent: "end",
                    mr: 1,
                  },
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <UseImage src={logoMet} type="img" style={{ width: "50%" }} />
                </Box>
              </Box>
            </Box>
          </Hidden>
          {/* mobile finis */}

          {/* web init */}
          <Hidden smDown>
            <Box
              style={{
                justifyContent: "center",
                width: "90%",
                margin: "1em 1em",
              }}
            >
              <UseImage src={logoMet} type="img" style={{ width: "85%" }} />
            </Box>
            <Box style={{ margin: "1em 0 0 0", width: "80%" }}>
              <Divider />
            </Box>
            <Box
              style={{
                display: { xs: "none", md: "flex" },
                margin: "0.8em 0.8em 0.5em 0.8em", //  marginTop: '.8em', marginBottom: ".5em",
                width: "90%",
              }}
            >
              <Typography
                variant="h6"
                style={{ color: "#3F3F3E", fontWeight: 550 }}
              >
                Menú
              </Typography>
            </Box>
            <Box
              style={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                width: "100%",
                [theme.breakpoints.up("lg")]: {
                  flexDirection: "column !important",
                },
                [theme.breakpoints.down("lg")]: {
                  flexDirection: "column !important",
                },
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column !important",
                },
              }}
            >
              {itemsOrdenados()?.map((item, index) => {
                const menuItem = optionsHeader?.find(
                  (mi) =>
                    mi.Name ===
                    item.id /* || item.id === 'Administración de usuarios' */
                );
                if (menuItem) {
                  if (
                    item.name == "Recordatorios" &&
                    !stateAuth?.items?.UserLoginData?.SecurityLoginData?.IsAdmin
                  )
                    return null;

                  console.log("item:::", item);

                  return (
                    <div key={index}>
                      {rolUser === "Empleado" &&
                      item.id === "Generar Solicitudes" ? null : (
                        <MenuButton
                          key={index}
                          disabled={item.name}
                          component={RouterLink}
                          to={item.link}
                          sx={{
                            display: "flex",
                            justifyContent: "left",
                            alignContent: "center",
                            alignItems: "center",
                            textAlign: "left",
                            // marginLeft: 2,
                            marginTop: "1em",
                            textDecoration: "none !important",
                            borderBottom: "none",
                            fontWeight: getNavItemActive(item.link) ? 650 : 400,
                            color: getNavItemActive(item.link)
                              ? "#FFF"
                              : "#000",
                            backgroundColor: getNavItemActive(item.link)
                              ? theme.palette.menuColor.main
                              : "transparent",
                            "&:hover": {
                              color: "#FFF",
                            },
                            "&:active": {
                              color: "#FFF",
                            },
                            "&:focus": {
                              color: "#FFF",
                            },
                          }}
                        >
                          {item.icon}
                          <span style={{ minWidth: "60px" }}>{item.name}</span>
                        </MenuButton>
                      )}
                      {item?.secundary?.map((subMenu) =>
                        /* subMenu.rol && subMenu.rol === rolUser */
                        optionsHeader?.find(opt=>opt.Name === subMenu?.rol) ? (
                          <Box
                            key={index+'1'}
                            style={{
                              borderBottomColor: theme.palette.menuColor.main,
                              marginLeft: "0.5em",
                            }}
                            borderBottom={
                              getNavItemActive(subMenu.link) ? 2 : 0
                            }
                          >
                            <MenuButton
                              key={index+'2'}
                              disabled={subMenu.name}
                              component={RouterLink}
                              to={subMenu.link}
                              onClick={() => {
                                console.log("subMenu::", subMenu);
                                openWindowModalReport(subMenu.name);
                              }}
                              sx={{
                                display: "flex",
                                justifyContent: "left",
                                alignContent: "center",
                                alignItems: "center",
                                textAlign: "left",
                                // marginLeft: '1em',
                                fontWeight: 400,
                                color: "#000",
                                backgroundColor: "transparent",
                                textDecoration: "none !important",
                                borderBottom: "none",
                                paddingLeft: "1em",
                                "&:hover": {
                                  color: "#FFF",
                                },
                                "&:active": {
                                  color: "#FFF",
                                },
                                "&:focus": {
                                  color: "#FFF",
                                },
                              }}
                            >
                              <span
                                style={{
                                  minWidth: "60px",
                                  textDecoration: "none !important",
                                }}
                              >
                                {subMenu.name}
                              </span>
                            </MenuButton>
                          </Box>
                        ) : null
                      )}
                      {item?.optionsSecundary?.map((subMenu) => (
                        <Box
                          style={{
                            borderBottomColor: theme.palette.menuColor.main,
                            marginLeft: "1.5em",
                          }}
                          borderBottom={getNavItemActive(subMenu.link) ? 2 : 0}
                        >
                          <MenuButton
                            key={index}
                            // disabled={subMenu.name}
                            component={RouterLink}
                            to={subMenu.link}
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              alignContent: "center",
                              alignItems: "center",
                              textAlign: "left",
                              // marginLeft: '1em',
                              fontWeight: 400,
                              color: "#000",
                              backgroundColor: "transparent",
                              textDecoration: "none !important",
                              borderBottom: "none",
                              "&:hover": {
                                color: "#FFF",
                              },
                              "&:active": {
                                color: "#FFF",
                              },
                              "&:focus": {
                                color: "#FFF",
                              },
                            }}
                          >
                            <span style={{ minWidth: "60px" }}>
                              {subMenu.name}
                            </span>
                          </MenuButton>
                        </Box>
                      ))}
                    </div>
                  );
                }
              })}
            </Box>
          </Hidden>
        </Box>
      </Container>
    </AppBar>
  );
};
