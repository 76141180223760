import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const documentRequests = {}
function getType(type) {
  switch (type) {
    case '.pdf':
      return 'application/pdf'
    case '.mp4':
      return 'video/mp4'
    case '.webm':
      return 'video/mp4'
    case '.jpg':
      return 'image/jpeg'
    case '.mp3':
      return 'audio/mp3'
    default:
      return '.pdf'
  }
}

documentRequests.GetDocument = (data, ext = null) => {
  return axios.get(
    AppUrls.GetDocument + "?document_Id=" + data + "&&Authorization=" + Config.getTokenCurrentUser(),
    Config.ConfigNoAuthDoc()
  ).then((res) => handleResponse(res, ext))
}

documentRequests.GetBioDocument = (data, ext = null) => {
  return axios.get(
    AppUrls.GetBioDocument + "?Id=" + data + "&&Authorization=" + Config.getTokenCurrentUser(),
    Config.ConfigNoAuthDoc()
  ).then((res) => handleResponse(res, '.mp4'))
}

documentRequests.GetBioDocumentVersion = (data, ext = null) => {
  return axios.get(
    AppUrls.GetBioDocumentVersion + "?Id=" + data + "&&Authorization=" + Config.getTokenCurrentUser(),
    Config.ConfigNoAuthDoc()
  ).then((res) => handleResponse(res, '.mp4'))
}

let handleResponse = (response, ext = null) => {
  //Se obtiene el tipo de archivo
  console.log("*******response document: ******: ", response)
  // const headerval = response.headers['content-disposition'];
  const headerval = ext;
  console.log("headerval: ", headerval)
  let extFile = '';
  if (headerval === undefined || headerval === null) {
    extFile = '.pdf'
  } else {
    if (headerval.includes("?utf")) {
      extFile = '.pdf'
    } else {
      // extFile = '.' + headerval.split('.')[1].substring(0, 3);
      extFile = '.' + headerval.split('.')[1]/* .substring(0, 4) */;
    }
  }
  //Create a Blob from the PDF Stream
  const file = new Blob(
    [response.data],
    //{ type: response.data.type }
    { type: getType(extFile) }
  );//Build a URL from the file
  const fileURL = URL.createObjectURL(file);//Open the URL on new Window
console.log('extension:::',extFile)
  let respObject = {
    data: fileURL,
    url: response.config.url,
    type: getType(extFile),
    extension: extFile
  };

  if (response.status !== 200 &&
    response.statusText !== "OK") {
    let responseObject = { message: response.Messages + ' ' + (response.TansactionId ? response.TansactionId : "") + ' ' + (response.statusText ? response.statusText : "") };
    const error = (respObject.Body || responseObject);
    console.log('throw error')
    return Promise.reject(error);
  } else {
    console.log('response good: ', respObject)
    return respObject;
  }
}

documentRequests.getDocumentInfo = (data) => {
  return axios.post(
    AppUrls.GetDocumentInfo,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.DownloadDocument = (data) => {
  return axios.post(
    AppUrls.DownloadDocument,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.UpdateMetaData = (data) => {
  return axios.post(
    AppUrls.UpdateMetadata,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.validateDocument = (data) => {
  return axios.post(
    AppUrls.ValidateDocument,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.CargaDocumentoWithSection = (data) => {
  return axios.post(
    AppUrls.CargaDocumentoWithSection,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.CargarSeccion = (data) => {
  return axios.post(
    AppUrls.UploadSectionApi,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.RegisterMetadata = (data) => {
  return axios.post(
    AppUrls.RegisterMetadata,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.GetOcrData = (data) => {
  return axios.post(
    AppUrls.GetOcrData,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

documentRequests.GetDocumentsGroupByRol = (data) => {
  return axios.post(
    AppUrls.GetDocumentsGroupByRol,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponsePlainObject)
}

let handleResponsePlainObject = (response) => {
  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  return respObject;
}