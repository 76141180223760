export function getGenericControls(data) {
    // console.log("itemCtrl: --data***********- ", data)
    if (data?.QuestionDefinitions?.length > 0) {
    // if (data?.questionDefinitions?.length > 0) {
        // console.log("itemCtrl: --data.questionDefinitions***********- ", data.QuestionDefinitions)
        return data.QuestionDefinitions.map((itemCtrl) => {
        // return data.questionDefinitions.map((itemCtrl) => {
            // console.log("itemCtrl: --- ", itemCtrl)
            let listOptions = (itemCtrl.Options?.length <= 0 ? [] :
                itemCtrl.Options.map((item, index) => {
                    return {
                        id: ctrlGenerateName(itemCtrl.QuestionType, index),
                        label: item.Option,
                        value: item.Value,
                        required: true
                    }
                })
            )

            return {
                id: ctrlGenerateName(itemCtrl.QuestionType, itemCtrl.Id),
                name: ctrlGenerateName(itemCtrl.QuestionType, itemCtrl.Id),
                label: itemCtrl.Description,
                type: itemCtrl.QuestionType,
                groupName: itemCtrl.QuestionGroup,
                required: itemCtrl.Required,
                Options: (itemCtrl.Options?.length <= 0 ? [] : listOptions)
            }
        })
    } else {
        return []
    }
}

function ctrlGenerateName(opt, ctrlId) {
    switch (opt) {
        case 'Texto':
            return 'txt' + ctrlId.toString()
        case 'Combo':
            return 'cmb' + ctrlId.toString()
        case 'Radio':
            return 'rdo' + ctrlId.toString()
        case 'Phone':
            return 'pne' + ctrlId.toString()
        case 'Email':
            return 'eml' + ctrlId.toString()
        case 'Password':
            return 'pwr' + ctrlId.toString()
        case 'Checkbox':
            return 'chk' + ctrlId.toString()
        case 'CAPTCHA':
            return 'cpt' + ctrlId.toString()
        default:
            return 'IdGen' + ctrlId.toString()
    }
}
