import { Button, Grid, Typography, Box, styled } from "@mui/material";
import { Link } from "react-router-dom";

export const Buttons = (props) => {
  return (
    <Grid container spacing={2} justifyContent="center" sx={{ mt: `${props.marginTop == undefined ? 10 : props.marginTop}px`, mb: `${props.marginBottom == undefined ? 1 : props.marginBottom}px` }}>
      <Grid item xs={12} sm={8} sx={{ marginTop: '2px', textAlign: 'center', paddingTop: '2px !important', zIndex: 100 }}>
        <Button
          fullWidth
          variant="contained"
          sx={{ height: 45, px: 10, borderRadius: '0px', '&:hover': { backgroundColor: '#AF000D' }, textTransform: "none !important" }}
          onClick={props.onClickConfirm}
          disabled={props.disabledConfirm}
        >
          {props.ConfirmButtonText == undefined ? "Continuar" : props.ConfirmButtonText}
        </Button>
      </Grid>
      {props.onClickCancel != undefined ?
        <Grid item xs={12} sm={8} sx={{ marginTop: '2px', textAlign: 'center', paddingTop: '2px !important', zIndex: 100 }}>
          <Button
            fullWidth
            variant="outlined"
            sx={{ height: 45, px: 10, borderRadius: '0px', color: '#333333', borderColor: '#333333', '&:hover': { backgroundColor: '#F3F3F3', borderColor: '#333333' }, textTransform: "none !important" }}
            onClick={props.onClickCancel}
            disabled={props.disabledCancel}
          >
            {props.CancelButtonText == undefined ? "Cancelar" : props.CancelButtonText}
          </Button>
        </Grid> : null
      }
    </Grid>
  )
};

export const LinkButton = (props) => {
  return (
    <Box sx={{ p: 1, textAlign: props.textAlign == undefined ? "right" : props.textAlign, width: '90%', zIndex: 100, mb: `${props.marginBottom == undefined ? 1 : props.marginBottom}px` }}>
      <Link
        style={{ textDecoration: "none" }}
        to={props.LinkButtonUrl}>
        <Typography variant="body2" color="primary" sx={{ width: "100%", textAlign: "center" }} >
          {props.LinkButtonText}
        </Typography>
      </Link>
    </Box>
  )
};

export const ButtonsLined = (props) => {
  return (
    <Grid container spacing={2} justifyContent="center" sx={{ mt: `${props.marginTop == undefined ? 10 : props.marginTop}px`, mb: `${props.marginBottom == undefined ? 1 : props.marginBottom}px` }}>
      <Grid item xs={6} sm={6} sx={{ zIndex: 100 }}>
        <Button
          fullWidth
          variant="contained"
          sx={{ height: 35, px: 5 }}
          onClick={props.onClickConfirm}
          disabled={props.disabledConfirm}
        >
          {props.ConfirmButtonText == undefined ? "Continuar" : props.ConfirmButtonText}
        </Button>
      </Grid>
      {props.onClickCancel != undefined ?
        <Grid item xs={6} sm={6} sx={{ zIndex: 100 }}>
          <Button
            fullWidth
            variant="outlined"
            sx={{ height: 35, px: 5 }}
            onClick={props.onClickCancel}
            disabled={props.disabledCancel}
          >
            {props.CancelButtonText == undefined ? "Cancelar" : props.CancelButtonText}
          </Button>
        </Grid> : null
      }
    </Grid>
  )
};

export const GenericButtonList = styled(Box)(({ theme }) => ({
  width: '100% !important',
  padding: '16px',
  gap: '16px',
  minHeight: '56px',
  background: theme.palette.primary.lightContrast,
  border: `1px solid ${theme.palette.primary.contrastText}`,
  borderRadius: '0px',
  marginTop: '16px',
  '&:focus': {
    outline: 'none !important',
    background: theme.palette.primary.main80,
    border: `1px solid ${theme.palette.primary.main80}`,
  },
  '&:hover': {
    outline: 'none !important',
    background: theme.palette.primary.main60,
    border: `1px solid ${theme.palette.primary.main60}`,
  },
}));
