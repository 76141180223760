import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const userManagerRequests = {}

userManagerRequests.GetRolesMC = (data) => {
  return axios.post(
    AppUrls.GetRolesMC,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

userManagerRequests.GetProductUserMC = (data) => {
  return axios.post(
    AppUrls.GetProductUserMC,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

userManagerRequests.RegisterUserMC = (data) => {
  return axios.post(
    AppUrls.RegisterUserMC,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

userManagerRequests.UpdateUserMC = (data) => {
  return axios.post(
    AppUrls.UpdateUserMC,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

userManagerRequests.GetUsersMC = (data) => {
  return axios.post(
    AppUrls.GetUsersMC,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

let handleResponse = (response) => {
  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Body || responseObject);
    return Promise.reject(error);
  }
  return respObject;
}