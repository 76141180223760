import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fileMasiveActions, validationActions } from '../../store/actions'

import {
    TextField,
    Button,
    InputAdornment,
    CircularProgress,
    Typography,
    Grid, useTheme
} from '@mui/material'
import NoResults from '../../assets/NoResultMeet.png'
import {
    UseImage,
    CustomLoadingOverlay, GenericHeaderActions
} from '../../components'

import { useStyles } from '../home/HomeStyles'
import { mainStyles } from '../../theme/mainStyles'
import { Icon } from '@iconify/react';
import TableGroups from './TableGroups'
import TableUsersGroup from './TableUsersGroup'

export default function HomeMasive() {
    const theme = useTheme()
    const localStyles = useStyles(theme)
    const noInfo = mainStyles.noDataSections()
    const [searching, setSearching] = useState("")
    const [search, setSearch] = useState("")
    const [infoItems, setInfoItems] = useState([])
    const [dataGroup, setDataGroup] = useState()

    const info = useSelector(state => state.GetByUsuarioRolBusquedaPortalGrupos)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fileMasiveActions.GetByUsuarioRolBusquedaPortalGrupos({ "SearchText": search }))
        dispatch(validationActions.PostFileValidation({}))
    }, [])

    useEffect(() => {
        dispatch(fileMasiveActions.GetByUsuarioRolBusquedaPortalGrupos({ "SearchText": search }))
    }, [search])

    useEffect(() => {
        if (info.items !== undefined) {
            setInfoItems(info.items)
        }
    }, [info])

    return (
        <div>
            {/* <TableUsersSigners /> */}
            {dataGroup !== undefined ? (
                <TableUsersGroup dataGroup={dataGroup} setDataGroup={setDataGroup} />
            ) : (
                <div>
                    <form className={localStyles.form} >
                        <GenericHeaderActions onChangeTextField={(event) => {
                            setSearching(event.target.value)
                        }}
                            placeholderTextField="Busca por Asegurado, Beneficiario, Póliza o No. de reclamación"
                            onClickConfirm={(event) => {
                                event.preventDefault()
                                setSearch(searching)
                            }}
                            ConfirmButtonText="Buscar"
                            CancelButtonText="Limpiar búsqueda"
                            onClickCancel={() => { setSearch("") }} />
                    </form>
                    {info.error && <p>{"Error de red favor de actualizar la pagina"}</p>}
                    {
                        info.loading ?
                            <div className={localStyles.spinSection}>
                                <CustomLoadingOverlay
                                    active={info.loading} text="Espera un momento por favor..."
                                />
                            </div> : infoItems.length > 0 ?
                                <TableGroups data={infoItems} setDataGroup={setDataGroup} />
                                : <div className={noInfo.noResult}>
                                    <UseImage src={NoResults} type='img' style={{ maxWidth: 300 }} />
                                    <Typography variant="h6" className={noInfo.h6}>No se encontraron solicitudes</Typography>
                                </div>
                    }
                </div >)
            }
        </div >)
}
