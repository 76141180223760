import React from 'react'
import {
    Typography,
    Dialog,
} from '@mui/material';

import { customHelpDialogStyles } from './ModalStyles'

export const FrameModal= (props) => {
    const classes = customHelpDialogStyles()

    return (
        <Dialog onClose={props.closeAction}
            open={props.open} className={classes.ContainerDialog} >
            <div className={classes.leftColumnDialog}></div>
            <div className={classes.CenterDialog}>
                <div className={classes.centerContentDialog}>
                    {props.title !== undefined && props.title !== "" ?
                        <Typography
                            variant="h6"
                            gutterBottom
                            className={classes.title}>{props.title}</Typography>
                        : null
                    }
                    {props.body}
                </div>
            </div>
            <div className={classes.rightColumnDialog}></div>
        </Dialog >
    )
}