import React, { useState, useEffect } from 'react'
import { UseImage } from '../index';
import { docRequest } from '../../utils';

import {
    Typography,
    Card,
    Button, useTheme,
    Stack
} from '@mui/material';

import printOk from '../../assets/printOk.svg'
import printNoOk from '../../assets/printNoOk.svg'
import noPrint from '../../assets/noPrint.svg'
import MovilImage from '../../assets/Movil.png'
import PCImage from '../../assets/PC.png'
import RefreshIcon from '@mui/icons-material/Refresh';

import { useStyles } from './TabsStyle'
import { mainStyles } from '../../theme/mainStyles'
import { BioVisual, MiniBioVisual } from '../modals';
import failFacial from '../../assets/icon_fail_facial.svg'
import doneFacial from '../../assets/icon_done_facial.svg'


export function Biometrics({ bioData, selfArray, setBioDispatch, docMetAdic }) {
    const theme = useTheme()
    const localStyles = useStyles(theme)
    const cardStyle = mainStyles?.cards()

    const [idPercentage, setIdPercentage] = useState('')
    const [videoPercentage, setVideoPercentage] = useState('')
    const [audioPercentage, setAudioPercentage] = useState('')

    const [idDate, setIdDate] = useState('')
    const [videoDate, setVideoDate] = useState('')
    const [audioDate, setAudioDate] = useState('')

    const [idMatch, setIdMatch] = useState('')
    const [videoText, setVideoText] = useState('')
    const [audioText, setAudioText] = useState('')
    const [videoReadText, setVideoReadText] = useState('')
    const [audioReadText, setAudioReadText] = useState('')

    const [videoFrames, setVideoFrames] = useState([])
    const [selfieDocs, setSelfieDocs] = useState([])

    const setFrames = async (arrayList) => {
        setVideoFrames([])
        const frameIterancy = arrayList?.map(async (frame) => {
            console?.log('frame:::', frame)
            return await docRequest(frame?.DocumentSection_Id, frame?.SimilarityPersentage, frame?.ComparisonDate, frame?.SimilarityResult, frame?.SnapshotTime)
        })

        console?.log('frameIterancy:::', frameIterancy)
        return await Promise?.all(frameIterancy)
    }

    const setSelfieInfo = async (arrayList) => {
        setSelfieDocs([])

        const selfArray = arrayList?.map(async (doc) => {
            return await docRequest(doc)
        })
        return await Promise?.all(selfArray)
    }

    useEffect(() => {

        if (bioData?.Face?.Selfie !== null) {

            setSelfieInfo(selfArray)?.then(response => setSelfieDocs(response))

            if (bioData?.Face?.Selfie?.ComparisonVsVideo !== null && bioData?.Face?.Selfie?.ComparisonVsVideo?.ComparisonVsVideoFrame?.length > 0) {
                setFrames(bioData?.Face?.Selfie?.ComparisonVsVideo?.ComparisonVsVideoFrame)?.then(response => { console?.log('RESPONSE BIO:', response); setVideoFrames(response) })
            }

            if (bioData?.Face?.Selfie?.ComparisonVsIDEFront?.SimilarityPersentage !== null) {
                setIdPercentage(bioData?.Face?.Selfie?.ComparisonVsIDEFront?.SimilarityPersentage?.substring(0, 6))
            } else {
                setIdPercentage('0')
            }
            if (bioData?.Face?.Selfie?.ComparisonVsIDEFront?.ComparisonDate !== null) {
                // setIdDate(bioData?.Face?.Selfie?.ComparisonVsIDEFront?.ComparisonDate?.split(' ')[0])
                setIdDate(bioData?.Face?.Selfie?.ComparisonVsIDEFront?.ComparisonDate)
            } else {
                setIdDate('sin fecha')
            }
            if (bioData?.Face?.Selfie?.ComparisonVsIDEFront?.SimilarityResult !== null) {
                setIdMatch(bioData?.Face?.Selfie?.ComparisonVsIDEFront?.SimilarityResult)
            } else {
                setIdMatch('Sin similitud')
            }

        }

        if (bioData?.SpeechToText?.Audio !== null && bioData?.SpeechToText?.Audio?.SimilarityPersentage !== null) {
            setAudioPercentage(bioData?.SpeechToText?.Audio?.SimilarityPersentage?.substring(0, 6))
        } else {
            setAudioPercentage('0')
        }
        if (bioData?.SpeechToText?.Audio !== null && bioData?.SpeechToText?.Audio?.ComparisonDate !== null) {
            setAudioDate(bioData?.SpeechToText?.Audio?.ComparisonDate)
        } else {
            setAudioDate('sin Fecha')
        }
        if (bioData?.SpeechToText?.Audio !== null && bioData?.SpeechToText?.Audio?.TextToRead !== null) {
            setAudioText(bioData?.SpeechToText?.Audio?.TextToRead)
        }
        if (bioData?.SpeechToText?.Audio !== null && bioData?.SpeechToText?.Audio?.ReadText !== null) {
            setAudioReadText(bioData?.SpeechToText?.Audio?.ReadText)
        }

        if (bioData?.SpeechToText?.Video !== null && bioData?.SpeechToText?.Video?.SimilarityPersentage !== null) {
            setVideoPercentage(bioData?.SpeechToText?.Video?.SimilarityPersentage?.substring(0, 6))
        } else {
            setVideoPercentage('0')
        }
        if (bioData?.SpeechToText?.Video !== null && bioData?.SpeechToText?.Video?.ComparisonDate !== null) {
            setVideoDate(bioData?.SpeechToText?.Video?.ComparisonDate)
        } else {
            setVideoDate('sin Fecha')
        }
        if (bioData?.SpeechToText?.Video !== null && bioData?.SpeechToText?.Video?.TextToRead !== null) {
            setVideoText(bioData?.SpeechToText?.Video?.TextToRead)
        }
        if (bioData?.SpeechToText?.Video !== null && bioData?.SpeechToText?.Video?.ReadText !== null) {
            setVideoReadText(bioData?.SpeechToText?.Video?.ReadText)
        }

        console?.log('biodata', bioData)

    }, [bioData])

    console?.log('bioData?.Selfie?.ComparisonVsVideo?.OriginDevice::', bioData?.Selfie)


    return (
        <div className={localStyles?.biometricScroll}>
            {bioData?.Face?.Selfie !== null && selfieDocs?.length !== 0
                ? <div>
                    <div className={localStyles?.biometricTitle}>
                        <Typography variant="p" className={localStyles?.title}>
                            Reconocimiento facial&nbsp;
                            {/* nbsp es para colocar un espacio  */}
                            {bioData?.Face?.Selfie?.ComparisonVsIDEFront?.Approved
                                ? <span className={localStyles?.approved}>- Aprobado</span>
                                : bioData?.Face?.Selfie?.ComparisonVsIDEFront?.Approved === false
                                    ? <span className={localStyles?.rejected}>- Rechazado</span>
                                    : ''}
                        </Typography>
                        <Button
                            startIcon={< RefreshIcon />}
                            variant="contained"
                            sx={{ height: 45, px: 10, maxWidth: 300 }}
                            onClick={setBioDispatch} >
                            Actualizar
                        </Button>
                    </div>
                    {selfieDocs?.length !== 0 &&
                        /* ? <p>Cargando ...</p>
                        :  */<div className={localStyles?.mainResultSection}>
                            {selfieDocs?.map((doc, index) => {
                                if (index === 0) {
                                    return <MiniBioVisual cardName='Selfie' showDoc={doc?.data} />
                                } else {
                                    return <MiniBioVisual cardName='Identificación'
                                        cardPercentage={idPercentage}
                                        showDoc={doc?.data}
                                        cardDate={idDate}
                                        cardMatch={idMatch}
                                        metAdic={docMetAdic} />
                                }
                            })}
                        </div>
                    }



                </div>
                : ''}

            {bioData?.SpeechToText?.Audio !== null || bioData?.SpeechToText?.Video !== null
                ? <div>
                    <Typography variant="p" className={localStyles?.title}>
                        Videograbación / Audio&nbsp;
                        {bioData?.SpeechToText?.Approved
                            ? <span className={localStyles?.approved}>- Aprobado</span>
                            : bioData?.SpeechToText?.Approved === false
                                ? <span className={localStyles?.rejected}>- Rechazado</span>
                                : ''}
                    </Typography>
                    <div className={localStyles?.mainResultSection}>
                        {bioData?.SpeechToText?.Audio !== null
                            ? <BioVisual 
                                cardName='Audio'
                                docVersionId={null}
                                docId={bioData?.SpeechToText?.Audio?.DocumentSection_Id}
                                cardPercentage={audioPercentage}
                                cardDate={audioDate}
                                docText={audioText}
                                readText={audioReadText} />
                            : ''}

                        {bioData?.SpeechToText?.Video !== null
                            && bioData?.SpeechToText?.Video?.AttemptsData?.map((attemp, index) => (
                                <BioVisual
                                    key={index+'1'}
                                    cardName={'Videograbación ' + (index + 1)}
                                    docVersionId={attemp?.DocumentSectionVersion_Id}
                                    docId={ attemp?.DocumentSection_Id}
                                    cardPercentage={attemp?.SimilarityPersentage?.substring(0, 6)}
                                    cardDate={attemp?.ComparisonDate}
                                    docText={attemp?.TextToRead}
                                    readText={attemp?.ReadText}
                                    statusVideo={attemp?.Approved ? "Aprobado" : "Rechazado"} />
                            ))

                        }
                    </div>
                </div>
                : ''
            }

            {bioData?.Face?.Selfie !== null && bioData?.Face?.Selfie?.ComparisonVsVideo !== null && bioData?.Face?.Selfie?.ComparisonVsVideo?.ComparisonVsVideoFrame?.length > 0 && videoFrames?.length !== 0
                ? <div>
                    <Typography variant="p" className={localStyles?.title}>
                        Frames de video&nbsp;
                        {bioData?.Face?.Selfie?.ComparisonVsVideo?.Approved
                            ? <span className={localStyles?.approved}>- Aprobado</span>
                            : bioData?.Face?.Selfie?.ComparisonVsVideo?.Approved === false
                                ? <span className={localStyles?.rejected}>- Rechazado</span>
                                : ''}
                    </Typography>
                    {videoFrames?.length !== 0
                        /* ? <p>Cargando ...</p>
                        : */
                        && <div className={localStyles?.mainResultSection}>
                            {videoFrames?.map((frame, index) => {
                                console?.log('FRAME VIDEO: ', frame)

                                return /* frame?.match != null ?  */<MiniBioVisual
                                    cardName={'Video frame ' + (index + 1)}
                                    cardPercentage={frame?.match?.substring(0, 6)}
                                    cardMatch={frame?.similarity}
                                    cardDate={frame?.date?.split(' ')?.[0]}
                                    showDoc={frame?.data}
                                    snapShot={frame?.spnapShot}
                                    metAdic={docMetAdic} />
                                /* : <></> */
                            })}
                        </div>}

                </div>
                : ''
            }

            {bioData?.Fingerprints?.Fingerprint2 !== null || bioData?.Fingerprints?.Fingerprint7 !== null
                ?
                <div>
                    <Typography variant="p" className={localStyles?.title}>
                        Biometría dactilar y/o facial&nbsp;
                        {bioData?.Fingerprints?.Approved || bioData?.FacialIne?.Approved
                            ? <span className={localStyles?.approved}>- Aprobado</span>
                            : bioData?.Fingerprints?.Approved === false || bioData?.FacialIne?.Approved === false
                                ? <span className={localStyles?.rejected}>- Rechazado</span>
                                : ''}
                    </Typography>
                    <div className={localStyles?.mainResultSection}>
                        {bioData?.Fingerprints?.Fingerprint7 !== null &&
                            <Card className={cardStyle?.biometricCard}>
                                <Typography variant="p" className={cardStyle?.titleCard}>Huella dactilar índice izquierdo</Typography>

                                {bioData?.Fingerprints?.Fingerprint7?.SimilarityResult === 'True'
                                    ? <div className={localStyles?.dactilarSection}>
                                        <UseImage src={printOk} className={localStyles?.dactilarImg} type='img' />
                                        <Typography variant="p" className={cardStyle?.cardMatch}>{`Similitud de minucias ${bioData?.Fingerprints?.Fingerprint7?.SimilarityPersentage === undefined || bioData?.Fingerprints?.Fingerprint7?.SimilarityPersentage === null ? 0 : bioData?.Fingerprints?.Fingerprint7?.SimilarityPersentage?.substring(0, 6)}%`}</Typography>
                                    </div>
                                    : bioData?.Fingerprints?.Fingerprint7?.SimilarityResult === 'False' && bioData?.Fingerprints?.Fingerprint7?.ReasonForOmission === null
                                        ? <div className={localStyles?.dactilarSection}>
                                            <UseImage src={printNoOk} className={localStyles?.dactilarImg} type='img' />
                                            <Typography variant="p" className={cardStyle?.cardMatch}>{`Similitud de minucias ${bioData?.Fingerprints?.Fingerprint7?.SimilarityPersentage === undefined || bioData?.Fingerprints?.Fingerprint7?.SimilarityPersentage === null ? 0 : bioData?.Fingerprints?.Fingerprint7?.SimilarityPersentage?.substring(0, 6)}%`}</Typography>
                                        </div>
                                        : bioData?.Fingerprints?.Fingerprint7?.SimilarityResult === 'False' && bioData?.Fingerprints?.Fingerprint7?.ReasonForOmission !== null
                                            ? <div className={localStyles?.dactilarSection}>
                                                <UseImage src={noPrint} className={localStyles?.dactilarImg} type='img' />
                                                <Typography variant="p" className={cardStyle?.cardMatch}>{bioData?.Fingerprints?.Fingerprint7?.ReasonForOmission}</Typography>
                                            </div>
                                            : <div className={localStyles?.dactilarSection}>
                                                <UseImage src={noPrint} className={localStyles?.dactilarImg} type='img' />
                                                <Typography variant="p" className={cardStyle?.cardMatch}></Typography>
                                            </div>
                                }

                            </Card>
                        }

                        {bioData?.Fingerprints?.Fingerprint2 !== null &&
                            <Card className={cardStyle?.biometricCard}>
                                <Typography variant="p" className={cardStyle?.titleCard}>Huella dactilar índice derecho</Typography>
                                {bioData?.Fingerprints?.Fingerprint2?.SimilarityResult === 'True'
                                    ? <div className={localStyles?.dactilarSection}>
                                        <UseImage src={printOk} className={localStyles?.dactilarImg} type='img' />
                                        <Typography variant="p" className={cardStyle?.cardMatch}>{`Similitud de minucias  ${bioData?.Fingerprints?.Fingerprint2?.SimilarityPersentage === undefined || bioData?.Fingerprints?.Fingerprint2?.SimilarityPersentage === null ? 0 : bioData?.Fingerprints?.Fingerprint2?.SimilarityPersentage?.substring(0, 6)}%`}</Typography>
                                    </div>
                                    : bioData?.Fingerprints?.Fingerprint2?.SimilarityResult === 'False' && bioData?.Fingerprints?.Fingerprint2?.ReasonForOmission === null
                                        ? <div className={localStyles?.dactilarSection}>
                                            <UseImage src={printNoOk} className={localStyles?.dactilarImg} type='img' />
                                            <Typography variant="p" className={cardStyle?.cardMatch}>{`Similitud de minucias  ${bioData?.Fingerprints?.Fingerprint2?.SimilarityPersentage === undefined || bioData?.Fingerprints?.Fingerprint2?.SimilarityPersentage === null ? 0 : bioData?.Fingerprints?.Fingerprint2?.SimilarityPersentage?.substring(0, 6)}%`}</Typography>
                                        </div>
                                        : bioData?.Fingerprints?.Fingerprint2?.SimilarityResult === 'False' && bioData?.Fingerprints?.Fingerprint2?.ReasonForOmission !== null
                                            ? <div className={localStyles?.dactilarSection}>
                                                <UseImage src={noPrint} className={localStyles?.dactilarImg} type='img' />
                                                <Typography variant="p" className={cardStyle?.cardMatch}>{bioData?.Fingerprints?.Fingerprint2?.ReasonForOmission}</Typography>
                                            </div>
                                            : <div className={localStyles?.dactilarSection}>
                                                <UseImage src={noPrint} className={localStyles?.dactilarImg} type='img' />
                                                <Typography variant="p" className={cardStyle?.cardMatch}></Typography>
                                            </div>
                                }
                            </Card>
                        }

                        {bioData?.FacialIne !== null &&
                            <Card className={cardStyle?.biometricCard}>
                                <Typography variant="p" className={cardStyle?.titleCard}>Rostro</Typography>

                                {bioData?.FacialIne?.SimilarityResult === 'True'
                                    ? <div className={localStyles?.dactilarSection}>
                                        <UseImage src={doneFacial} className={localStyles?.dactilarImg} type='img' />
                                        <Typography variant="p" className={cardStyle?.cardMatch}>{`Similitud ${bioData?.FacialIne?.SimilarityPersentage === undefined || bioData?.FacialIne?.SimilarityPersentage === null ? 0 : bioData?.FacialIne?.SimilarityPersentage?.substring(0, 6)}%`}</Typography>
                                    </div>
                                    : bioData?.FacialIne?.SimilarityResult === 'False'
                                        ? <div className={localStyles?.dactilarSection}>
                                            <UseImage src={failFacial} className={localStyles?.dactilarImg} type='img' />
                                            <Typography variant="p" className={cardStyle?.cardMatch}>{`Similitud ${bioData?.FacialIne?.SimilarityPersentage === undefined || bioData?.FacialIne?.SimilarityPersentage === null ? 0 : bioData?.FacialIne?.SimilarityPersentage?.substring(0, 6)}%`}</Typography>
                                        </div>
                                        : bioData?.FacialIne?.SimilarityResult === 'False'
                                            ? <div className={localStyles?.dactilarSection}>
                                                <UseImage src={failFacial} className={localStyles?.dactilarImg} type='img' />
                                                <Typography variant="p" className={cardStyle?.cardMatch}>{`Similitud ${bioData?.FacialIne?.SimilarityPersentage === undefined || bioData?.FacialIne?.SimilarityPersentage === null ? 0 : bioData?.FacialIne?.SimilarityPersentage?.substring(0, 6)}%`}</Typography>
                                            </div>
                                            : <div className={localStyles?.dactilarSection}>
                                                <UseImage src={failFacial} className={localStyles?.dactilarImg} type='img' />
                                                <Typography variant="p" className={cardStyle?.cardMatch}></Typography>
                                            </div>
                                }

                            </Card>
                        }
                    </div>
                </div>
                : ''
            }

            {bioData?.Face?.Selfie?.ComparisonVsVideo?.OriginDevice &&
                <div>
                    <Typography variant="p" className={localStyles?.title}>
                        Dispositivo que usó el asegurado&nbsp;
                    </Typography>

                    <div className={localStyles?.mainResultSection}>
                        <DeviceCard type={bioData?.Face?.Selfie?.ComparisonVsVideo?.OriginDevice} />
                    </div>

                </div>}
        </div>
    )
}

const DeviceCard = ({ type }) => {

    const theme = useTheme()
    const cardStyle = mainStyles?.cards()
    const localStyles = useStyles(theme)
    return <Card className={cardStyle?.biometricCard}>
        <Typography variant="p" className={cardStyle?.titleCard}>Dispositivo</Typography>
        <img src={type === "PC" ? PCImage : MovilImage} /* lassName={localStyles?.miniImg} */ style={{ width: '5rem' }} />
        <Typography variant="p" className={cardStyle?.cardMatch}>{type}&nbsp;</Typography>
    </Card>
}