import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Tooltip,
  MenuItem,
  ListItemText,
  tooltipClasses,
  TextField as InputText,
  FormControlLabel,
  Checkbox,
  Box,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  Typography,
  autocompleteClasses,
  Radio as Rd,
  RadioGroup,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import esLocale from "date-fns/locale/es";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Controller } from "react-hook-form";
import { IMaskInput } from "react-imask";
import { DatePicker as DPick } from "@mui/x-date-pickers";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Parser from "html-react-parser";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from "@mui/styles";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { NumericFormat, PatternFormat } from "react-number-format";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import EyeIcon from "../assets/EyeIcon.svg";
import Eye_close from "../assets/Eye_close.svg";
import CalendarIcon from "../assets/CalendarIcon.svg";
import DropdownIcon from "../assets/Drop&downIcon.svg";

import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en-gb";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { MobileTimePicker as TmPk } from "@mui/x-date-pickers/MobileTimePicker";
import { esES } from "@mui/x-date-pickers/locales";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { es } from "date-fns/locale";
import { UseImage } from "./UseImage";

const themeDatePicker = createTheme({
  palette: {
    primary: {
      main: "#000", // Color principal
      dark: "#000000",
      light: "#f0f0f0",
    },
    secondary: {
      main: "#00ff00", // Color secundario
    },
    background: {
      default: "#f0f0f0", // Color de fondo
    },
  },
});

const themeSelect = createTheme({
  palette: {
    primary: {
      main: "#000", // Color principal
      dark: "#000000",
      light: "#f0f0f0",
    },
    secondary: {
      main: "#00ff00", // Color secundario
    },
    background: {
      default: "#f0f0f0", // Color de fondo
    },
  },
});

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    backgroundColor: "red",
  },
}));

const DatePicker = styled(DPick)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    fontSize: 14,
    width: "100%",
    backgroundColor: "white", // Color de fondo predeterminado
    "& fieldset legend span": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      borderRadius: 0,
    },
    "&:focus-within fieldset": {
      borderColor: "black", // Cambia el color del contorno al hacer focus
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    backgroundColor: "#F9F2F3", // Color de fondo cuando hay error
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B", // Color del borde cuando hay error
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#000 !important",
  },
  "& .MuiInputLabel-root.Mui-error": {
    color: "#000 !important",
  },
  "& .MuiFormLabel-root.Mui-error": {
    color: "#000 !important",
  },
}));

const MobileTimePicker = styled(TmPk)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      // height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    fontSize: 14,
    width: "100%",
    "& fieldset legend span": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      borderRadius: 0,
    },
    "&:focus-within fieldset": {
      borderColor: "black ", // Cambia el color del contorno al hacer focus
    },
  },

  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B",
    backgroundColor: "#F9F2F3",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    // color: '#A8000B',
    color: "#000 !important",
  },
  "& .MuiInputLabel-root.Mui-error": {
    // color: '#A8000B',
    color: "#000 !important",
  },
  "& .MuiFormLabel-root.Mui-error": {
    // color: '#A8000B', // Color de la etiqueta en caso de error
    color: "#000 !important",
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    backgroundColor: "#F9F2F3",
  },
/*   "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B",
  }, */
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input": {
    backgroundColor: "transparent", // Fondo del texto transparente
  },
}));

const ControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": { fontSize: 14 },
  display: "inline-flex",
  alignItems: "center",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "140%",
  fontFamily: '"UniversNextforHSBC-Regular",sans-serif',
  [theme.breakpoints.down("sm")]: {
    fontWeight: 500,
    fontSize: "9px",
    lineHeight: "144%",
  },
}));

const Check = styled(Checkbox)(({ theme }) => ({
  padding: "4px 0",
  paddingLeft: theme.spacing(1),
}));

const Radio = styled(Rd)(({ theme }) => ({
  paddingTop: 4,
  paddingBottom: 4,
  [theme.breakpoints.down("sm")]: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 1,
  },
  "&:hover": { backgroundColor: "#d3d3d3" },
  "&.Mui-checked": { color: "black" },
}));

const TextField = styled(InputText)(({ theme }) => ({
  marginTop: "8px",
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    fontSize: 14,
    width: "100%",
    "& fieldset legend span": {
      display: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black", // Color del borde cuando está enfocado
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      borderRadius: 0,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 7,
    marginTop: 0,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    fontFamily: '"UniversNextforHSBC-Regular", sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black", // Color del borde cuando está enfocado
    },
  },
  /* "& label.Mui-focused": {
    color: theme.palette.primary.main,
  }, */
  "& label.Mui-error": {
    // color: "#A8000B !important",
    color: "#000 !important",
    // #A8000B
  },
  "& .MuiSelect-select": {
    /*  paddingTop: '15px',
     paddingBottom: '1px', */
    margin: "0 auto",
    marginTop: "3px",
    marginBottom: "2px",
    // height: '30px !important',
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B !important",
    backgroundColor: "#F9F2F3",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    // color: '#A8000B !important',
    color: "#000 !important",
  },
  "& .MuiInputLabel-root.Mui-error": {
    // color: '#A8000B !important',
    color: "#000 !important",
  },
  "& .MuiFormLabel-root.Mui-error": {
    // color: '#A8000B !important', // Color de la etiqueta en caso de error
    color: "#000 !important",
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    backgroundColor: "#F9F2F3 !important",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B !important",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input": {
    backgroundColor: "transparent !important", // Fondo del texto transparente
  },
}));

const AutocompleteStyled = styled(Autocomplete)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    padding: "2.5px 5px",
  },
  "& ~ .MuiAutocomplete-popper .MuiAutocomplete-noOptions": {
    fontSize: 14,
  },
}));

const useStylesPhone = makeStyles({
  inputBase: {
    width: "100% !important",
    color: "inherit",
  },
});

const TextFieldPINOTP = styled(InputText)(({ theme }) => ({
  marginTop: "8px",
  "& .MuiOutlinedInput-input": {
    height: 30,
    padding: "10px 15px",
    textAlign: "center",
    fontSize: "30px",
    letterSpacing: "15px",
    [theme.breakpoints.down("sm")]: {
      // height: 18,
      padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 0,
    fontSize: 14,
    "& fieldset legend span": {
      display: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black", // Color del borde cuando está enfocado
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      borderRadius: 0,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: "16px",
    marginBottom: 5,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    fontFamily: '"UniversNextforHSBC-Regular",sans-serif',
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: "9px",
      lineHeight: "144%",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black !important", // Color del borde cuando está enfocado
    },
  },
  // "& label.Mui-focused": {
  //   color: theme.palette.primary.main,
  // },
  "& label.Mui-error": {
    // color: "#A8000B !important",
    color: "#000 !important",
    // #DB0011
  },
  "& .MuiSelect-select": {
    height: 30,
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
  input: {
    "&::placeholder": {
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
        fontSize: "10px",
      },
    },
  },

  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B",
    backgroundColor: "#F9F2F3",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    // color: '#A8000B',
    color: "#000 !important",
  },
  "& .MuiInputLabel-root.Mui-error": {
    // color: '#A8000B',
    color: "#000 !important",
  },
  "& .MuiFormLabel-root.Mui-error": {
    // color: '#A8000B', // Color de la etiqueta en caso de error
    color: "#000 !important",
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    backgroundColor: "#F9F2F3",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#A8000B",
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input": {
    backgroundColor: "transparent", // Fondo del texto transparente
  },
}));

const CustomIconSelect = () => (
  <span style={{ marginRight: "10px" }}>
    <UseImage src={DropdownIcon} />
  </span>
);
const CustomIconCalendar = () => (
  <span>
    <UseImage src={CalendarIcon} />
  </span>
);

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexPhone =
  "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}";
const regexRFC = "/^[A-ZÑ&]{3,4}d{6}(?:[A-Zd]{3})?$/";
const regexNombre = /^[a-zA-Z0-9]+$/;
const regexPoliza = /^[a-zA-Z0-9]+$/;
const regexCURP =
  /^([A-Z]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[A-Z]{3}[0-9A-Z]\d)$/i;

export const InputField = (props) => {
  const theme = useTheme();
  const location = useLocation();
  const classesPhone = useStylesPhone();
  const [showPassword, setShowPassword] = useState(false);
  const [errorTf, setErrorTf] = useState("");

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  }, []);

  const {
    inputType,
    iconSpam,
    defaultValue,
    value,
    label,
    multiline,
    minRows,
    sxLabel,
    sxInput,
    optionsList,
    tooltipText,
    control,
    name,
    validations,
    icon,
    posIcon,
    onEnter,
    placeholder,
    onSelect,
    selectField,
    disabled,
    loading,
    onInputChange,
    open,
    onOpen,
    onClose,
    error,
    helperText,
    isPassword,
    checked,
    radioValues,
    minDate,
    maxDate,
    className,
    refCustom,
    required,
    register,
    onBlur,
    checkTitle,
  } = props;

  const getInputType = () => {
    switch (inputType) {
      case "select":
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validations}
            render={({ field: { ref, onChange, ...restFields } }) => {
              // console.log('CB VALUE: ', label, { ...restFields })
              return (
                <ThemeProvider theme={themeSelect}>
                  <TextField
                    {...restFields}
                    {...register(name, {
                      required: required ? "Requerido" : "",
                    })}
                    error={error}
                    helperText={helperText}
                    label={label} //disabled ? 'rgba(0, 0, 0, 0.38)'
                    name={name}
                    fullWidth
                    select
                    inputRef={ref}
                    disabled={disabled}
                    placeholder={placeholder}
                    required={required}
                    onChange={(e) => {
                      // console.log('DATA COMBO::: ', e)
                      onChange(e);
                      onSelect && onSelect(e);
                    }}
                    SelectProps={{
                      IconComponent: CustomIconSelect,
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxWidth: "300px", // Set the maxWidth here
                          },
                        },
                      },
                    }}
                    inputProps={{ displayEmpty: true, shrink: true }}
                    sx={{
                      "& .MuiFormLabel-asterisk": {
                        color:
                          /* error ? '#A8000B !important' :  */ "black !important",
                      },
                    }}
                  >
                    {/* <MenuItem disabled value="">
                  <spam style={{ color: '#9E9E9E' }}>{placeholder}</spam>
                </MenuItem> */}
                    {optionsList.map((opt, i) => (
                      <MenuItem
                        key={`${opt?.Option}${i}`}
                        value={opt?.Value?.toUpperCase()}
                        placeholder="Seleccione"
                        selected
                        sx={{ wordSpacing: "0.2rem" }}
                      >
                        {opt.Option?.toUpperCase()}
                      </MenuItem>
                    ))}
                  </TextField>
                </ThemeProvider>
              );
            }}
          />
        );
      case "check":
        console.log("checkTitle:::", required);
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            justifyContent={"center"}
          >
            {checkTitle ? (
              <Box
                sx={{
                  mt: 1,
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "140%",
                  [theme.breakpoints.down("sm")]: {
                    fontWeight: 400,
                    fontSize: "10px",
                    lineHeight: "144%",
                  },
                }}
              >
                {`${checkTitle} ${required ? "*" : ""}`}
              </Box>
            ) : null}
            <ControlLabel
              sx={{
                fontSize: "14px !important",
                mr: 1,
                mt: "0.5px",
                fontFamily: '"UniversNextforHSBC-Regular",sans-serif',
              }}
              required={required}
              name={name}
              disabled={disabled}
              control={
                <Controller
                  control={control}
                  name={name}
                  defaultValue={defaultValue}
                  rules={{ ...validations, required: required }}
                  render={({
                    field: { ref, onChange, value, name, ...restFields },
                  }) => (
                    <Box sx={{ mt: 0.4 }}>
                      <Check
                        {...restFields}
                        name={name}
                        inputRef={ref}
                        checked={value ?? defaultValue}
                        disabled={disabled}
                        required={required}
                        onChange={(e) => {
                          onChange(e);
                          onSelect && onSelect(e);
                        }}
                        size="small"
                      />
                    </Box>
                  )}
                />
              }
              label={
                <Box
                  color={/* error ? "#A8000B" :  */ "#000 !important"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    ml: 1,
                    mt: 0.4,
                    fontStyle: "normal",
                    fontWeight: !!checkTitle ? 100 : 600,
                    fontSize: "16px",
                    lineHeight: "16px",
                    fontFamily: '"UniversNextforHSBC-Regular",sans-serif',
                    [theme.breakpoints.down("sm")]: {
                      fontWeight: !!checkTitle ? 100 : 600,
                      fontSize: "11px",
                      lineHeight: "144%",
                    },
                  }}
                >
                  {Parser(
                    `${label} ${!!checkTitle ? "" : required ? "*" : ""}`
                  )}
                  {tooltipText && (
                    <Tip
                      title={tooltipText}
                      placement="top"
                      arrow
                      enterTouchDelay={0}
                    >
                      <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                    </Tip>
                  )}
                </Box>
              }
            />
          </Box>
        );
      case "radio":
        return (
          <ControlLabel
            sx={{
              fontSize: "14px !important",
              m: "0",
              fontFamily: '"UniversNextforHSBC-Regular",sans-serif',
              ...(sxInput ?? {}),
              width: "100% !important",
            }}
            // disabled={disabled}
            control={
              <Controller
                defaultValue={null}
                control={control}
                name={name}
                rules={{ ...validations, required }}
                // disabled={disabled}
                render={({ field }) => (
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "140%",
                        [theme.breakpoints.down("sm")]: {
                          fontWeight: 400,
                          fontSize: "10px",
                          lineHeight: "144%",
                        },
                        marginTop: "5px",
                        marginBottom: "5px",
                        // color: error ? "#A8000B" : "inherit",
                        color: "#000 !important",
                      }}
                    >
                      {label ? `${label} ${required ? "*" : ""}` : null}
                      {tooltipText && (
                        <Tip
                          title={tooltipText}
                          placement="top"
                          arrow
                          enterTouchDelay={0}
                        >
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </Box>

                    <RadioGroup {...field}>
                      {radioValues?.map((rv) => (
                        <FormControlLabel
                          key={rv.Option + rv.Value}
                          // disabled={disabled}
                          value={rv.Value}
                          control={
                            <Radio
                              required={label ? false : required}
                              disabled={disabled}
                              onChange={(e) => {
                                onSelect && onSelect(e, rv.Value);
                              }}
                              color="primary"
                              size="small"
                            />
                          }
                          label={
                            <Box
                              color={
                                disabled
                                  ? "rgba(0, 0, 0, 0.38) !important"
                                  : "#000 !important"
                              }
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                ml: 1,
                                fontStyle: "normal",
                                fontWeight: 400,
                                // fontSize: '17px',
                                lineHeight: "16px",
                                fontFamily:
                                  '"UniversNextforHSBC-Regular",sans-serif',
                                [theme.breakpoints.down("sm")]: {
                                  fontWeight: 400,
                                  fontSize: "10px",
                                  lineHeight: "144%",
                                },
                              }}
                            >
                              {rv.Option}
                              {rv.TooltipText && (
                                <Tip
                                  title={rv.TooltipText}
                                  placement="top"
                                  arrow
                                  enterTouchDelay={0}
                                >
                                  <InfoOutlinedIcon
                                    sx={{ ml: 1, fontSize: 15 }}
                                  />
                                </Tip>
                              )}
                            </Box>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                )}
              />
            }
          />
        );
      case "date":
        return (
          <Controller
            control={control}
            name={name}
            rules={{
              ...validations,
            }}
            render={({ field: { ref, onChange, ...restFields } }) => {
              return (
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      marginTop: "15px",
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "144%",
                      },
                      marginBottom: "10px",
                      color: "#000 !important",
                    }}
                  >
                    {label != undefined && label != null && label != "" ? (
                      <>
                        {`${label} ${required ? "*" : ""}`}
                        {tooltipText && (
                          <Tip
                            title={tooltipText}
                            placement="top"
                            arrow
                            enterTouchDelay={0}
                          >
                            <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                          </Tip>
                        )}
                      </>
                    ) : null}
                  </Box>
                  <ThemeProvider theme={themeDatePicker}>
                    <DatePicker
                      // onError={setErrorDate}
                      desktopModeMediaQuery="@media (min-width: 100px)"
                      slotProps={{
                        day: {
                          sx: {
                            "&.MuiPickersDay-root": {
                              borderRadius: "0px",
                              "&:hover": {
                                borderRadius: "0px",
                                backgroundColor: "#D3D3D3 ",
                              },
                            },
                            "&.MuiPickersDay-root.Mui-selected": {
                              backgroundColor: "#000",
                              borderRadius: "0px",
                            },
                            "&.MuiPickersDay-root.Mui-selected:hover": {
                              backgroundColor: "#D3D3D3",
                              borderRadius: "0px",
                            },
                          },
                        },
                        desktopPaper: {
                          sx: {
                            ".MuiPickersYear-yearButton": {
                              "&:hover": { borderRadius: "0px" },
                            }, //MuiPickersDay-root MuiPickersDay-dayWithMargin
                            ".MuiPickersYear-yearButton.Mui-selected": {
                              backgroundColor: "#000",
                              borderRadius: "0px",
                              "&:hover": {
                                borderRadius: "0px",
                                backgroundColor: "#D3D3D3",
                              },
                            },
                            ".MuiPickersYear-yearButton.Mui-selected:hover": {
                              backgroundColor: "#D3D3D3",
                              borderRadius: "0px",
                              color: "black",
                            },
                          },
                        },
                        field: {
                          sx: {
                            color: "blue",
                          },
                        },
                        toolbar: {
                          toolbarPlaceholder: "",
                        },
                      }}
                      sx={{
                        "& fieldset": {
                          borderColor: error ? "#A8000B !important" : "none",
                          marginTop: "5px",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "black", // Color del texto al seleccionar el TextField
                          },
                      }}
                      minDate={minDate}
                      maxDate={maxDate}
                      format="dd/MM/yyyy"
                      inputFormat="dd/MM/yyyy"
                      inputProps={{ placeholder: "dd/mm/aaaa" }}
                      components={{
                        // OpenPickerIcon: CalendarTodayOutlinedIcon,
                        OpenPickerIcon: CustomIconCalendar,
                      }}
                      {...restFields}
                      onChange={(e) => {
                        onSelect && onSelect(e);
                      }}
                      inputRef={ref}
                      disabled={disabled}
                    />
                  </ThemeProvider>
                  {!!error && (
                    <p
                      style={{
                        paddingLeft: "10px",
                        color: "#000 !important" /* color: '#A8000B' */,
                      }}
                    >
                      {helperText}
                    </p>
                  )}
                </Box>
              );
            }}
          />
        );
      case "phone":
        const isMobile =
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/Android/i);
        return (
          <Controller
            control={control}
            name={name}
            rules={{
              ...validations,
              pattern: {
                value: /^\d{12,}$/ /* /^\d{12}$/ */,
                message: "El numero debe tener al menos 10 digitos",
              },
              validate: (value) =>
                value.length >= 12 ||
                "El numero debe tener al menos 10 digitos",
            }}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "140%",
                    [theme.breakpoints.down("sm")]: {
                      fontWeight: 400,
                      fontSize: "10px",
                      lineHeight: "144%",
                    },
                    marginTop: "5px",
                    marginBottom: "5px",
                    // color: error ? "#A8000B" : "inherit",
                    color: "#000 !important",
                  }}
                >
                  {`${label} `}
                  {required ? <span>{"*"}</span> : null}
                  {tooltipText && (
                    <Tip
                      title={tooltipText}
                      placement="top"
                      arrow
                      enterTouchDelay={0}
                    >
                      <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                    </Tip>
                  )}
                </Box>

                <PhoneInput
                  {...restFields}
                  country={"mx"}
                  inputClass={classesPhone.inputBase}
                  enableSearch={true}
                  preferredCountries={["mx", "co"]}
                  // specialLabel={`${label} ${required ? " *" : ""}`}
                  onChange={(value, country, e, formattedValue) => {
                    onChange(value);
                    onSelect && onSelect(value);
                  }}
                  key={name}
                  inputProps={{
                    ref,
                    required: true,
                    // autoFocus: true,
                    inputMode: "numeric",
                  }}
                  inputStyle={{
                    height: 25,
                    borderRadius: 0,
                    ...(error && {
                      borderColor: "#A8000B",
                      backgroundColor: "#F9F2F3",
                    }),
                    // padding: "15px 15px",
                    paddingTop: "22px",
                    paddingBottom: "22px",
                    [theme.breakpoints.down("sm")]: {
                      paddingTop: "17px",
                      paddingBottom: "17px",
                    },
                    "&:focus-within fieldset": {
                      borderColor: "black !important", // Cambia el color del contorno al hacer focus
                    },
                    ...(isMobile && {
                      paddingTop: "17px",
                      paddingBottom: "17px",
                    }),
                  }}
                  buttonStyle={{
                    borderRadius: "0px",
                    // borderRight: '1px solid transparent', backgroundColor: '#fff',
                    ...(error && {
                      borderTop: "1px solid #A8000B",
                      borderBottom: "1px solid #A8000B",
                      borderLeft: "1px solid #A8000B",
                      borderRight: "1px solid transparent",
                      backgroundColor: "#F9F2F3",
                    }),
                  }}
                />
                {error && (
                  <Typography
                    sx={{
                      /*  color: '#A8000B' */ color: "#000 !important",
                      pl: 2,
                    }}
                  >
                    {helperText || "Requerido"}
                  </Typography>
                )}
              </Box>
            )}
          />
        );
      case "autocomplete":
        const filter = createFilterOptions();
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validations}
            render={({ field: { onChange, value } }) => (
              <AutocompleteStyled
                clearOnBlur
                handleHomeEndKeys
                sx={{ p: 0 }}
                fullWidth
                disabled={disabled}
                onChange={(event, newValue) => {
                  if (typeof newValue == "string") {
                    onChange({
                      name: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    onChange({
                      name: newValue.inputValue,
                    });
                  } else {
                    onChange(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.name
                  );
                  if (inputValue != "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `Agregar "${inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                loadingText="Cargando..."
                noOptionsText="Sin resultados"
                clearText="Limpiar"
                openText="Abrir"
                closeText="Cerrar"
                onInputChange={onInputChange}
                disablePortal={true}
                loading={loading}
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                value={value}
                options={optionsList}
                getOptionLabel={(option) => {
                  if (typeof option == "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.name;
                }}
                /*  getOptionLabel={(item) => (item.name ? item.name : "")} */
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={error}
                    placeholder={placeholder}
                    InputLabelProps={{ sx: sxLabel }}
                    sx={{
                      "& .MuiFormLabel-asterisk": {
                        color:
                          /* error ? '#A8000B !important' : */ "black !important",
                      },
                      ...(sxInput ?? {}),
                    }}
                    label={
                      <>
                        {" "}
                        {label}
                        {iconSpam ? (
                          <spam
                            style={{
                              color: "#000 !important",
                              /* color: "#A8000B" ,*/ marginLeft: 3,
                            }}
                          >
                            *
                          </spam>
                        ) : null}
                      </>
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <Typography
                    {...props}
                    sx={{ fontSize: "14px !important" /* margin: "0 10px" */ }}
                  >
                    {option.name}
                  </Typography>
                )}
              />
            )}
          />
        );
      case "email":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => {
              return (
                <TextField
                  {...restFields}
                  {...register(name, {
                    required: required ? "Requerido" : "",
                    validate: (value) => {
                      return (
                        new RegExp(regexEmail).test(value) ||
                        `${label} Dato inválido. ${
                          label?.toUpperCase() == "CORREO ELECTRÓNICO"
                            ? "Ej. ejemplo@ejemplo.com"
                            : ""
                        }`
                      );
                    },
                  })}
                  fullWidth
                  error={error}
                  helperText={helperText}
                  inputRef={ref}
                  name={name}
                  disabled={disabled}
                  onKeyUp={onEnter}
                  defaultValue={defaultValue}
                  required={required}
                  autoComplete="new-password"
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "140%",
                        [theme.breakpoints.down("sm")]: {
                          fontWeight: 400,
                          fontSize: "10px",
                          lineHeight: "144%",
                        },
                      }}
                    >
                      {label}
                      {iconSpam || required ? (
                        <spam
                          style={{
                            color: "#000 !important",
                            /* color: "#A8000B", */ marginLeft: 3,
                          }}
                        ></spam>
                      ) : null}
                      {tooltipText && (
                        <Tip
                          title={tooltipText}
                          placement="top"
                          arrow
                          enterTouchDelay={0}
                        >
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </Box>
                  }
                  InputProps={{
                    inputProps: {
                      // style: { textTransform: "uppercase" },
                      outline: "none",
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                    // inputComponent: TextMaskCustom
                  }}
                  InputLabelProps={{ sx: sxLabel }}
                  sx={{
                    "& .MuiFormLabel-asterisk": {
                      color:
                        /* error ? '#A8000B !important' :  */ "black !important",
                    },
                    ...(sxInput ?? {}),
                  }}
                />
              );
            }}
          />
        );
      case "curp":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                  validate: (value) => {
                    if (required) {
                      console.log("REGEX:: ", value);
                      return (
                        new RegExp(regexCURP)?.test(value) ||
                        `${label} Dato inválido. Ingrese 18 carácteres y en su formato correcto`
                      );
                    } else {
                      if (value === "") {
                        return null;
                      } else {
                        return (
                          new RegExp(regexCURP).test(value) ||
                          `${label} Dato inválido. Ingrese 18 carácteres y en su formato correcto`
                        );
                      }
                    }
                  },
                  setValueAs: (value) => value?.toUpperCase(),
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    maxLength: 18,
                    style: { textTransform: "uppercase" },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                  // inputComponent: TextMaskCustom
                }}
                InputLabelProps={{ sx: sxLabel }}
                sx={{
                  "& .MuiFormLabel-asterisk": {
                    color:
                      /* error ? '#A8000B !important' :  */ "black !important",
                  },
                  ...(sxInput ?? {}),
                }}
              />
            )}
          />
        );
      case "rfc":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                  setValueAs: (value) => value?.toUpperCase(),
                  // validate: (value) => {
                  //   return (new RegExp(regexRFC).test(value)
                  //     || `${label} dato inválido, ingrese los dígitos de su RFC.`
                  //   );
                  // },
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "140%",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "144%",
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip
                        title={tooltipText}
                        placement="top"
                        arrow
                        enterTouchDelay={0}
                      >
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    maxLength: 13,
                    style: { textTransform: "uppercase" },
                    outline: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  outline: "none",
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                InputLabelProps={{ sx: sxLabel }}
                sx={{
                  "& .MuiFormLabel-asterisk": {
                    color:
                      /* error ? '#A8000B !important' : */ "black !important",
                  },
                  ...(sxInput ?? {}),
                }}
              />
            )}
          />
        );
      case "clabe":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <PatternFormat
                {...restFields}
                fullWidth
                inputRef={ref}
                label={
                  <>
                    {" "}
                    {label}
                    {iconSpam ? (
                      <spam
                        style={{
                          color: "#000 !important",
                          /* color: "#A8000B", */ marginLeft: 3,
                        }}
                      >
                        *
                      </spam>
                    ) : null}
                  </>
                }
                customInput={TextField}
                displayType="input"
                format="###################"
                mask="_"
                sx={{
                  "& fieldset": {
                    borderColor: error ? "#A8000B !important" : "none",
                  },
                }}
              />
            )}
          />
        );
      case "PostalCode":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <PatternFormat
                {...restFields}
                fullWidth
                inputRef={ref}
                label={
                  <>
                    {" "}
                    {label}
                    {iconSpam ? (
                      <spam
                        style={{
                          color: "#000 !important",
                          /* color: "#A8000B", */ marginLeft: 3,
                        }}
                      >
                        *
                      </spam>
                    ) : null}
                  </>
                }
                customInput={TextField}
                displayType="input"
                format="#####"
                mask="_"
                sx={{
                  "& fieldset": {
                    borderColor: error ? "#A8000B !important" : "none",
                  },
                }}
              />
            )}
          />
        );
      case "PIN":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <PatternFormat
                {...restFields}
                fullWidth
                inputRef={ref}
                helperText={helperText}
                error={error}
                label={
                  <>
                    {" "}
                    {label}
                    {iconSpam ? (
                      <spam
                        style={{
                          color: "#000 !important",
                          /* color: "#A8000B" ,*/ marginLeft: 3,
                        }}
                      >
                        *
                      </spam>
                    ) : null}
                  </>
                }
                onChange={(e) => {
                  let valueReplace = e.target.value?.replace(/-/g, "");
                  // console.log("event PIN target valueReplace: ", valueReplace)
                  onChange(valueReplace);
                  onSelect && onSelect(valueReplace);
                }}
                customInput={TextFieldPINOTP}
                displayType="input"
                format="#-#-#-#-#"
                mask="_"
                sx={{
                  "& fieldset": {
                    borderColor: error ? "#A8000B !important" : "none",
                  },
                }}
              />
            )}
          />
        );
      case "currency":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <NumericFormat
                {...restFields}
                fullWidth
                inputRef={ref}
                label={
                  <>
                    {" "}
                    <spam
                      style={{
                        color: /* error ? "#A8000B" : */ disabled
                          ? "rgba(0, 0, 0, 0.38)"
                          : "#000 !important",
                      }}
                    >
                      {label}
                    </spam>
                    {required ? (
                      <spam
                        style={{
                          color: "#000 !important",
                          /*  color: error ? "#A8000B" : "" ,*/ marginLeft: 3,
                        }}
                      >
                        *
                      </spam>
                    ) : null}
                  </>
                }
                onChange={(e) => {
                  let valueReplace = e.target.value?.replace(/[,|$]/g, "");
                  onChange(valueReplace);
                  onSelect && onSelect(valueReplace);
                }}
                customInput={TextField}
                decimalScale={2} // Decimales
                fixedDecimalScale={true}
                thousandSeparator={true} // Separador de miles
                prefix={"$"} // Símbolo de moneda
                disabled={disabled}
              />
            )}
          />
        );
      case "number":
        return (
          <Controller
            control={control}
            name={name}
            // defaultValue=""
            rules={validations}
            render={({ field: { ref, ...restFields } }) => {
              return (
                <NumericFormat
                  {...restFields}
                  fullWidth
                  inputRef={ref}
                  label={
                    <>
                      {" "}
                      <spam
                        style={{
                          color:
                            "#000 !important" /* color: error ? "#A8000B" : "" */,
                        }}
                      >
                        {label}
                      </spam>
                      {required ? (
                        <spam style={{ /* color: "#A8000B", */ marginLeft: 3 }}>
                          *
                        </spam>
                      ) : null}
                    </>
                  }
                  customInput={TextField}
                  decimalScale={0} // Decimales
                  fixedDecimalScale={false}
                  thousandSeparator={false} // Separador de miles
                />
              );
            }}
          />
        );
      case "hour":
        return (
          <LocalizationProvider
            dateAdapter={AdapterDateFns /* AdapterDayjs */}
            adapterLocale={es}
          >
            <Controller
              control={control}
              name={name}
              rules={validations}
              render={({ field: { ref, onChange, ...restFields } }) => {
                console.log("hour inside", { ...restFields });
                return (
                  <Box sx={{ width: "100%" }}>
                    <Box
                      component="div"
                      sx={{
                        marginTop: "15px",
                        whiteSpace: "normal",
                        display: "flex",
                        alignItems: "center",
                        fontStyle: "normal",
                        fontWeight: 400,
                        flexWrap: "wrap",
                        fontSize: "16px",
                        lineHeight: "140%",
                        [theme.breakpoints.down("sm")]: {
                          fontWeight: 400,
                          fontSize: "10px",
                          lineHeight: "144%",
                        },
                        marginBottom: "5px",
                        // color: error ? "#A8000B" : "black",
                        color: "#000 !important",
                      }}
                    >
                      {label != undefined && label != null && label != "" ? (
                        <>
                          {`${label} ${required ? "*" : ""}`}
                          {tooltipText && (
                            <Tip
                              title={tooltipText}
                              placement="top"
                              arrow
                              enterTouchDelay={0}
                            >
                              <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                            </Tip>
                          )}
                        </>
                      ) : null}
                    </Box>
                    <Stack spacing={3} width={"100%"}>
                      <LocalizationProvider
                        localeText={
                          esES.components.MuiLocalizationProvider.defaultProps
                            .localeText
                        }
                      >
                        <MobileTimePicker
                          {...restFields}
                          ref={ref}
                          openTo="hours"
                          sx={{
                            borderRadius: "0px !important",
                            "& fieldset": {
                              borderColor: error
                                ? "#A8000B !important"
                                : "none",
                              marginTop: "5px",
                            },
                          }}
                          onChange={(event) => {
                            const currentDate = new Date(event);
                            const hours = currentDate.getHours();
                            const minutes = currentDate.getMinutes();
                            const hour = `${hours < 10 ? "0" + hours : hours}:${
                              minutes < 10 ? "0" + minutes : minutes
                            }`;
                            // onChange(hour);
                            onSelect && onSelect(hour);
                          }}
                          locale={esLocale}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Box>
                );
              }}
            />
          </LocalizationProvider>
        );
      case "password":
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            defaultValue={defaultValue}
            render={({
              field: { ref, onChange, ...restFields },
              fieldState,
            }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                  validate: (value) => {
                    if (!isPassword) {
                      const ochocaracteres = /.{8,}/.test(value);
                      const mayymin =
                        /(?:[A-Z])/.test(value) && /(?:[a-z])/.test(value);
                      const numeros = /(?:\d)/.test(value);
                      const noespecial = /[^!?A-Za-z\d]/.test(value);
                      const espacios = /\s/.test(value);
                      console.log(ochocaracteres, mayymin, numeros, noespecial);

                      const valida =
                        ochocaracteres && mayymin && numeros && noespecial;

                      console.log("valida--------:  ", valida);

                      console.log("valida-------espacios-:  -", espacios, "--");
                      // const regex = /^(?=.*[A-Za-z0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+ ]{8,}$/;
                      // return regex.test(value) || 'password invalid'

                      if (valida === false || espacios) {
                        return `Revisa que tu contraseña cumpla lo siguiente: ${
                          espacios ? "No contenga espacios, " : ""
                        }
                ${ochocaracteres === false ? "8 caracteres mínimo, " : ""}
                ${
                  mayymin === false
                    ? "al menos una letra mayúscula y letras minúsculas, "
                    : ""
                }
                ${numeros === false ? "al menos un número, " : ""}
                ${noespecial === false ? "mínimo un caracter especial." : ""} `;
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  },
                })}
                type={showPassword ? "text" : "password"}
                error={error ?? fieldState.invalid}
                helperText={helperText ?? fieldState.error?.message}
                defaultValue={defaultValue}
                fullWidth
                name={name}
                inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                onKeyUp={onEnter}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                autoComplete="new-password"
                label={
                  label != undefined && label != null && label != "" ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "140%",
                        [theme.breakpoints.down("sm")]: {
                          fontWeight: 400,
                          fontSize: "10px",
                          lineHeight: "144%",
                        },
                      }}
                    >
                      {label}
                      {""}
                      {iconSpam ? (
                        <spam
                          style={{
                            color: "#000 !important",
                            /*  color: "#A8000B", */ marginLeft: 3,
                          }}
                        >
                          *
                        </spam>
                      ) : null}
                      {tooltipText && (
                        <Tip
                          title={tooltipText}
                          placement="top"
                          arrow
                          enterTouchDelay={0}
                        >
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </Box>
                  ) : null
                }
                InputProps={{
                  inputProps: {
                    outlinet: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {/* {showPassword ? <Visibility /> : <VisibilityOff />} */}
                        {showPassword ? (
                          <UseImage src={EyeIcon} style={{ width: "20px" }} />
                        ) : (
                          <UseImage src={Eye_close} style={{ width: "20px" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true, sx: sxLabel }}
                sx={{
                  "& $input": {
                    height: multiline ? "auto" : "25px",
                  },
                  "& .MuiFormLabel-asterisk": {
                    color:
                      /* error ? '#A8000B !important' : */ "black !important",
                  },
                  ...(sxInput ?? {}),
                }}
              />
            )}
          />
        );
      case "text":
      default:
        const validatePattern = (required) => {
          const isLetterField =
            label.toUpperCase() == "NOMBRE (S)" ||
            label.toUpperCase().includes("APELLIDO") ||
            label.toUpperCase() == "NOMBRE(S)" ||
            label.toUpperCase() == "ESTATUS";
          const isLetterAndNumberField =
            label.toUpperCase().includes("PÓLIZA") ||
            label.toUpperCase() == "NUM.SIN.HSBC" ||
            label.toUpperCase() == "FOLIO WF HSBC";
          const isNumberField = label.toUpperCase()?.includes("PORCENTAJE");
          const isOnlyText = label.toUpperCase() == "LUGAR DEL SINIESTRO";

          if (isLetterField) {
            return {
              pattern: {
                value: /^(?=.*[a-zA-ZñÑ])[a-zA-ZñÑ\s]+$/,
                message: "Este campo solo acepta letras y espacios",
              },
            };
          } else if (isNumberField) {
            return {
              pattern: {
                value: /^\d+$/,
                message: "Este campo solo puede contener números",
              },
            };
          } else if (isLetterAndNumberField) {
            return {
              pattern: {
                value: /^(?=.*[a-zA-ZñÑ0-9])[a-zA-ZñÑ0-9\s]+$/,
                message: "Este campo solo acepta letras, espacios y numeros",
              },
            };
          } else {
            // if (required) {
            //   return {
            //     pattern: {
            //       value: /^(?!\s+$).*$/,
            //       message: 'Este campo no puede estar vacío',
            //     },
            //   }
            // } else {
            return null;
            // }
          }
        };
        return (
          <Controller
            control={control}
            name={name}
            rules={{
              ...validations,
              ...validatePattern(required),
            }}
            defaultValue={defaultValue}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? "Requerido" : "",
                  setValueAs: (value) =>
                    value /* (value || '')?.toUpperCase() */,
                })}
                type={isPassword ? "password" : "text"}
                error={error}
                defaultValue={defaultValue}
                fullWidth
                name={name}
                inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                onKeyUp={onEnter}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                helperText={helperText}
                autoComplete="new-password"
                label={
                  label != undefined && label != null && label != "" ? (
                    <Box
                      component="div"
                      sx={{
                        whiteSpace: "normal",
                        display: "flex",
                        alignItems: "center",
                        fontStyle: "normal",
                        fontWeight: 400,
                        flexWrap: "wrap",
                        fontSize: "16px",
                        lineHeight: "140%",
                        ...(disabled && { color: "rgba(0, 0, 0, 0.38)" }),
                        [theme.breakpoints.down("sm")]: {
                          fontWeight: 400,
                          fontSize: "10px",
                          lineHeight: "144%",
                        },
                        // color: !!error ? '#A8000B !important' : 'black'
                        color: "#000 !important",
                      }}
                    >
                      {label}
                      {""}
                      {iconSpam ? (
                        <spam
                          style={{
                            color: "#000 !important",
                            /* color: "#A8000B !important", */ marginLeft: 3,
                          }}
                        >
                          *
                        </spam>
                      ) : null}
                      {tooltipText && (
                        <Tip
                          title={tooltipText}
                          placement="top"
                          arrow
                          enterTouchDelay={0}
                        >
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </Box>
                  ) : null
                }
                InputProps={{
                  inputProps: {
                    outlinet: "none",
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                    style: { textTransform: "uppercase" },
                  },
                  style: { textTransform: "uppercase" },
                  startAdornment:
                    posIcon == "start" ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                  endAdornment:
                    posIcon == "end" ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                }}
                InputLabelProps={{ shrink: true, sx: sxLabel }}
                sx={{
                  "input::placeholder": {
                    wordSpacing: "0.2rem",
                  },
                  "& .MuiFormLabel-asterisk": {
                    color:
                      /* error ? '#A8000B !important' :  */ "black !important",
                  },
                  "& $input": {
                    height: multiline ? "auto" : "25px",
                  },
                  ...(sxInput ?? {
                    wordSpacing: "0.2rem",
                  }),
                }}
              />
            )}
          />
        );
    }
  };

  if (inputType == "check") {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>{getInputType()}</Box>
    );
  }

  return getInputType();
};
