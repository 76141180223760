import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const fileRequests = {}

fileRequests.GetFileInfo = (data) => {
  return axios.post(
    AppUrls.GetFileInfo,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })/* .catch((e) => handleResponse(false)) */
}

fileRequests.GetSearchByUser = (data) => {
  return axios.post(
    AppUrls.GetSearchByUser,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })
}

fileRequests.GetUserSearchPagination = (data) => {
  return axios.post(
    AppUrls.GetUserSearchPagination,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })
}

fileRequests.GetInfoBySearch = (data) => {
  return axios.post(
    AppUrls.GetInfoBySearch,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })
}

fileRequests.GetRecordInfo = (data) => {
  return axios.post(
    AppUrls.GetRecordInfo,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })
}

fileRequests.GetActorsInfo = (data) => {
  return axios.post(
    AppUrls.GetActorsInfo,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })
}

fileRequests.GetActorInfo = (data) => {
  return axios.post(
    AppUrls.GetActorInfo,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })
}

fileRequests.GenerateFolio = (data) => {
  return axios.post(
    AppUrls.GenerateFolio,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigNoAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })
}

fileRequests.FileUpdateMetadata = (data) => {
  return axios.post(
    AppUrls.FileUpdateMetadata,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })/* .catch((e) => handleResponse(false)) */
}

fileRequests.AsignateCurrentUserNew = (data) => {
  return axios.post(
    AppUrls.AsignateCurrentUserNew,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })/* .catch((e) => handleResponse(false)) */
}

fileRequests.GenerateFolioHiring = (data) => {
  return axios.post(
    AppUrls.GenerateFolioHiring,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })
}

fileRequests.AsignateCurrentUser = (data) => {
  return axios.post(
    AppUrls.AsignateCurrentUser,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })
}

fileRequests.AsignateCurrentUser = (data) => {
  return axios.post(
    AppUrls.AsignateCurrentUser,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse).catch((e) => { return handleReject(e?.response) })
}

let handleResponse = (response) => {
  if (!response) {
    let responseObject = { message: 'unauthorized' };
    return Promise.reject(responseObject);
  }

  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject?.Messages + ' ' + (respObject?.TransactionId ? respObject.TransactionId : '') + ' ' + (respObject?.statusText ? respObject.statusText : '') };
    let error = (respObject?.Body || responseObject);
    console.log('Error response: ', error)
    return Promise.reject(error);
  }
  return respObject;
}

let handleReject = (response) => {
  if (!!!response) {
    let responseObject = { message: 'unauthorized' };
    return Promise.reject(responseObject);
  }
  return new Promise((resolve, reject) => {
    let respObject = response.data;
    if (!respObject.IsOK) {
      let responseObject = { message: respObject?.Messages + ' ' + (respObject?.TransactionId ? respObject.TransactionId : '') + ' ' + (respObject?.statusText ? respObject.statusText : '') };
      let error = (respObject?.Body || responseObject);
      reject(error);
    }
    reject(respObject);
  })

}